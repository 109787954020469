import crypto from "crypto";

export function gistIdentify(email) {
  const gistHash = crypto
    .createHmac(
      "sha256",
      "lUdkl+wa9Mw9xMKQZPsIcH37gF9uh+/MKYZeFuNkx+I4T/zNIGjDMg=="
    )
    .update(email)
    .digest("hex");
  gist.identify({
    email: email,
    user_hash: gistHash,
  });
}

export function clearGistCookies() {
  // List of cookie names to clear
  const cookieNames = ["gist_identified_4rtjrhbh", "gist_identified_7tn4opfe", "gist_id_4rtjrhbh", "gist_id_7tn4opfe"];

  // Loop through the cookie names and delete each cookie
  cookieNames.forEach((cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
}
