import { Fade, Modal, Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
const MaintainanceModal = ({ openModal = true, handleClose }) => {
  const theme = useTheme();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <Box
          sx={{
            width: { xs: "95%", sm: "588px", md: "72%", lg: "900px" },
            height: "fit-content",
            backgroundColor: "background.paper",
            boxShadow: 24,
            borderRadius: "10px",
            outline: "none",
            border: "none",
            textAlign: "center",
            padding: "30px",
            [theme.breakpoints.up("sm")]: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            [theme.breakpoints.down("sm")]: {
              position: "fixed",
              bottom: "0",
              left: "50%",
              transform: "translate(-50%, -0%)",
              padding: "15px",
              borderRadius: "15px 15px 0px 0px",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "36px",
              [theme.breakpoints.down("md")]: {
                fontSize: "18px",
                mb: "10px",
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                mb: "5px",
              },
              color: "#002753",
            }}
          >
            System is Under Maintenance
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#949495",
              padding: "10px 50px",
              [theme.breakpoints.down("md")]: {
                fontSize: "10px",
                maxWidth: "390px",
                margin: "auto",
                padding: 0,
              },
            }}
          >
            Some features are unavailable due to system maintenance to improve
            our infrastructure. We apologize for the inconvenience.
          </Typography>
          <Box
            component="img"
            sx={{
              width: { xs: "200px", sm: "271px", md: "380px" },
              padding: "20px",
            }}
            src="/maintainceModal.png"
          />
          {/* <Box sx={{ xs: "5px", sm: "5px", md: "30px" }}>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                cursor: "pointer",
                textTransform: "capitalize",
                backgroundColor: "#1D6BC3",
                fontSize: "16px",
                borderRadius: "5px",
                padding: "7px 45px",
                height: "38px",
                color: "white",

                "&:hover": {
                  backgroundColor: "#1D6BC3",
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "10px",
                  width: "140px",
                  padding: "7px, 56px, 7px, 56px",
                },
              }}
            >
              Close
            </Button>
          </Box> */}
        </Box>
      </Fade>
    </Modal>
  );
};
export default MaintainanceModal;
