import axios from "axios";
import { getFilterParameters } from "../helper/getFilterParameters";

const AUTH_BASE_URL = process.env.NEXT_PUBLIC_SSO_APP;

export const cardDataApi = (timeRange, channelIds) =>
  axios.get(
    `${AUTH_BASE_URL}/dashboard/data_points?time_range=${timeRange}&sales_channel_ids=${channelIds}`
  );

export const graphDataApi = (timeRange, channelIds) =>
  axios.get(
    `${AUTH_BASE_URL}/dashboard/graph?time_range=${timeRange}&sales_channel_ids=${channelIds}`
  );

export const topSellingDataApi = (timeRange, channelIds) =>
  axios.get(
    `${AUTH_BASE_URL}/dashboard/top_selling_listings?time_range=${timeRange}&sales_channel_ids=${channelIds}`
  );

export const reviewDataApi = (timeRange, channelIds) =>
  axios.get(
    `${AUTH_BASE_URL}/dashboard/top_reviews?time_range=${timeRange}&sales_channel_ids=${channelIds}`
  );

export const dashboardDataShopAnalyzer = (channelIds) =>
  axios.get(
    `${AUTH_BASE_URL}/shop_analyzer?page=1&sales_channel_ids=${channelIds}`
  );

export const inventoryTableData = (
  channelIds,
  sortPattern,
  changeTab,
  filters
) => {
  var url = `${AUTH_BASE_URL}/inventory?sales_channel_ids=${channelIds.join(
    ","
  )}&order_by=${sortPattern.sortBy}&order_direction=${
    sortPattern.sortDirection
  }&page=${sortPattern.page}&state=${changeTab}`;
  const finalUrl = getFilterParameters(url, filters);
  return axios.get(finalUrl);
};

export const upcomingHolidays = () => axios.get(`${AUTH_BASE_URL}/holidays`);

export const setDefaultCurrency = (currency_code) =>
  axios.post(`${AUTH_BASE_URL}/users/update_default_currency`, {
    default_currency_code: currency_code,
  });

export const setDefaultSalesChannels = (salesChannelsIds) =>
  axios.post(`${AUTH_BASE_URL}/users/update_default_sales_channel_ids`, {
    default_sales_channel_ids: salesChannelsIds,
  });
export const setHideShopName = (hide) =>
  axios.post(`${AUTH_BASE_URL}/users/update_hide_details_option`, {
    hide_details: hide,
  });

export const reloadDashBoard = () =>
  axios.post(`${AUTH_BASE_URL}/sales_channels/update_sales_channel_data`, {});

export const exportMylistings = (sortBy, sortDirection) =>
  axios.post(
    `${AUTH_BASE_URL}/inventory/export?order_by=${sortBy}&order_direction=${sortDirection}`,
    {}
  );
