import React, { useEffect, useState } from "react";
import { updateStripeSubscription } from "../../apis/stripe";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { stripeSubscription, promoCodeDetails } from "../../apis/stripe";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import Loader from "../../components/Loader";
import { useLoader } from "../../contexts/LoaderContext";

export default function UpdateSubscriptionModal({
  priceId,
  details,
  updateSubscription,
  isUpdateLoading,
  offer,
}) {
  const { loadingSet, setLoadingSet, addToLoadingSet, removeFromLoadingSet } =
    useLoader();
  const router = useRouter();
  const [promoCode, setPromoCode] = useState();
  const [promoCodeId, setPromoCodeId] = useState();
  const [promoCodeName, setPromoCodeName] = useState();
  const [promoCodePercent, setPromoCodePercent] = useState();
  const [havePromoCode, setHavePromoCode] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [amountToBePaid, setAmountToBePaid] = useState();

  // useEffect(() => {
  //   getPrice();
  // }, []);

  //   const applyPromoCode = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const res = await promoCodeDetails(promoCode);
  //       console.log(res);
  //       setPromoCodeId(res.data.promo_code.id);
  //       setPromoCodeName(res.data.promo_code.code);
  //       setPromoCodePercent(res.data.promo_code.coupon.percent_off);
  //     } catch (err) {
  //       setPromoCodeId();
  //       setPromoCodePercent();
  //       toast.error("Invalid promo code");
  //     }
  //   };

  async function getPrice() {
    try {
      addToLoadingSet("getPrice");
      const res = await updateStripeSubscription({
        check_price: true,
        interval: details.interval,
        plan_name: details.title,
      });
      setAmountToBePaid(res.data.amount_to_be_paid);
    } catch (err) {
      toast.error("Something went wrong.");
      console.log(err);
    } finally {
      removeFromLoadingSet("getPrice");
    }
  }
  return (
    <div>
      <>
        <div
          style={{
            backgroundColor: "#f7f8fa",
            margin: 0,
            padding: 0,
            borderRadius: "5px",
          }}
        >
          <div style={{}}>
            <form
              style={{
                display: "block",
                width: "100%",
                margin: 0,
              }}
            >
              <div className="checkout-form-header">
                {" "}
                {!offer ? "Upgrade membership" : "Bogo Plan Details"}
              </div>
              <div className="checkout-form-plan-details">
                <div>
                  <div
                    style={{
                      fontWeight: offer && "600",
                      fontSize: offer && "16px",
                    }}
                  >
                    {!offer ? details.title : "Buy one, get one month free"}
                  </div>{" "}
                  <div className="checkout-plan-duration">
                    <span ms-lang="pay_billed">{details.footerTagLine}</span>
                  </div>
                </div>
                <div className="ms-checkout-pricing-wrapper">
                  <div
                    data-cy="amount"
                    className="ms-checkout-price"
                    style={{ fontWeight: offer && "600" }}
                  >
                    {`$${details.chargePrice}`}
                  </div>
                </div>
              </div>
              <div className="checkout-form-plan-details">
                <div>
                  <div>
                    {" "}
                    If you’re already on a paid plan and upgrading, your billing
                    will be pro-rated accordingly
                  </div>
                </div>
                {/* <div className="ms-checkout-pricing-wrapper">
                  <div
                    data-cy="amount"
                    className="ms-checkout-price"
                    style={{}}
                  >
                    {amountToBePaid && `${amountToBePaid}`}
                  </div>
                </div> */}
              </div>
              {/* {promoCodeId && (
                <div className="checkout-form-plan-details">
                  <div>
                    <div>{promoCodeName}</div>{" "}
                    <div className="checkout-plan-duration">
                      <span ms-lang="pay_billed">Sub total</span>
                    </div>
                  </div>
                  <div className="ms-checkout-pricing-wrapper">
                    <div
                      data-cy="amount"
                      className="ms-checkout-price"
                      style={{}}
                    >
                      {promoCodePercent &&
                        `- $${(
                          details.chargePrice *
                          (promoCodePercent / 100)
                        ).toFixed(2)}`}
                    </div>
                    <div
                      data-cy="amount"
                      className="ms-checkout-price"
                      style={{}}
                    >
                      {promoCodePercent &&
                        `=  $${(
                          details.chargePrice -
                          details.chargePrice * (promoCodePercent / 100)
                        ).toFixed(2)}`}
                    </div>
                  </div>
                </div>
              )}

              {havePromoCode ? (
                <>
                  {promoCodeId ? (
                    <div className="checkout-form-div">
                      <Link
                        style={{ fontSize: "0.7rem", cursor: "pointer" }}
                        onClick={() => {
                          setPromoCodeName();
                          setPromoCodeId();
                          setPromoCodePercent();
                          setHavePromoCode(false);
                        }}
                      >
                        Remove promo code
                      </Link>
                    </div>
                  ) : (
                    <Paper
                      className="checkout-form-div"
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="COUPON"
                        onChange={(e) => setPromoCode(e.target.value)}
                        inputProps={{ "aria-label": "search google maps" }}
                      />
                      <Button
                        style={{
                          margin: "5px",
                          color: "white",
                          backgroundColor: "#58b4f4",
                        }}
                        variant="contained"
                        onClick={applyPromoCode}
                      >
                        Apply
                      </Button>
                    </Paper>
                  )}
                </>
              ) : (
                <div className="checkout-form-div">
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => setHavePromoCode(true)}
                  >
                    Have a promo code?
                  </Link>
                </div>
              )} */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={(e) => updateSubscription(e, promoCodeId)}
                  style={{ color: "white", backgroundColor: "#58b4f4" }}
                  className="pay-button"
                  disabled={isUpdateLoading}
                >
                  {isUpdateLoading ? "Loading..." : "Upgrade"}
                </button>
              </div>
            </form>
            <Loader isLoading={loadingSet.size !== 0} />
          </div>
        </div>
      </>
    </div>
  );
}
