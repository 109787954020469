import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { Tooltip, Typography } from "@mui/material";
import { motion } from "framer-motion";


export default function ToggleGroup({
  isExpanded,
  alignment,
  setAlignment,
  setIsExpanded,
}) {
  const handleAlignment = (event, newAlignment) => {
    if (!isExpanded) {
      setIsExpanded(true);
      localStorage.setItem("sidebarExpanded", true);
    }
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.6),
      border: 0,
      height: "32px",
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
      "&.left-toggle": {
        width: isExpanded ? "98px" : "auto",
      },
      "&.right-toggle": {
        width: isExpanded ? "99px" : "auto",
        textAlign: "center",
      },
    },
  }));
  return (
    <div style={{ marginTop: "10px" }}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          isExpanded={isExpanded}
        >
          <ToggleButton
            value="left"
            aria-label="left aligned"
            className="left-toggle"
          >
            <Tooltip
              PopperProps={{
                style: {
                  zIndex: 999999999,
                  display: isExpanded ? 'none' : 'block',
                }
              }}
              placement="right"
              arrow
              title={
                <Typography fontSize="12px" fontWeight="500" lineHeight="18px" p="8px">
                  Menu
                </Typography>
              }
            >
              <p> {isExpanded ? "Menu" : <MenuIcon sx={{ fontSize: "13px" }} />} </p>
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value="right"
            aria-label="right aligned"
            className="right-toggle"
          >
            <Tooltip
              PopperProps={{
                style: {
                  zIndex: 9999999999, display: isExpanded ? 'none' : 'block',
                }
              }}
              placement="right"
              arrow
              title={
                <Typography fontSize="12px" fontWeight="500" lineHeight="18px" p="8px">
                  Options
                </Typography>
              }
            >
              <p>{isExpanded ? "Options" : <PersonIcon sx={{ fontSize: "13px" }} />}</p>
            </Tooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div >
  );
}
