import Image from "next/image";
import React from "react";
import AccountSvg from "../../assets/svg/account.svg";
import FileSvg from "../../assets/svg/file.svg";
import CrownSvg from "../../assets/svg/crown.svg";
import SupportSvg from "../../assets/svg/support.svg";
import RefreshSvg from "../../assets/svg/Refresh-Etsy.svg";
import SearchSvg from "../../assets/svg/search-grey.svg";
import NotificationBellSvg from "../../assets/svg/notification-bell.svg";
import NotificationDotSvg from "../../assets/svg/notification-dot.svg";
import ChevronDownSvg from "../../assets/svg/chevron-down.svg";
import ChevronRight from "../../assets/svg/chevron-right.svg";
import greyChevronRight from "../../assets/svg/grey-chevron-right.svg";
import BlueCircleTick from "../../assets/svg/blue-circle-tick.svg";
import GreyCircleTick from "../../assets/svg/grey-circle-tick.svg";
import ReviewerTest from "../../assets/svg/reviewer-test.svg";
import Heart from "../../assets/svg/heart.svg";
import HideBarDiscount from "../../assets/svg/side-bar-discount.svg";
import CloseButton from "../../assets/svg/close-button.svg";
import Share from "../../assets/svg/share.svg";
import Edit from "../../assets/svg/edit.svg";
import Calculate from "../../assets/svg/calculate.svg";
import Clipboard from "../../assets/svg/clipboard.svg";
import HorizontalDots from "../../assets/svg/horizontal-dots.svg";
import addFolder from "../../assets/svg/add-folder.svg";
import folder from "../../assets/svg/folder.svg";
import trash from "../../assets/svg/trash.svg";
import darkCloseButton from "../../assets/svg/dark-close-button.svg";
import darkAddFolder from "../../assets/svg/dark-add-folder.svg";
import darkTrash from "../../assets/svg/dark-trash.svg";
import greyAddFolder from "../../assets/svg/grey-add-folder.svg";
import blueAddFolder from "../../assets/svg/blue-add-folder.svg";
import allDone from "../../assets/svg/all-done.svg";
import infoCircle from "../../assets/svg/info-circle.svg";
import shoppingBag from "../../assets/svg/shopping-bag.svg";
import watchlistEmptyFolder from "../../assets/svg/watchlist-empty-folder.svg";
import link from "../../assets/svg/link.svg";
import etsyIcon from "../../assets/svg/etsy-icon.svg";
import etsySalesChannels from "../../assets/svg/etsy-sales-channels.svg";
import shoppingCart from "../../assets/svg/shopping-cart.svg";
import lock from "../../assets/svg/lock.svg";
import logIn from "../../assets/svg/log-in.svg";
import linkRed from "../../assets/svg/link-red.svg";
import closeRedButton from "../../assets/svg/close-button-red.svg";
import addStoreExpanded from "../../assets/svg/addStoreExpanded.svg";
import addStoreSmall from "../../assets/svg/addStoreSmall.svg";
import upsellTick from '../../assets/svg/upsell-tick.svg';
import giftIcon from '../../assets/svg/gift-icon.svg';

const Svg = ({ svgName, width, height }) => {
  if (svgName === "Account") {
    return <Image src={AccountSvg} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Wishlist") {
    return <Image src={FileSvg} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Upgrade Account") {
    return <Image src={CrownSvg} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Support") {
    return <Image src={SupportSvg} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Refresh Etsy Data") {
    return <Image src={RefreshSvg} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Search") {
    return <Image src={SearchSvg} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "NotificationBell") {
    return (
      <Image
        src={NotificationBellSvg}
        width={width}
        height={height}
        alt="N/A"
      />
    );
  }
  if (svgName === "NotificationDot") {
    return (
      <Image src={NotificationDotSvg} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "ChevronDown") {
    return (
      <Image src={ChevronDownSvg} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "ChevronRight") {
    return <Image src={ChevronRight} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "ReviewerTest") {
    return <Image src={ReviewerTest} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "BlueCircleTick") {
    return (
      <Image src={BlueCircleTick} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "GreyCircleTick") {
    return (
      <Image src={GreyCircleTick} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "Heart") {
    return <Image src={Heart} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "HideBarDiscount") {
    return (
      <Image src={HideBarDiscount} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "CloseButton") {
    return <Image src={CloseButton} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Share") {
    return <Image src={Share} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Edit") {
    return <Image src={Edit} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Calculate") {
    return <Image src={Calculate} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "Clipboard") {
    return <Image src={Clipboard} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "HorizontalDots") {
    return (
      <Image src={HorizontalDots} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "addFolder") {
    return <Image src={addFolder} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "folder") {
    return <Image src={folder} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "trash") {
    return <Image src={trash} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "darkCloseButton") {
    return (
      <Image src={darkCloseButton} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "darkAddFolder") {
    return (
      <Image src={darkAddFolder} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "darkTrash") {
    return <Image src={darkTrash} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "greyAddFolder") {
    return (
      <Image width={width} height={height} src={greyAddFolder} alt="N/A" />
    );
  }
  if (svgName === "blueAddFolder") {
    return (
      <Image src={blueAddFolder} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "greyChevronRight") {
    return (
      <Image src={greyChevronRight} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "allDone") {
    return <Image src={allDone} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "infoCircle") {
    return <Image src={infoCircle} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "shoppingBag") {
    return <Image src={shoppingBag} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "shoppingCart") {
    return <Image src={shoppingCart} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "watchlistEmptyFolder") {
    return (
      <Image
        src={watchlistEmptyFolder}
        width={width}
        height={height}
        alt="N/A"
      />
    );
  }
  if (svgName === "link") {
    return <Image src={link} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "etsyIcon") {
    return <Image src={etsyIcon} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "lock") {
    return <Image src={lock} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "etsySalesChannels") {
    return (
      <Image src={etsySalesChannels} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "logIn") {
    return <Image src={logIn} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "linkRed") {
    return <Image src={linkRed} width={width} height={height} alt="N/A" />;
  }
  if (svgName === "closeRedButton") {
    return (
      <Image src={closeRedButton} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "addStoreExpanded") {
    return (
      <Image src={addStoreExpanded} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "addStoreSmall") {
    return (
      <Image src={addStoreSmall} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "upsellTick") {
    return (
      <Image src={upsellTick} width={width} height={height} alt="N/A" />
    );
  }
  if (svgName === "giftIcon") {
    return (
      <Image src={giftIcon} width={width} height={height} alt="N/A" />
    );
  }
};

export default Svg;
