import React, { useState } from "react";
import Gift from "../../assets/svg/gift.svg";
import { Modal, Box, Typography, Button } from "@mui/material";
import Image from "next/image";
import TrialReminderWrapper from "./TrialReminderWrapper";
import { Close } from "@mui/icons-material";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { useLoader } from "../../contexts/LoaderContext";
import { ExtendMyFreeTrial, RemindMeLater } from "../../apis/account";
import { TrialEnded } from "./TrialExtensionSuccess";
import { useAuth } from "../../contexts/AuthContext";
import amplitude from "amplitude-js";

export const TrialReminder = ({
  open,
  onClose,
  reverseReminder,
  getCurrentUser,
}) => {
  const [extensionSuccess, setExtensionSuccess] = useState(false);
  const { addToLoadingSet, removeFromLoadingSet } = useLoader();
  const {
    authState: { trialData },
  } = useAuth();
  const updateFreeTrial = async () => {
    try {
      addToLoadingSet("extendMyFreeTrial");
      const data = await ExtendMyFreeTrial();
      // await getCurrentUser();
      onClose();
      amplitude.getInstance().logEvent("Trial Extended");
      setExtensionSuccess(true);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      removeFromLoadingSet("extendMyFreeTrial");
    }
  };

  const handleClose = () => {
    setExtensionSuccess(false);
  };
  const updateRemindMeLater = async () => {
    try {
      addToLoadingSet("remindMeLater");
      const data = await RemindMeLater();
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      removeFromLoadingSet("remindMeLater");
    }
  };

  return (
    <>
      <Modal
        disableAutoFocus
        open={open}
        onClose={reverseReminder && onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <TrialReminderWrapper>
          {reverseReminder && (
            <div className="close-wrapper">
              <Close
                onClick={onClose}
                sx={{
                  fontSize: { xs: "14px", md: "20px" },
                  color: "#646566",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <Image src={Gift} alt="gift-icon" />
            <Typography
              fontWeight="500"
              color="#19191a"
              fontSize="18px"
              lineHeight="24px"
            >
              Want your free trial extended?
            </Typography>
          </Box>
          <Box sx={{ border: "1px solid #EBEBEB", marginRight: "10px" }}></Box>
          <Box
            sx={{ display: "flex", padding: "16px", justifyContent: "center" }}
          >
            <Typography>
              Your {trialData?.total_trial_length} days trial for Everbee ended
              a few days ago.
            </Typography>
          </Box>
          <Box sx={{ padding: "10px 0px 15px 0px" }}>
            <Typography>
              If you would like more time to try Everbee for free, we'd be happy
              to give you a{" "}
              <span style={{ color: "#032954", fontWeight: "700" }}>
                free 7 day extension
              </span>{" "}
              of your trial
            </Typography>
          </Box>

          <Box sx={{ border: "1px solid #EBEBEB", marginRight: "10px" }}></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                "&.MuiButton-root": {
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={() => updateFreeTrial()}
            >
              Extend My Free Trial
            </Button>
            {reverseReminder && (
              <Button
                onClick={updateRemindMeLater}
                sx={{
                  "&.MuiButton-root": {
                    color: "#313233",
                    border: "1px solid #EBEBEB",
                  },
                }}
              >
                Remind Me Later
              </Button>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "3px", justifyContent: "center" }}>
            <Typography
              fontWeight="400"
              color="#19191a"
              fontSize="12px"
              lineHeight="16px"
            >
              If you are not interested in extending your trial, please
            </Typography>
            <Typography
              fontWeight="400"
              color="#1F76CC"
              fontSize="12px"
              lineHeight="16px"
              onClick={() =>
                window.open("https://forms.gle/cYRoDPEtTuc9Ajq3A", "_blank")
              }
              sx={{ cursor: "pointer" }}
            >
              tell us why
            </Typography>
          </Box>
        </TrialReminderWrapper>
      </Modal>
      <TrialEnded open={extensionSuccess} onClose={handleClose} />
    </>
  );
};
