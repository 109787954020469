import { Box, Icon, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import { InfoOutlined, TrendingUpRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import LockedIconComponent from "../LockedIconComponent";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { useEffect } from "react";
import { useDashboard } from "../../contexts/DashboardContext";

const useStyles = makeStyles(
  (theme) => ({
    cardOuterWrapper: {
      minHeight: "20%",
      width: "18%",
      cursor: "pointer",
      "&.MuiPaper-root": {
        borderRadius: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "250px",
        height: "98px",
        marginRight: "12px",
        "&.MuiPaper-root": {
          borderRadius: "5px",
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        minHeight: "20%",
        width: "18%",
        cursor: "pointer",
        "&.MuiPaper-root": {
          borderRadius: "14px",
        },
      },
    },
    cardInnerWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "relative",
      flexDirection: "column",
      padding: 20,
      gap: 15,
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
        paddingTop: "17px",
        paddingBottom: "17px",
        flexDirection: "row",
        gap: "18px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingLeft: "13px",
        paddingTop: "9px",
        paddingBottom: "17px",
        gap: "11px",
      },
    },
    cardInfoWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
        gap: "5px",
        justifyContent: "center",
      },
      [theme.breakpoints.between("sm", "md")]: {
        gap: "8px",
      },
    },
    cardTitle: {
      "&.MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "600",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
          fontWeight: "600",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "11px",
          fontWeight: "600",
        },
      },
    },
    cardValue: {
      "&.MuiTypography-root": {
        fontSize: "20px",
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          fontWeight: "500",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "14.06px",
          fontWeight: "500",
        },
      },
    },
    bottomText: {
      "&.MuiTypography-root": {
        fontSize: "12px",
        weight: "400",
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "8.43px",
          fontWeight: "500",
        },
      },
    },
  }),
  {
    name: "MuiCustomStyles",
  }
);

const Cards = ({ cardInfo, value, currencySymbol, description, isLoading }) => {
  const {
    salesChannelsState: { userAccountDetails, salesChannelsDetails },
  } = useSalesChannel();

  const { img, title, percentage, tooltipText } = cardInfo;
  const classes = useStyles();
  const { option, setOption } = useDashboard();
  return (
    <Paper
      sx={{ backgroundColor: "cardColor.main" }}
      elevation={0}
      className={classes.cardOuterWrapper}
      onClick={() => {
        title == "Gross revenue" && setOption("gross_revenue");
        title == "Avg. Order Value" && setOption("average_order_value");
        title == "Total Orders" && setOption("total_orders");
        title == "Lifetime Value" && setOption("ltv");
      }}
    >
      <Box className={classes.cardInnerWrapper}>
        <Icon sx={{ width: "40px", height: "40px" }}>
          <img src={img} alt="icon" />
        </Icon>
        <Box sx={{ position: "absolute", right: "16px", top: "10px" }}>
          <Tooltip title={tooltipText} placement="top" arrow>
            <InfoOutlined
              sx={{
                opacity: "0.3",
                fontSize: "15px",
                "&:hover": { opacity: "1" },
              }}
            />
          </Tooltip>
        </Box>

        <Box className={classes.cardInfoWrapper}>
          <Typography color="fontColor.secondary" className={classes.cardTitle}>
            {title}
          </Typography>
          {
            <>
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "10%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/loadingT.gif"
                    alt="loader"
                    sx={{ width: "50px" }}
                  />
                </Box>
              ) : (
                <Typography className={classes.cardValue} letterSpacing="2%">
                  {value?.toString().includes("Please upgrade") ? (
                    <LockedIconComponent />
                  ) : (
                    <>
                      {title !== "Total Orders" && title !== "Repeat Rate" && (
                        <>{currencySymbol}</>
                      )}

                      {salesChannelsDetails?.length === 0 ? "---" : value}
                    </>
                  )}
                </Typography>
              )}
            </>
          }

          {/* Required */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <TrendingUpRounded sx={{ color: "#11CA00" }} fontSize="small" />
            <Typography className={classes.bottomText}>
              <Typography variant="span" pr="3px" color="greenColor.main">
                {percentage}
              </Typography>
              {disc}
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Paper>
  );
};

export default Cards;
