import React from "react";
import { motion } from "framer-motion";

const FramerMotion = ({
  children,
  delay = 0.2,
  explicitClass = "",
  style = null,
}) => {
  return (
    <motion.div
      className={explicitClass}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: delay } }}
      style={style}
    >
      {children}
    </motion.div>
  );
};

export default FramerMotion;
