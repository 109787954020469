import { createContext, useContext, useState, useEffect } from "react";
const LoaderContext = createContext();
const LoaderProvider = ({ children }) => {
  const [loadingSet, setLoadingSet] = useState(new Set());
  const [openLearn, setOpenLearn] = useState(false);
  useEffect(() => {}, [loadingSet]);
  function addToLoadingSet(process) {
    setLoadingSet((prev) => new Set([...prev, process]));
  }
  function removeFromLoadingSet(process) {
    setLoadingSet((prev) => {
      const next = new Set(prev);
      next.delete(process);
      return next;
    });
  }
  return (
    <LoaderContext.Provider
      value={{
        loadingSet,
        setLoadingSet,
        addToLoadingSet,
        removeFromLoadingSet,
        openLearn,
        setOpenLearn,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};
const useLoader = () => useContext(LoaderContext);
export { LoaderProvider, useLoader };
