import Cookies from "js-cookie";

export const setCookie = (
  cookieName,
  cookieValue,
  options = { expires: 365 }
) => {
  Cookies.set(cookieName, cookieValue, options);
  // Example of options object - { expires: 7, path: '/' }
};

export const getCookie = (cookieName) => {
  return Cookies.get(cookieName);
};

export const removeCookie = (cookieName) => {
  Cookies.remove(cookieName);
};
