import Link from "next/link";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import StyledWrapper from "./StyledWrapper";
import { useAuth } from "../../contexts/AuthContext";
import NextLink from "next/link";
import { MAIN_MENU } from "../SidebarN/Sidebar";
import { useRouter } from "next/router";
import { useState } from "react";
import LockIcon from "../../assets/svg/lock-1.svg";
import Image from "next/image";
import { Tooltip, Typography } from "@mui/material";

const SideSubMenu = ({
  subMenuList,
  parentMainMenu,
  setMenuState,
  menuState,
}) => {
  const changeMainAndSubMenu = (slug, subMenuIdx) => {
    setMenuState(parentMainMenu);
    setActiveSubMenu({ value: slug, subMenuIndex: subMenuIdx });
  };

  const [activeSubMenu, setActiveSubMenu] = useState({
    value: null,
    subMenuIndex: null,
  });

  const {
    salesChannelsState: {
      salesChannelsDetails,
      sellerSalesChannelsDetails,
      userAccountDetails,
    },
    updateSelectedSalesChannels,
  } = useSalesChannel();

  const isUser =
    userAccountDetails?.current_plan?.includes("Growth") ||
    userAccountDetails?.current_plan?.includes("Business");
  return (
    <StyledWrapper isExpanded={false}>
      {subMenuList?.map((item, subMenuIdx) => {
        return (
          <li
            onClick={() => changeMainAndSubMenu(item.slug, subMenuIdx)}
            className={`sub-menu-item${
              activeSubMenu.value === item.slug && menuState !== "dashboard"
                ? " active-sub-menu"
                : ""
            }`}
            key={item.slug}
          >
            <Link
              href={
                item.check
                  ? isUser
                    ? `/${item.slug}`
                    : "/pricing"
                  : `/${item.slug}`
              }
            >
              {(item?.slug === "listingRankTracker" && isUser === false) ||
              (item?.name === "Trademark Monitor" && isUser === false) ? (
                <Tooltip
                  PopperProps={{
                    style: { zIndex: 9999999999, cursor: "pointer" },
                  }}
                  placement="right"
                  arrow
                  title={
                    <Typography
                      fontSize="12px"
                      fontWeight="500"
                      lineHeight="18px"
                      p="5px"
                    >
                      <Link href={"/pricing"}>
                        <span style={{ textDecoration: "underline" }}>
                          Upgrade To Growth Plan
                        </span>
                      </Link>
                    </Typography>
                  }
                >
                  <div className="lock-wrapper">
                    <span className="lock-show">{item.name}</span>{" "}
                    <Image src={LockIcon} alt="lock-icon" />{" "}
                  </div>
                </Tooltip>
              ) : (
                item.name
              )}
            </Link>
            <div
              className={`tree-box-left-only${
                subMenuIdx <= activeSubMenu.subMenuIndex
                  ? " active-tree-left-only"
                  : subMenuIdx === activeSubMenu.subMenuIndex + 1
                  ? " active-tree-left-only-height"
                  : ""
              }`}
            ></div>
            <div
              className={`tree-box-left-bottom${
                activeSubMenu.value === item.slug
                  ? " active-tree-left-bottom"
                  : activeSubMenu.subMenuIndex !== null &&
                    subMenuIdx <= activeSubMenu.subMenuIndex
                  ? " lower-active-tree-left-bottom"
                  : ""
              }`}
            ></div>
          </li>
        );
      })}
    </StyledWrapper>
  );
};

export default SideSubMenu;
