export const getFilterParameters = (url, filterObj) => {
  Object.keys(filterObj).forEach((params) => {
    if (params === "productName_Include")
      url += `&title_include=${filterObj[params]}`;
    else if (params === "productName_Exclude")
      url += `&title_exclude=${filterObj[params]}`;
    else if (params === "shopName_Include")
      url += `&shop_name_include=${filterObj[params]}`;
    else if (params === "shopName_Exclude")
      url += `&shop_name_exclude=${filterObj[params]}`;
    else if (params === "price_Max") url += `&price_max=${filterObj[params]}`;
    else if (params === "price_Min") url += `&price_min=${filterObj[params]}`;
    else if (params === "estMoSales_Max")
      url += `&est_mo_sales_max=${filterObj[params]}`;
    else if (params === "estMoSales_Min")
      url += `&est_mo_sales_min=${filterObj[params]}`;
    else if (params === "estMoRevenue_Max")
      url += `&est_mo_revenue_max=${filterObj[params]}`;
    else if (params === "estMoRevenue_Min")
      url += `&est_mo_revenue_min=${filterObj[params]}`;
    else if (params === "listingAge_Max")
      url += `&listing_age_in_months_max=${filterObj[params]}`;
    else if (params === "listingAge_Min")
      url += `&listing_age_in_months_min=${filterObj[params]}`;
    else if (params === "favorites_Max")
      url += `&num_favorers_max=${filterObj[params]}`;
    else if (params === "favorites_Min")
      url += `&num_favorers_min=${filterObj[params]}`;
    else if (params === "estTotalSales_Max")
      url += `&est_total_sales_max=${filterObj[params]}`;
    else if (params === "estTotalSales_Min")
      url += `&est_total_sales_min=${filterObj[params]}`;
    else if (params === "reviews_Max")
      url += `&est_reviews_max=${filterObj[params]}`;
    else if (params === "reviews_Min")
      url += `&est_reviews_min=${filterObj[params]}`;
    else if (params === "tagsUsed_Max")
      url += `&tags_used_max=${filterObj[params]}`;
    else if (params === "tagsUsed_Min")
      url += `&tags_used_min=${filterObj[params]}`;
    // else if (params === "descriptionCharacterCount_Max")
    //   url += `&description_character_count_max=${filterObj[params]}`;
    // else if (params === "descriptionCharacterCount_Min")
    //   url += `&description_character_count_min=${filterObj[params]}`;
    else if (params === "minimumProcessing_Max")
      url += `&processing_min_max=${filterObj[params]}`;
    else if (params === "minimumProcessing_Min")
      url += `&processing_min_min=${filterObj[params]}`;
    else if (params === "placementOfListingInShop_Max")
      url += `&featured_rank_max=${filterObj[params]}`;
    else if (params === "placementOfListingInShop_Min")
      url += `&featured_rank_min=${filterObj[params]}`;
    else if (params === "shippedFrom_Bol")
      url += `&shipping_from_country_iso=${filterObj[params]}`;
    else if (params === "shopAge_Max")
      url += `&shop_age_month_max=${filterObj[params]}`;
    else if (params === "shopAge_Min")
      url += `&shop_age_month_min=${filterObj[params]}`;
    else if (params === "shopDigitalListingCount_Max")
      url += `&digital_listing_count_max=${filterObj[params]}`;
    else if (params === "shopDigitalListingCount_Min")
      url += `&digital_listing_count_min=${filterObj[params]}`;
    else if (params === "titleCharacter_Max")
      url += `&title_character_count_max=${filterObj[params]}`;
    else if (params === "titleCharacter_min")
      url += `&title_character_count_min=${filterObj[params]}`;
    else if (params === "visibilityScore_Max")
      url += `&visibility_score_max=${filterObj[params]}`;
    else if (params === "visibilityScore_Min")
      url += `&visibility_score_min=${filterObj[params]}`;
    else if (params === "isCustomizable_Bol")
      url += `&is_customizable=${filterObj[params]}`;
    else if (params === "isPersonalizable_Bol")
      url += `&is_personalizable=${filterObj[params]}`;
    else if (params === "hasVariations_Bol")
      url += `&has_variations=${filterObj[params]}`;
    else if (params === "isSupply_Bol")
      url += `&is_supply=${filterObj[params]}`;
    else if (params === "shopAge_Max")
      url += `&shop_age_month_max=${filterObj[params]}`;
    else if (params === "shopAge_Min")
      url += `&shop_age_month_min=${filterObj[params]}`;
    else if (params === "conversionRate_Max")
      url += `&conversion_rate_max=${filterObj[params]}`;
    else if (params === "conversionRate_Min")
      url += `&conversion_rate_min=${filterObj[params]}`;
    else if (params === "views_Max") url += `&views_max=${filterObj[params]}`;
    else if (params === "views_Min") url += `&views_min=${filterObj[params]}`;
    else if (params === "listingTye_Bol")
      url += `&listing_type=${filterObj[params]}`;
    else if (params === "whoMade_Include")
      url += `&who_made=${filterObj[params]}`;
    else if (params === "whenMade_Include")
      url += `&when_made=${filterObj[params]}`;
    else if (params === "placementOfListingInShop_Max")
      url += `&featured_rank_max=${filterObj[params]}`;
    else if (params === "placementOfListingInShop_Min")
      url += `&featured_rank_min=${filterObj[params]}`;
    else if (params === "keyword_Include")
      url += `&keyword_include=${filterObj[params]}`;
    else if (params === "category_Bol")
      url += `&category_include=${filterObj[params].replace("&", "%26")}`;
    else if (params === "approach_Bol")
      url += `&approach=${filterObj[params].replace("&", "%26")}`;
    else if (params === "keyword_Exclude")
      url += `&keyword_exclude=${filterObj[params]}`;
    else if (params === "vol_Max")
      url += `&new_volume_max=${filterObj[params]}`;
    else if (params === "vol_Min")
      url += `&new_volume_min=${filterObj[params]}`;
    else if (params === "competition_Max")
      url += `&competition_max=${filterObj[params]}`;
    else if (params === "competition_Min")
      url += `&competition_min=${filterObj[params]}`;
    else if (params === "shopSales_Max")
      url += `&transaction_sold_count_max=${filterObj[params]}`;
    else if (params === "shopSales_Min")
      url += `&transaction_sold_count_min=${filterObj[params]}`;
    else if (params === "score_Max") url += `&score_max=${filterObj[params]}`;
    else if (params === "score_Min") url += `&score_min=${filterObj[params]}`;
  });

  return url;
};
