import axios from "axios";
import { getCookie } from "../helper/cookies";

const AUTH_BASE_URL = process.env.NEXT_PUBLIC_SSO_APP;

export const accountDetails = (payload) =>
  axios.get(`${AUTH_BASE_URL}/account/12`);

export const disconnectEtsy = (xToken, xEmail) =>
  axios.get(`${AUTH_BASE_URL}/etsy/disconnect_etsy`);

export const getStripeSubscription = (payload) =>
  axios.get(`${AUTH_BASE_URL}/stripe_subscriptions/ids`, payload);

export const deleteStripeSubscription = (payload) =>
  axios.delete(`${AUTH_BASE_URL}/stripe_subscriptions/ids`, payload);

export const salesChannels = (payload) =>
  axios.get(`${AUTH_BASE_URL}/sales_channels`, payload);

export const deleteSalesChannels = (payload) =>
  axios.delete(`${AUTH_BASE_URL}/sales_channels/${payload}`);

export const transferSalesChannels = (payload) =>
  axios.put(
    `${AUTH_BASE_URL}/users/transfer_sales_channels?sales_channel_id=${payload}`
  );

export const rewardfulCookies = () => {
  var cookies = getCookie("rewardful.referral");
  if (cookies) {
    axios.put(`${AUTH_BASE_URL}/users/set_affiliate_cookie`, {
      affiliate_cookie: cookies,
    });
  }
};

export const trackRewardful = (name, location) => {
  return axios.post(`https://api.getrewardful.com/referrals/track`, {
    api_key: "605017",
    location: `${location}`,
    referrer: "",
    title: "Pricing Page",
    link_token: `${name}`,
  });
};

export const subscriptionReminder = () =>
  axios.put(`${AUTH_BASE_URL}/users/remind_me_later_for_subscription`);

export const cancelSubscriptionReminderPopup = () =>
  axios.put(
    `${AUTH_BASE_URL}/users/clicked_on_close_upgrade_subscription_popup`
  );

const measurement_id = process.env.NEXT_PUBLIC_IS_DEV
  ? `G-WKPVGJ47CR`
  : `G-V6SXEHYL1Q`;
const api_secret = process.env.NEXT_PUBLIC_IS_DEV
  ? `GDHG_kqRTiCvjcUMxAYaYw`
  : `mwTXyPmRROaK41kyrVOaqA`;

export const googleAnalyticsEvent = (payload) =>
  fetch(
    `https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`,
    {
      method: "POST",
      body: JSON.stringify({
        client_id: localStorage.getItem("everbeeId"),
        events: [
          {
            name: payload.eventName,
            params: {
              session_id: Date.now().toString(),
              engagement_time_msec: 100,
              ...payload.eventPrams,
            },
          },
        ],
      }),
    }
  )
    .then((response) => {})
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });

export const googleAnalyticsUsers = (payload) =>
  fetch(
    `https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`,
    {
      method: "POST",
      body: JSON.stringify({
        client_id: localStorage.getItem("everbeeId"),
        user_properties: payload,
        events: [
          {
            name: "setting_user_properties",
            params: {
              session_id: Date.now().toString(),
              engagement_time_msec: 100,
            },
          },
        ],
      }),
    }
  )
    .then((response) => {})
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });

export const ExtendMyFreeTrial = (payload) =>
  axios.put(`${AUTH_BASE_URL}/users/extend_trial`);

export const RemindMeLater = (payload) =>
  axios.put(`${AUTH_BASE_URL}/users/set_remind_later_for_trial_popup`);

export const StayOnFreePlan = (payload) =>
  axios.put(`${AUTH_BASE_URL}/users/stay_on_free_plan_for_reverse_trial`);

export const closeTrialIntoModal = () =>
  axios.put(`${AUTH_BASE_URL}/users/get_started_on_trial`);

export const saveTableStateApi = (payload) =>
  axios.put(`${AUTH_BASE_URL}/users/set_product_clm_order`, {
    product_analytics_column_order: payload,
  });

export const onboardingFlow = () =>
  axios.put(`${AUTH_BASE_URL}/users/complete_onboarding_flow`, {});

export const saveNpsCsatData = (surveyId, response) => {
  return axios.put(`${AUTH_BASE_URL}/surveys/${surveyId}`, response);
};

export const getStripeSubscriptionId = () => {
  return axios.get(
    `${AUTH_BASE_URL}/stripe_subscriptions/core_subscription_id`
  );
};

//Get new jwt token
export const getNewToken = () =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_SSO_BACKEND_APP
    }/verify_uid_token_to_jwt?email=${localStorage.getItem("everbeeEmail")}`
  );

  export const onboardingInfoFlow = () => {
    const token = getCookie(`${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return axios.patch(
      `${process.env.NEXT_PUBLIC_SSO_BACKEND_APP}/update_sidebar_onboarding_shown`,
      {},
      { headers }
    );
  };
  