import styled from "styled-components";

const StyledWrapper = styled.div`
  cursor: pointer;
  max-width: 98%;
  height: 2.25rem;
  margin-top: 0.5rem;
  border-radius: 6px;
  padding: 0.375rem 0.2rem 0.375rem 0.2rem;
  box-shadow: ${({ isActive }) => isActive && "0px 1px 2px rgba(0, 0, 0, 0.1)"};
  display: flex;
  align-items: center;
  justify-content: ${({ isExpanded }) =>
    isExpanded ? "space-between" : "center"};

  .menu-seperator {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .navitem-text {
    color: ${({ isActive }) => (isActive ? "19191A" : "#505152")};
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 20px;
  }

  .arrow-container {
    margin-right: 0.75rem;
  }

  .lock-shadow {
    color:${({ isUser, navText }) => (isUser === true && navText === 'Optimize') ? '#cccccc !important' : ''};
  }

  .optimize-div {
    display: flex;
    gap: 0.5rem;
  }

  .top-optimize {
    margin-top: 3px;
  }
`;

export default StyledWrapper;
