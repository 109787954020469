/*global chrome*/
import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "./Loader";
import { getSubdomain } from "../helper/getSubdomain";
import { useAuth } from "../contexts/AuthContext";

export function ReconnectModal({
  reconnectModal,
  setReconnectModal,
  handleClose,
  everbeeToken,
  from,
  shopName,
}) {
  const {
    authState: { everbeeUserId },
  } = useAuth();
  const scopes =
    "listings_r listings_w listings_d email_r shops_r shops_w transactions_r transactions_w profile_r address_r";

  async function connectToEtsy() {
    var urlState = "";
    urlState = `${everbeeUserId}.${window.location.href}`;
    window.location.href = `https://www.etsy.com/oauth/connect?response_type=code&client_id=uarm508l32t4exk5sjengk1c&redirect_uri=${process.env.NEXT_PUBLIC_REDIRECT_URI}&scope=${scopes}&state=${urlState}&code_challenge=wq9up7xVG7ADNs_yHtTjH2Vm1lL0BU6pVhZpXBeXTpg
          &code_challenge_method=S256`;
  }

  return (
    <Modal
      disableAutoFocus
      open={reconnectModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // style={{
      //   position: "absolute",
      //   top: "20%",
      //   left: "20%",
      //   right: "20%",
      //   bottom: "20%",
      //   backgroundColor: "white",
      //   borderRadius: "20px",
      // }}
    >
      <Box
        component="main"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          height: 400,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // display: "flex",
          flexGrow: 1,
          borderRadius: "8px",
        }}
      >
        <img
          src="/picture-cnt.png"
          alt="Picture of the author"
          style={{
            borderBottomLeftRadius: "8px",
            borderTopLeftRadius: "8px",
          }}
        />
        <Container>
          <Typography
            color="textSecondary"
            sx={{ fontWeight: "500", fontSize: "1.5rem", lineHeight: "1" }}
            gutterBottom
            variant="body2"
          >
            {from == "EverBee Print"
              ? "Get Started with EverBee Print"
              : from == "EverBee Email" && "Get Started with EverBee Email"}
          </Typography>
          {shopName ? (
            <Typography
              color="textSecondary"
              sx={{ fontWeight: "400", fontSize: "16px" }}
              gutterBottom
              variant="body2"
            >
              Let's re-connect your Etsy store {shopName} to make sure
              everything is synced nicely.
            </Typography>
          ) : (
            <Typography
              color="textSecondary"
              sx={{ fontWeight: "400", fontSize: "16px" }}
              gutterBottom
              variant="body2"
            >
              Let's re-connect your Etsy store to make sure everything is synced
              nicely.
            </Typography>
          )}
          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              style={{
                color: "white",
                backgroundColor: "#58b4f4",
                fontSize: "16px",
                fontWeight: "600",
              }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                connectToEtsy();
              }}
            >
              Reconnect Now
            </Button>
          </Box>
        </Container>
      </Box>
    </Modal>
  );
}
