import { useState, useEffect } from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import StoreSelectItem from "./StoreSelectItem";
import StyledWrapper from "./StyledWrapper";
import arrowUpDown from "../../assets/svg/arrow-up-down.svg";
import PlusIcon from "../../assets/svg/plus.svg";
import avatarPic from "../../assets/images/test-avatar.jpg";
import { Avatar, ClickAwayListener, IconButton, Box } from "@mui/material";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { useAuth } from "../../contexts/AuthContext";
import Empty from "../../assets/svg/empty.svg";
import {
  AddCircle,
  VisibilityOffRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { setHideShopName } from "../../apis/dashboard";
import show from "../../assets/svg/show.svg";
import hide from "../../assets/svg/hide.svg";
import Svg from "../Svg/Svg";
import { getSubdomain } from "../../helper/getSubdomain";

const StoreSelect = ({
  isExpanded,
  setIsExpanded,
  selectedStores,
  storeList,
  setStoreSelected,
}) => {
  const [isStoreSelectActive, setIsStoreSelectActive] = useState(false);

  const [isConnectShopModal, setIsConnectShopModal] = useState(false);
  const {
    authState: { everbeeUserId },
  } = useAuth();
  const {
    salesChannelsState: {
      selectedShops,
      salesChannelsDetails,
      sellerSalesChannelsDetails,
      userAccountDetails,
    },
    updateSelectedSalesChannels,
    salesChannelsDispatch,
  } = useSalesChannel();

  const [disabled, setDisabled] = useState(false);

  const updateDefaultSalesChannel = (salesChannelId) => {
    updateSelectedSalesChannels(salesChannelId);
  };

  const handleStoreClicked = (child) => {
    storeList.map((storeChild) => {
      if (storeChild.name == child) {
        //update in store
        storeChild.isChecked = true;
        //update in selected store
        setStoreSelected({
          id: storeChild.id,
          name: storeChild.name,
          sales: 2346,
          isChecked: true,
          avatar: avatarPic,
        });
        updateDefaultSalesChannel(storeChild?.id);
      } else {
        storeChild.isChecked = false;
      }
    });

    setIsStoreSelectActive(false);
  };

  const handleCollapsedView = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      setIsStoreSelectActive(true);
      localStorage.setItem("sidebarExpanded", true);
    }
  };
  useEffect(() => {
    if (!isExpanded) {
      setIsStoreSelectActive(false);
    }
  }, [isExpanded]);
  const handleClickAway = () => {
    setIsStoreSelectActive(false);
  };
  const scopes =
    "listings_r listings_w listings_d email_r shops_r shops_w transactions_r transactions_w profile_r address_r";
  const subDomain = getSubdomain();

  const handleButtonClick = () => {


    var urlState = "";
    urlState = `${everbeeUserId}.${window.location.href}`;
    window.location.href = `https://www.etsy.com/oauth/connect?response_type=code&client_id=uarm508l32t4exk5sjengk1c&redirect_uri=${process.env.NEXT_PUBLIC_REDIRECT_URI}&scope=${scopes}&state=${urlState}&code_challenge=wq9up7xVG7ADNs_yHtTjH2Vm1lL0BU6pVhZpXBeXTpg
          &code_challenge_method=S256`;
          
    setIsConnectShopModal(true);
    setIsStoreSelectActive(false);
  };

  const updateHide = async (hide) => {
    try {
      setDisabled(true);
      const resp = await setHideShopName(hide);
      salesChannelsDispatch({
        type: "SET_USER_ACCOUNT_DETAILS",
        payload: {
          ...userAccountDetails,
          hide_details: resp.data.current_user.hide_details,
        },
      });
    } catch (error) {
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {storeList.length == 0 ? (
        <StyledWrapper
          isExpanded={isExpanded}
          onClick={() => handleButtonClick()}
        >
          {isExpanded ? (
            <div className="add-store-container">
              <Svg svgName={"addStoreExpanded"} width={"214px"} />
            </div>
          ) : (
            <div className="add-store-container">
              <Svg width={"60px"} svgName={"addStoreSmall"} />
            </div>
          )}
        </StyledWrapper>
      ) : (
        <StyledWrapper isExpanded={true}>
          {isExpanded && (
            <div
              className="store-wrapper"
              onClick={() => setIsStoreSelectActive((prev) => !prev)}
            >
              <div className="store-container">
                {selectedStores !== undefined &&
                !userAccountDetails?.hide_details ? (
                  <Avatar
                    alt="Store Avatar"
                    sx={{ background: "#3d98f2" }}
                    src={selectedStores?.imageUrl}
                  >
                    {selectedStores?.name?.substring(0, 1)}
                  </Avatar>
                ) : userAccountDetails?.hide_details ? (
                  <Image src={Empty} alt="empty-icon" />
                ) : (
                  <Image src={Empty} alt="empty-icon" />
                )}
                <div>
                  {!userAccountDetails?.hide_details ? (
                    <div className="store-name">{selectedStores?.name}</div>
                  ) : (
                    <div className="store-name">********</div>
                  )}
                </div>
              </div>

              <div>
                <Image
                  className="icon-toggle"
                  src={arrowUpDown}
                  alt="Arrow up-down toggle"
                />
              </div>
              {isStoreSelectActive ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div
                    className="store-select-menu"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="sales-channels">
                      <p>Sales Channels</p>
                      <IconButton
                        onClick={() => {
                          updateHide(!userAccountDetails?.hide_details);
                        }}
                        disabled={disabled || selectedStores === undefined}
                        sx={{
                          width: "50px",
                          height: "21px",
                          borderRadius: 0,
                          width: "auto",
                          "&.MuiIconButton-root:hover": {
                            borderRadius: 0,
                            backgroundColor: "#fff",
                          },
                        }}
                      >
                        {!userAccountDetails?.hide_details ? (
                          <div className="show-hide-wrapper">
                            {disabled === true ? (
                              <Box
                                component="img"
                                src="/loadingT.gif"
                                alt="loader"
                                sx={{ width: "25px" }}
                              />
                            ) : (
                              <Image src={hide} alt="hide-icon" />
                            )}
                            <p className="text-wrapper">Hide All</p>
                          </div>
                        ) : (
                          <div className="show-hide-wrapper">
                            {disabled === true ? (
                              <Box
                                component="img"
                                src="/loadingT.gif"
                                alt="loader"
                                sx={{ width: "25px" }}
                              />
                            ) : (
                              <Image src={show} alt="show-icon" />
                            )}
                            <div>
                              <p className="text-wrapper">Show All</p>
                            </div>
                          </div>
                        )}
                      </IconButton>
                    </div>
                    {/* </div> */}
                    <div className="store-list">
                      {storeList.map(({ name, isChecked }) => (
                        <StoreSelectItem
                          key={name}
                          name={name}
                          isChecked={isChecked}
                          onClick={() => handleStoreClicked(name)}
                        />
                      ))}
                    </div>
                    <div className="add-new-wrapper">
                      <button
                        onClick={() => handleButtonClick()}
                        className="btn-add-new"
                      >
                        <Image src={PlusIcon} alt="Plus Icon" />
                        <span className="btn-text">Add New</span>
                      </button>
                    </div>
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          )}

          {!isExpanded && (
            <div className="store-wrapper">
              {selectedStores !== undefined &&
              !userAccountDetails?.hide_details ? (
                <Avatar
                  alt="Store Avatar"
                  sx={{ background: "#3d98f2" }}
                  onClick={handleCollapsedView}
                  src={selectedStores?.imageUrl}
                >
                  {selectedStores?.name?.substring(0, 1)}
                </Avatar>
              ) : userAccountDetails?.hide_details ? (
                <Image src={Empty} alt="empty-icon" />
              ) : (
                <Image src={Empty} alt="empty-icon" />
              )}
            </div>
          )}
        </StyledWrapper>
      )}
    </>
  );
};

export default StoreSelect;
