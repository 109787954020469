import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "../helper/cookies";

axios.defaults.headers = {
  Accept: "applicaion/json",
  "Content-Type": "application/json",
};
const AUTH_BASE_URL = process.env.NEXT_PUBLIC_SSO_APP;
const issueLogoutRequest = () => {
  window.onbeforeunload = null;
  window.location.href = "/logout";
};

const handleRequest = (config) => {
  const token = getCookie(`${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`);
  if (token) {
    config.headers["X-Access-Token"] = token;
  }

  return config;
};

const handleSuccessResponse = (response, setOpenPricingModal) => {
  if (response?.data?.remind_for_upgrade_to_growth_annual) {
    setOpenPricingModal(true);
  }
  return response;
};

const handleErrorResponse = (error, setOpenSystemDownModal) => {
  if (error.response.status == 404 || error.response.status == 403) {
    // setShowErrorPage(true);
  } else if (error.response.status == 400) {
    // setOpenMaintainanceModal(true);
    // setOpenSystemDownModal(true);
  } else if (error.response.status == 500) {
    // setOpenMaintainanceModal(true);
    // setOpenSystemDownModal(true);
    if (error.data.error_data.error.code !== "card_declined") {
      toast.error("Taking longer than expected… Try reloading page");
    }
  }
  return Promise.reject(error);
};

export const registerRequestIntercept = () => {
  axios.interceptors.request.use(handleRequest);
};

export const registerResponseIntercept = (
  setOpenPricingModal,
  setOpenMaintainanceModal,
  setOpenSystemDownModal
) => {
  axios.interceptors.response.use(
    (response) => {
      return handleSuccessResponse(response, setOpenPricingModal);
    },
    (error) => {
      return handleErrorResponse(error, setOpenSystemDownModal);
    }
  );
};

export const bogoOffer = () =>
  axios.get(`${AUTH_BASE_URL}/users/bogo_eligible`);
