export const plans = {
  hobby: "Hobby",
  growthmonth: process.env.NEXT_PUBLIC_IS_DEV
    ? "Growth Month Test"
    : "Growth plan",
  growthannualy: process.env.NEXT_PUBLIC_IS_DEV
    ? "Growth Annual Test"
    : "Growth Plan Annual",
  pro: process.env.NEXT_PUBLIC_IS_DEV ? "Pro Test" : "Pro",
  businessannualy: process.env.NEXT_PUBLIC_IS_DEV
    ? "Business Annual Test"
    : "Business Plan Annual",
  businessmonth: process.env.NEXT_PUBLIC_IS_DEV
    ? "Business Month Test"
    : "Business plan",
  bogoplan: process.env.NEXT_PUBLIC_IS_DEV
    ? "Growth Month Test"
    : "Growth plan",
};

export const plansPriceId = {
  growthmonth: process.env.NEXT_PUBLIC_IS_DEV
    ? "price_1JZcsuAprxaDl4vmUJxpdFcH"
    : "price_1IvnMKAprxaDl4vmq0SruLOC",
  growthannualy: process.env.NEXT_PUBLIC_IS_DEV
    ? "price_1JZcvAAprxaDl4vm1aUlvglm"
    : "price_1JYDSDAprxaDl4vmf6AKIJsF",
  pro: process.env.NEXT_PUBLIC_IS_DEV
    ? "price_1K4RYHAprxaDl4vmrib4Pwx3"
    : "price_1K5CADAprxaDl4vmhrBk6o6E",
  businessmonth: process.env.NEXT_PUBLIC_IS_DEV
    ? "price_1NXoH2AprxaDl4vmEiZjjS16"
    : "price_1NYFD1AprxaDl4vmsNzYl0G8",
  businessannualy: process.env.NEXT_PUBLIC_IS_DEV
    ? "price_1NXoIFAprxaDl4vmcgt2dLIW"
    : "price_1NYFDwAprxaDl4vmcNBmJieC",
};
