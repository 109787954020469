import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { Router } from "next/router";
import Link from "next/link";
import { useAuth } from "../../contexts/AuthContext";
import CustomizedSwitch from "../CustomizedSwitch";

const useStyles = makeStyles(
  (theme) => ({
    menuItems: {
      "&.MuiButtonBase-root": {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "400",
      },
    },
  }),
  {
    name: "HeaderDropdownStyles",
  }
);
const HeaderDropdown = ({ open, setOpen, anchorRef }) => {
  const {
    authState: { everbeeName, everbeeEmail },
    logoutUser,
  } = useAuth();
  const classes = useStyles();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        style={{ zIndex: 3 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "top-start" ? "left top" : "left top",
            }}
          >
            <Paper
              sx={{
                width: { xs: "190px", sm: "200px" },
                borderRadius: "12px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      margin: { xs: "5px 10px 0px 10px" },
                    }}
                  >
                    <Typography
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="24px"
                    >
                      {everbeeName}
                    </Typography>
                    <Typography
                      fontSize="10px"
                      fontWeight="500"
                      lineHeight="22px"
                    >
                      {everbeeEmail}
                    </Typography>
                  </Box>
                  <CustomizedSwitch />
                  <Divider />
                  <Link href={`/account?tabname=profile`}>
                    <MenuItem className={classes.menuItems}>Account</MenuItem>
                  </Link>
                  <MenuItem
                    className={classes.menuItems}
                    onClick={() => {
                      // $crisp.push(["do", "chat:open"]);
                      gist.chat("open")
                    }}
                  >
                    Support
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItems}
                    onClick={() => {
                      window.open(
                        "https://everbee.canny.io/wish-list",
                        "_blank"
                      );
                    }}
                  >
                    Wish-List
                  </MenuItem>
                  <MenuItem className={classes.menuItems} onClick={logoutUser}>
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default HeaderDropdown;
