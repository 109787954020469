export const tradeMarkMonitorReducer = (state, { type, payload }) => {
  switch (type) {
    case "INITIAL_TABLE_DATA":
      return {
        ...state,
        tableRowData: payload.listings,
        flaggedListings: payload.flagged_listings,
        scannedListings: payload.scanned_listings,
        totalAlerts: payload.total_alerts,
        totalPageCount: payload.total_pages,
      };
    case "INITIAL_MONITORED_KEYWORD_TABLE":
      return {
        ...state,
        monitoredKeywordRowData: payload.rowDataArrays,
        rowCountOfMonitoredKeywords: payload.totalCount,
      };
    case "INCREMENT_MONITORED_KEYWORD_PAGE":
      return {
        ...state,
        monitoredKeywordRowData: state.monitoredKeywordRowData.concat([
          ...payload.rowDataArrays,
        ]),
        rowCountOfMonitoredKeywords: payload.totalCount,
      };
    case "SET_MONITORED_KEYWORD_CHECKBOX_ARRAY":
      return {
        ...state,
        monitoredKeywordsCheckboxArr: payload,
      };
    case "ADD_MONITORED__IN_TABLE":
      return {
        ...state,
        monitoredKeywordRowData: [...payload, ...state.monitoredKeywordRowData],
      };
    case "INCREMENT_PAGE":
      return {
        ...state,
        tableRowData: state.tableRowData.concat([...payload.listings]),
      };
    case "UPDATE_SIDEBAR_MENU_INDICATOR":
      return {
        ...state,
        trademarkIndicatorCount: payload,
      };
    case "RIGHT_SIDE_PANE_API":
      return {
        ...state,
        tradeMarkTagRowData: payload.trademarks,
        tradeMarkListingData: payload.listing,
      };
    case "UPDATE_RIGHT_PANE_STATUS":
      return {
        ...state,
        isRightSidePaneOpen: payload,
      };
    case "UPDATE_TABLE_WITH_UNSNOOZE_API":
      return {
        ...state,
        tableRowData: payload.rowDataArray,
        totalAlerts: state.totalAlerts + payload.alerts,
        flaggedListings: state.flaggedListings + payload.flagged,
        trademarkIndicatorCount:
          state.trademarkIndicatorCount + payload.flagged,
      };
    case "UPDATE_TABLE_WITH_SNOOZE_API":
      return {
        ...state,
        tableRowData: payload.rowDataArray,
        totalAlerts: state.totalAlerts - payload.alerts,
        flaggedListings: state.flaggedListings - payload.unflagged,
        trademarkIndicatorCount:
          state.trademarkIndicatorCount - payload.unflagged,
      };
    case "UPDATE_ENALRGE_TABLE":
      return {
        ...state,
        enlargeRowDataTrademark: payload.rowDataArray,
        trademarkEnlargeTableData: payload.singleRowData,
      };
    case "UPDATE_TRADEMARK_TAG_ROW_DATA":
      var keywordIds = payload.keywordIds;
      var tradeMarkTagRowData_ = state.tradeMarkTagRowData;
      var updatedTradeMarkTagRowData = tradeMarkTagRowData_.map((item) => {
        if (keywordIds.includes(item.keywordId)) {
          item["snoozeStatus"] = 1;
        }
        return item;
      });
      return {
        ...state,
        tradeMarkTagRowData: [...updatedTradeMarkTagRowData],
      };
    case "UPDATE_TRADEMARK_TAG_ROW_DATA_FOR_UNSNOOZE":
      var keywordIds = payload.keywordIds;
      var tradeMarkTagRowData_ = state.tradeMarkTagRowData;
      var updatedTradeMarkTagRowData = tradeMarkTagRowData_.map((item) => {
        if (keywordIds.includes(item.keywordId)) {
          item["snoozeStatus"] = 0;
        }
        return item;
      });
      return {
        ...state,
        tradeMarkTagRowData: [...updatedTradeMarkTagRowData],
      };
    case "UPDATE_MONITORED_KEYWORD_ROW_DATA_FOR_SNOOZE":
      var keywordId_ = payload.keywordId;
      var monitoredKeywordRowData_ = [...state.monitoredKeywordRowData];
      var index_ = 0;
      var monitoredKeywordData = monitoredKeywordRowData_.map((item, index) => {
        if (keywordId_.includes(item.keywordId)) {
          item["snoozeStatus"] = 1;
          index_ = index;
        }
        return item;
      });
      var temp = monitoredKeywordRowData_;
      if (!state.showSnoozed) {
        if (keywordId_.length > 1) {
          var filteredTemp = temp.filter(
            (item) => !keywordId_.includes(item.keywordId)
          );
          temp = [...filteredTemp];
        } else {
          temp.splice(index_, 1);
        }
      }
      return {
        ...state,
        monitoredKeywordRowData: [...temp],
        tradeMarkTagRowData: [...temp],
        monitoredKeywordsCheckboxArr: [],
      };
    case "UPDATE_MONITORED_KEYWORD_ROW_DATA_FOR_UNSNOOZE":
      var keywordId_ = payload.keywordId;
      var monitoredKeywordRowData_ = [...state.monitoredKeywordRowData];
      var index_ = 0;
      var monitoredKeywordData = monitoredKeywordRowData_?.find(
        (item, index) => {
          if (item.keywordId == keywordId_) {
            index_ = index;
            return true;
          }
          return false;
        }
      );

      if (monitoredKeywordData) {
        monitoredKeywordData["snoozeStatus"] = 0;
        monitoredKeywordRowData_[index_] = monitoredKeywordData;
      }

      return {
        ...state,
        tradeMarkTagRowData: [...monitoredKeywordRowData_],
      };
    case "SELECT_ALL_CHECKBOX_FOR_SNOOZE":
      var toBeSnoozedIds = [];
      var monitoredKeywordRowData_ = [...state.monitoredKeywordRowData];
      var monitoredKeywordData = monitoredKeywordRowData_.map((item) => {
        if (item["snoozeStatus"] == 0) {
          toBeSnoozedIds.push(item.keywordId);
        }
      });
      return {
        ...state,
        selectAllMonitoredKeywords: true,
        monitoredKeywordsCheckboxArr: [...toBeSnoozedIds],
      };
    case "UNSELECT_ALL_CHECKBOX_FOR_SNOOZE":
      return {
        ...state,
        selectAllMonitoredKeywords: false,
        monitoredKeywordsCheckboxArr: [],
      };
    case "SNOOZE_ALL_KEYWORD":
      var monitoredKeywordRowData_ = [...state.monitoredKeywordRowData];
      var monitoredKeywordData = monitoredKeywordRowData_.map((item) => {
        item["snoozeStatus"] = 1;
      });
      return {
        ...state,
        tradeMarkTagRowData: [...monitoredKeywordRowData_],
        monitoredKeywordsCheckboxArr: [],
        selectAllMonitoredKeywords: false,
      };
    case "UPDATE_SHOW_SNOOZED":
      return {
        ...state,
        showSnoozed: !state.showSnoozed,
      };
    case "UPDATE_HIDE_NON_TRADEMARK":
      return {
        ...state,
        showHideNonTrademark: !state.showHideNonTrademark,
      };
    case "SHOW_KEYWORDS_WITH_TRADEMARK":
      return {
        ...state,
        showHideNonTrademark: true,
        showSnoozed: false,
      };
    case "KEYWORD_LISTING_DETAILS":
      return {
        ...state,
        listingForKeyword: payload.listings,
        totalPageCountForListings: payload.totalPages,
        totalListingsCount: payload.totalListingsCount,
      };
    case "KEYWORD_LISTING_DETAILS_INCREMENT_PAGE":
      return {
        ...state,
        listingForKeyword: state.listingForKeyword.concat([
          ...payload.listings,
        ]),
      };
    case "KEYWORD_IDENTIFIER_LISTINGS":
      return {
        ...state,
        keywordListingIdentifier: payload,
      };
  }
};
