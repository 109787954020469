import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "../contexts/ColorModeContext";

export const Toastr = () => {
  const { mode } = useTheme();
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        limit={4}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={mode}
        style={{ zIndex: "99999999" }}
      />
    </div>
  );
};
