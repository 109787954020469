import { Box, Drawer } from "@mui/material";

import React from "react";

import Sidebar from "./SidebarN/Sidebar";

const MiniSideBar = ({ isExpanded, setIsExpanded }) => {
  return (
    <Box component="nav">
      <Drawer
        variant="permanent"
        open={isExpanded}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "background.secondary",
          },
        }}
      >
        <Sidebar
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          tabView={true}
        />
      </Drawer>
    </Box>
  );
};

export default MiniSideBar;
