import { styled } from "@mui/material/styles";
// import SideBar from "./SideBar";
import MiniSideBar from "./MiniSideBar";
import { Box, Button, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import AppBarComponent from "./AppBarComponent";
import Header from "./dashboardComponents/Header";
import { useRouter } from "next/router";
import { useSalesChannel } from "../contexts/SalesChannelContext";
import { useEffect, useState } from "react";
import OnboardingModal from "./dashboardComponents/OnboardingModal";
import { useAuth } from "../contexts/AuthContext";
import { useExtension } from "../contexts/ExtensionContext";
import { useTour } from "@reactour/tour";
import LearningModulePane from "./learningModule/LearningModulePane";
import { useLoader } from "../contexts/LoaderContext";
import Sidebar from "./SidebarN";
import { TrialPopUpDisplay } from "./TrialPopUpDisplay";
import { ReconnectModal } from "./ReconnectModal";
import { stepsConfig } from "../constants/feedbackSurveyConstant";
import { NpsSurvey } from "everbee-nps-popups";
import amplitude from "amplitude-js";
import { onboardingCsatSteps } from "../constants/feedbackSurveyConstant";
import { CsatSurvey } from "everbee-nps-popups";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
}));
const getGreetingMessageBasedonTime = () => {
  let day = new Date();
  let hr = day.getHours();
  if (hr < 12) {
    return "Good Morning";
  } else if (hr <= 17) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

const message = getGreetingMessageBasedonTime();

export const DashboardLayout = ({ children, getCurrentUser }) => {
  const theme = useTheme();
  const { setIsOpen } = useTour();
  const router = useRouter();
  const { openLearn, setOpenLearn } = useLoader();
  const { tab, subtab, onboardingstep, step } = router.query;
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [backendOnboardingStep, setBackendOnboardingStep] = useState(false);
  const [backendStep, setBackendStep] = useState(1);
  const [openLearningModule, setOpenLearningModule] = useState(false);
  const publicRoutes = [
    "/login",
    "/register",
    "/forgotPassword",
    "/magicLink",
    "/authentication",
    "/addEtsyDetails",
    "/404",
    "/_error",
    "/changePassword",
    "/error",
    "/getting-started",
    "/redirectingLoader",
    "somethingWentWrong",
    "/useChromeExtensionNow",
    "/sentry_sample_error",
    "/inventory",
  ];
  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem("sidebarExpanded") == "false" ? false : true
  );
  const { extInstalled } = useExtension();
  const {
    salesChannelsState: { userAccountDetails },
  } = useSalesChannel();
  const {
    authDispatch,
    authState: {
      everbeeName,
      everbeeEmail,
      everbeeUserId,
      is_token_revoked,
      shop_name,
      any_survey_answered_or_skipped_today,
      surveys_pending,
    },
  } = useAuth();

  const [reconnectModal, setReconnectModal] = useState(
    is_token_revoked && is_token_revoked
  );

  useEffect(() => {
    getAccountDetails();
  }, [userAccountDetails, backendOnboardingStep, backendStep]);

  function getAccountDetails() {
    if (userAccountDetails?.email) {
      if (!userAccountDetails?.onboarding_step2_connected_one_sales_channel) {
        setBackendOnboardingStep(true);
        setBackendStep(2);
      } else if (
        !userAccountDetails?.onboarding_step3_setting_up_sales_channel
      ) {
        setBackendOnboardingStep(true);
        setBackendStep(3);
      }
    }
  }
  const surveyDetails =
    surveys_pending &&
    surveys_pending?.find((survey) => survey.survey_type === "NPS Survey");
  const surveyDetailsCsat =
    surveys_pending &&
    surveys_pending?.find((survey) => survey.survey_type === "Onboarding CSAT");

  const openExtension = () => {
    if (extInstalled) {
      const encodeEmail = encodeURIComponent(everbeeEmail);
      window.open(`https://www.etsy.com`, "_blank");
    } else {
      window.open("https://everbee.io/install/", "_blank");
    }
  };

  useEffect(() => {
    if (router.pathname == "/inventory") {
      router.push("/404");
    }
  }, [router.isReady]);

  const onClose = () => {
    setReconnectModal(false);
  };

  const closeSurvey = () => {
    console.log("close");
    authDispatch({ type: "FEEDBACK_DONE", payload: true });
  };

  return (
    <>
      <DashboardLayoutRoot>
        {router.pathname !== "/inventory" && (
          <>
            {publicRoutes.every((element) => element !== router.pathname) &&
              !matchMobile &&
              (matches ? (
                <Sidebar
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              ) : (
                <MiniSideBar
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              ))}
            {/* <OnboardingModal
              backendstep={backendStep}
              openExtension={openExtension}
              matches={matches}
              backendOnboardingStep={backendOnboardingStep}
              backendStep={backendStep}
            /> */}

            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                width: "100%",
                backgroundColor: router.pathname == "/ambassadors" && "white",
                marginLeft:
                  everbeeUserId &&
                  publicRoutes.every(
                    (element) => element !== router.pathname
                  ) &&
                  (isExpanded
                    ? { sm: "0px", md: "256px", lg: "256px" }
                    : { sm: "0", md: "72px", lg: "72px" }),
                // width: { sm: "100%", md: "calc( 100vw - 300px)" },
                // marginLeft: { md: "300px" },
                overflow: "auto",
                height:
                  router.pathname !== "/pricing" ||
                  (router.pathname !== "/pricing" &&
                    router.pathname !== "/account" &&
                    backendOnboardingStep == true &&
                    (backendStep == 2 ||
                      (backendStep == 3 &&
                        (router.pathname == "/dashboard" ||
                          router.pathname == "/inventory"))))
                    ? "100vh"
                    : "auto",
              }}
            >
              {publicRoutes.every((element) => element !== router.pathname) &&
                matchMobile && (
                  <AppBarComponent
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                  />
                )}
              {publicRoutes.every((element) => element !== router.pathname) &&
                router.pathname !== "/account" &&
                router.pathname !== "/everbeePrint" &&
                router.pathname !== "/evermail" &&
                router.pathname !== "/courses" &&
                router.pathname !== "/upsell-promo" && (
                  <Header
                    greetingMessage={message}
                    setOpenTermsModal={children?.props?.setOpenTermsModal || {}}
                  />
                )}
              {children}
            </Box>

            {publicRoutes.every((element) => element !== router.pathname) && (
              <>
                <LearningModulePane />
                <TrialPopUpDisplay getCurrentUser={getCurrentUser} />
                {is_token_revoked === true && (
                  <ReconnectModal
                    reconnectModal={reconnectModal}
                    shopName={shop_name}
                    handleClose={onClose}
                  />
                )}
                {router.pathname !== "/pricing" &&
                  router.pathname !== "/account" &&
                  reconnectModal === false &&
                  any_survey_answered_or_skipped_today === false &&
                  surveys_pending.some(
                    (survey) => survey.survey_type === "NPS Survey"
                  ) && (
                    <NpsSurvey
                      steps={stepsConfig}
                      surveyDetails={surveyDetails}
                      closeSurvey={closeSurvey}
                    />
                  )}
                {router.pathname !== "/pricing" &&
                  router.pathname !== "/account" &&
                  reconnectModal === false &&
                  any_survey_answered_or_skipped_today === false &&
                  surveys_pending.every(
                    (survey) => survey.survey_type !== "NPS Survey"
                  ) &&
                  surveys_pending.some(
                    (survey) => survey.survey_type === "Onboarding CSAT"
                  ) && (
                    <CsatSurvey
                      steps={onboardingCsatSteps}
                      surveyDetails={surveyDetailsCsat}
                      closeSurvey={closeSurvey}
                    />
                  )}
              </>
            )}
          </>
        )}
      </DashboardLayoutRoot>
    </>
  );
};
