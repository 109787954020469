import React, { useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { useExtension } from "../contexts/ExtensionContext";
import { useAuth } from "../contexts/AuthContext";
import { useSalesChannel } from "../contexts/SalesChannelContext";
import { ReconnectModal } from "./ReconnectModal";
import { useRouter } from "next/router";
import Email from "../assets/svg/ever-email.svg";
import Image from "next/image";
import amplitude from "amplitude-js";

export default function ChromeBanner({ message }) {
  const router = useRouter();
  const [reconnectModal, setReconnectModal] = useState(false);
  const { extInstalled } = useExtension();
  const {
    authState: {
      everbeeName,
      everbeeEmail,
      everbeeUserId,
      is_everbee_email_active,
    },
  } = useAuth();

  const {
    salesChannelsState: {
      salesChannelsDetails,
      sellerSalesChannelsDetails,
      userAccountDetails,
      selectedStores,
    },
    updateSelectedSalesChannels,
  } = useSalesChannel();

  const openExtension = () => {
    if (extInstalled) {
      window.open(`https://www.etsy.com`, "_blank");
    } else {
      window.open("https://everbee.io/install/", "_blank");
    }
  };

  const handleEmailClick = () => {
    amplitude.getInstance().logEvent("Email banner");
    window.open(`${process.env.NEXT_PUBLIC_EMAIL_FRONTEND_APP}`, "_blank");
  };

  const learnClick = (e) => {
    e.stopPropagation();
    window.open(
      "https://help.everbee.io/en/article/why-should-i-use-everbee-email-1lxcvg5/",
      "_blank"
    );
  };
  return (
    <>
      <ReconnectModal
        reconnectModal={reconnectModal}
        setReconnectModal={setReconnectModal}
        handleClose={() => {
          setReconnectModal(false);
        }}
      />
      <Box
        sx={{
          cursor: "pointer",
          backgroundColor: "#FECE46",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
          gap: "10px",
        }}
        onClick={
          extInstalled && !is_everbee_email_active
            ? handleEmailClick
            : extInstalled && is_everbee_email_active
            ? openExtension
            : openExtension
        }
      >
        {extInstalled && !is_everbee_email_active ? (
          <>
            {" "}
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Get More Sales And Reviews on Autopilot. Start Using{" "}
              <span style={{ fontWeight: "600" }}>EverBee Email</span> Now
              {/* <a style={{ textDecoration: 'underline' }} onClick={(e) => learnClick(e)}>Learn more</a> */}
            </Typography>
          </>
        ) : extInstalled && is_everbee_email_active ? (
          <>
            {" "}
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Use Your Chrome Extension Now
            </Typography>
            <Link
              target="_blank"
              underline="none"
              pr="10px"
              variant="p"
              color="#5154A2"
              fontSize="14px"
              fontWeight="500"
              sx={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <img width="20px" height="20px" src="/everbee-chrome-ext.png" />
            </Link>
          </>
        ) : (
          <>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Use Your Chrome Extension Now
            </Typography>
            <Link
              target="_blank"
              underline="none"
              pr="10px"
              variant="p"
              color="#5154A2"
              fontSize="14px"
              fontWeight="500"
              sx={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <img width="20px" height="20px" src="/everbee-chrome-ext.png" />
            </Link>
          </>
        )}
      </Box>
    </>
  );
}
