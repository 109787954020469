import { createContext, useContext, useReducer, useState } from "react";
import { tradeMarkMonitorReducer } from "../reducers/tradeMarkMonitorReducer";
import {
  getAllListingsScanned,
  getScanSingleListingTradeMarkMonitor,
  getScanAllListingsTradeMarkMonitor,
  snoozeTrademark,
  snoozeKeywordTrademark,
  unSnoozeKeywordTrademark,
  getSingleListingTradeMarkDetails,
  unSnoozeTrademark,
  getSingleKeywordTrademarkDetails,
  snoozeAllKeywordTrademark,
  addMonitoredKeywordApi,
  getKeywordListingDetails,
} from "../apis/tradeMarkMonitor";
import {
  generateTrademarkRowData,
  getTrademarkRowData,
  getTrademarkSectionData,
} from "../helper/getTrademarkData";
import { useLoader } from "./LoaderContext";
import { toast } from "react-toastify";
import amplitude from "amplitude-js";
import { getEnlargeTrademarkDataArray } from "../helper/getTrademarkData";
import { googleAnalyticsEvent } from "../apis/account";
import {
  getMonitoredKeywordData,
  generateMonitoredKeyword,
} from "../helper/getMonitoredKeyword";

const initialState = {
  tableRowData: [],
  flaggedListings: -1,
  scannedListings: -1,
  totalAlerts: -1,
  trademarkIndicatorCount: 0,
  totalPageCount: 0,
  tradeMarkTagRowData: [],
  tradeMarkListingData: {},
  isRightSidePaneOpen: false,
  enlargeRowDataTrademark: [],
  monitoredKeywordRowData: [],
  trademarkEnlargeTableData: {},
  monitoredKeywordsCheckboxArr: [],
  selectAllMonitoredKeywords: false,
  rowCountOfMonitoredKeywords: 0,
  showSnoozed: false,
  showHideNonTrademark: true,
  listingForKeyword: [],
  totalPageCountForListings: 0,
  keywordListings: 0,
  keywordListingIdentifier: false,
};
const TradeMarkMonitorContext = createContext(initialState);

const TradeMarkMonitorProvider = ({ children }) => {
  const { addToLoadingSet, removeFromLoadingSet } = useLoader();
  const [tradeMarkMonitorState, tradeMarkMonitorDispatch] = useReducer(
    tradeMarkMonitorReducer,
    initialState
  );
  const [loading, setLoading] = useState(false);
  const [listingLoading, setListingLoading] = useState(false);
  const [tradeMarkRightPaneLoader, setTradeMarkRightPaneLoader] =
    useState(false);
  const [updateActions, setUpdateActions] = useState({
    updateSnooze: false,
    actionListingId: "",
  });
  const getTradeMarkForAllListings = async (clearData, sortPattern) => {
    try {
      setLoading(true);
      addToLoadingSet("getTradeMarkForAllListings");
      if (sortPattern.current.initialLoading) {
      }
      const res = await getAllListingsScanned(sortPattern.current);
      if (res.data.success) {
        const rowDataArray = getTrademarkRowData(res.data.listings);
        if (clearData) {
          tradeMarkMonitorDispatch({
            type: "INITIAL_TABLE_DATA",
            payload: { ...res.data, listings: rowDataArray },
          });
        } else {
          tradeMarkMonitorDispatch({
            type: "INCREMENT_PAGE",
            payload: { listings: rowDataArray },
          });
        }
      }
      sortPattern.current = {
        sortBy: sortPattern.current.sortBy,
        sortDirection: sortPattern.current.sortDirection,
        page: sortPattern.current.page + 1,
        initialLoading: false,
        sort: false,
      };
    } catch (error) {
      console.log(error);
      toast.error(error.response);
    } finally {
      removeFromLoadingSet("getTradeMarkForAllListings");
      setLoading(false);
    }
  };

  const getMonitoredKeyword = async (clearData, sortPattern) => {
    try {
      setLoading(true);
      addToLoadingSet("getMonitoredKeyword");
      const { rowDataArrays, totalCount } = await getMonitoredKeywordData(
        sortPattern.current
      );
      if (clearData) {
        tradeMarkMonitorDispatch({
          type: "INITIAL_MONITORED_KEYWORD_TABLE",
          payload: { rowDataArrays, totalCount },
        });
      } else {
        tradeMarkMonitorDispatch({
          type: "INCREMENT_MONITORED_KEYWORD_PAGE",
          payload: { rowDataArrays, totalCount },
        });
      }
      sortPattern.current = {
        sortBy: sortPattern.current.sortBy,
        sortDirection: sortPattern.current.sortDirection,
        page: sortPattern.current.page + 1,
        initialLoading: false,
        sort: false,
        showAll: sortPattern.current.showAll || false,
        hideNonTrademarkKeywords:
          sortPattern.current.hideNonTrademarkKeywords || false,
      };
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message);
    } finally {
      removeFromLoadingSet("getMonitoredKeyword");
      setLoading(false);
    }
  };

  const getScanAllListings = async (apiRef) => {
    try {
      addToLoadingSet("getScanAllListings");
      const { data } = await getScanAllListingsTradeMarkMonitor();
      if (data.success) {
        const rowIds = apiRef.current.getAllRowIds();
        apiRef.current.updateRows(
          rowIds.map((rowId) => {
            const listing = tradeMarkMonitorState.tableRowData.find(
              (item) => item?.id === rowId
            );
            return {
              id: rowId,
              actions: {
                ...listing.actions,
                tradeMarkScanStatus: 1,
              },
            };
          })
        );
        amplitude.getInstance().logEvent("Trademark Monitor - Scan", {
          count_of_monitored_listing: tradeMarkMonitorState.flaggedListings,
        });
        googleAnalyticsEvent({
          eventName: "TrademarkMonitorScan",
          eventPrams: {
            count_of_monitored_listing: tradeMarkMonitorState.flaggedListings,
          },
        });
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      removeFromLoadingSet("getScanAllListings");
    }
  };
  const getScanSingleListing = async (listingId) => {
    try {
      addToLoadingSet("getScanSingleListing");
      const { data } = await getScanSingleListingTradeMarkMonitor(listingId);
      if (data.success) {
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      removeFromLoadingSet("getScanSingleListing");
    }
  };

  const snoozeTrademarkListing = async (payload, handleClose) => {
    try {
      addToLoadingSet("snoozeTrademarkListing");
      const { data } = await snoozeTrademark(payload);
      if (data.success) {
        if (tradeMarkMonitorState.isRightSidePaneOpen) {
          await getSingleTrademarkData(
            payload.listingId,
            setTradeMarkRightPaneLoader
          );
        }

        const rowDataArray = generateTrademarkRowData(data.listing);
        const position = tradeMarkMonitorState?.tableRowData?.findIndex(
          (row) => row?.listingId === rowDataArray?.listingId
        );
        const previousListing = tradeMarkMonitorState?.tableRowData[position];
        var temp = [...tradeMarkMonitorState?.tableRowData];
        temp.splice(position, 1, rowDataArray);
        if (data?.listing?.snoozed === 1) {
          tradeMarkMonitorDispatch({
            type: "UPDATE_TABLE_WITH_SNOOZE_API",
            payload: {
              rowDataArray: temp,
              totalAlerts: rowDataArray?.trademarkAlerts,
              alerts: previousListing?.trademarkAlerts,
              unflagged: previousListing?.trademarkAlerts > 0 ? 1 : 0,
            },
          });
        } else {
          tradeMarkMonitorDispatch({
            type: "UPDATE_TABLE_WITH_SNOOZE_API",
            payload: {
              rowDataArray: temp,
              totalAlerts: rowDataArray?.trademarkAlerts,
              alerts: 1,
              unflagged: 0,
            },
          });
        }
        toast.success("Snoozed successfully");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      handleClose();
      removeFromLoadingSet("snoozeTrademarkListing");
    }
  };

  const snoozeKeywordTrademarkListing = async (payload, handleClose) => {
    try {
      addToLoadingSet("snoozeTrademarkListing");

      const { data } = await snoozeKeywordTrademark(payload);
      if (data.success) {
        if (payload.from == "all trademark") {
          tradeMarkMonitorDispatch({
            type: "UPDATE_MONITORED_KEYWORD_ROW_DATA_FOR_SNOOZE",
            payload: {
              keywordId: payload.keyword_ids,
            },
          });
        } else {
          tradeMarkMonitorDispatch({
            type: "UPDATE_TRADEMARK_TAG_ROW_DATA",
            payload: {
              keywordIds: payload.keyword_ids,
            },
          });
        }

        toast.success("Snoozed successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      handleClose();
      removeFromLoadingSet("snoozeTrademarkListing");
    }
  };

  const snoozeAllKeywordTrademarkListing = async (handleClose) => {
    try {
      addToLoadingSet("snoozeTrademarkListing");

      const { data } = await snoozeAllKeywordTrademark();
      if (data.success) {
        tradeMarkMonitorDispatch({
          type: "SNOOZE_ALL_KEYWORD",
          payload: {},
        });
        toast.success("Snoozed successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      handleClose();
      removeFromLoadingSet("snoozeTrademarkListing");
    }
  };

  const unSnoozeTrademarkListing = async (payload) => {
    addToLoadingSet("unSnoozeTrademarkListing");
    try {
      const { data } = await unSnoozeTrademark(payload);
      const formattedData = generateTrademarkRowData(data.listing);
      const position = tradeMarkMonitorState?.tableRowData?.findIndex(
        (row) => row?.listingId === formattedData?.listingId
      );
      var previousListing = tradeMarkMonitorState?.tableRowData[position];
      var temp = [...tradeMarkMonitorState?.tableRowData];
      temp.splice(position, 1, formattedData);
      tradeMarkMonitorDispatch({
        type: "UPDATE_TABLE_WITH_UNSNOOZE_API",
        payload: {
          rowDataArray: temp,
          alerts: !payload.trademarkId ? formattedData?.trademarkAlerts : 1,
          flagged:
            (!payload.trademarkId || data?.listing?.snoozed === 0) &&
              previousListing?.trademarkAlerts === 0
              ? 1
              : 0,
        },
      });

      if (tradeMarkMonitorState.isRightSidePaneOpen) {
        await getSingleTrademarkData(
          payload.listingId,
          setTradeMarkRightPaneLoader
        );
      }
      toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      removeFromLoadingSet("unSnoozeTrademarkListing");
    }
  };

  const unsnoozeKeywordTrademarkListing = async (payload) => {
    try {
      addToLoadingSet("unSnoozeTrademarkListing");
      const { data } = await unSnoozeKeywordTrademark(payload);
      if (data.success) {
        if (payload.from == "all trademark") {
          tradeMarkMonitorDispatch({
            type: "UPDATE_MONITORED_KEYWORD_ROW_DATA_FOR_UNSNOOZE",
            payload: {
              keywordId: payload.keyword_ids,
            },
          });
        } else {
          tradeMarkMonitorDispatch({
            type: "UPDATE_TRADEMARK_TAG_ROW_DATA_FOR_UNSNOOZE",
            payload: {
              keywordIds: payload.keyword_ids,
            },
          });
        }
        toast.success("Unsnoozed successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      removeFromLoadingSet("unSnoozeTrademarkListing");
    }
  };

  /**rightside pane */
  const getSingleTrademarkData = async (currentIaId, setIsLoading) => {
    setIsLoading(true);
    try {
      const { data } = await getSingleListingTradeMarkDetails(currentIaId);
      if (data.success) {
        const formatedRowData = getTrademarkSectionData(data?.trademarks);
        tradeMarkMonitorDispatch({
          type: "RIGHT_SIDE_PANE_API",
          payload: { ...data, trademarks: formatedRowData },
        });
      }
      amplitude.getInstance().logEvent("Trademark Monitor - details", {
        count_of_alert: data?.listing.keyword_trademarks_count,
      });
      googleAnalyticsEvent({
        eventName: "TrademarkMonitorDetails",
        eventPrams: {
          count_of_alert: data?.listing.keyword_trademarks_count,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setTradeMarkRightPaneLoader(false);
      setIsLoading(false);
    }
  };

  const addMonitoredKeyword = async (keywords, close, setIsLoading) => {
    setIsLoading(true);
    try {
      const res = await addMonitoredKeywordApi(keywords);
      const apiResult = res.data.keywords;
      var rowDataArray = [];
      for (var i = 0; i < apiResult?.length; i++) {
        rowDataArray.push(generateMonitoredKeyword(apiResult[i]));
      }
      tradeMarkMonitorDispatch({
        type: "ADD_MONITORED__IN_TABLE",
        payload: rowDataArray,
      });
      close();
      toast.success("Keywords added successfully");
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const getEnlargeTrademarkData = async (row, payload, setTableLoading) => {
    try {
      setTableLoading(true);
      const res = await getSingleKeywordTrademarkDetails(payload);
      const formatedRowData = getEnlargeTrademarkDataArray(res.data.trademarks);
      if (res.data.success) {
        tradeMarkMonitorDispatch({
          type: "UPDATE_ENALRGE_TABLE",
          payload: { rowDataArray: formatedRowData, singleRowData: row },
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTableLoading(false);
    }
  };

  const getKeywordListings = async (clearData, payload, id) => {
    try {
      setListingLoading(true)
      tradeMarkMonitorDispatch({
        type: 'KEYWORD_IDENTIFIER_LISTINGS',
        payload: true
      })
      const res = await getKeywordListingDetails(payload, id);
      const rowDataArray = getTrademarkRowData(res.data.listings);
      if (clearData) {
        tradeMarkMonitorDispatch({
          type: "KEYWORD_LISTING_DETAILS",
          payload: { listings: rowDataArray, totalPages: res.data.total_pages, totalListingsCount: res.data.total_count }
        })
      }
      else {
        tradeMarkMonitorDispatch({
          type: "KEYWORD_LISTING_DETAILS_INCREMENT_PAGE",
          payload: { listings: rowDataArray }
        })
      }
      payload.current = {
        sortBy: payload.current.sortBy,
        sortDirection: payload.current.sortDirection,
        page: payload.current.page + 1,
        initialLoading: false,
        sort: false,
      };
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setListingLoading(false);
    }
  }

  return (
    <TradeMarkMonitorContext.Provider
      value={{
        tradeMarkMonitorState,
        tradeMarkMonitorDispatch,
        getTradeMarkForAllListings,
        getScanSingleListing,
        snoozeTrademarkListing,
        loading,
        listingLoading,
        getMonitoredKeyword,
        updateActions,
        setUpdateActions,
        getScanAllListings,
        unSnoozeTrademarkListing,
        unsnoozeKeywordTrademarkListing,
        getSingleTrademarkData,
        tradeMarkRightPaneLoader,
        getEnlargeTrademarkData,
        snoozeKeywordTrademarkListing,
        snoozeAllKeywordTrademarkListing,
        addMonitoredKeyword,
        getKeywordListings,
      }}
    >
      {children}
    </TradeMarkMonitorContext.Provider>
  );
};

const useTradeMarkMonitor = () => useContext(TradeMarkMonitorContext);

export { useTradeMarkMonitor, TradeMarkMonitorProvider };
