export const salesChannelsReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_CHANNELS_DETAILS":
      return {
        ...state,
        salesChannelsDetails: payload.salesChannels,
        sellerSalesChannelsDetails: payload.sellerSalesChannels,
      };
    case "SET_USER_ACCOUNT_DETAILS":
      return {
        ...state,
        userAccountDetails: payload,
      };
    case "SET_TIME_RANGE_SELECTOR":
      return {
        ...state,
        timeRangeSelector: payload,
      };
    case "SHOP_SELECTOR":
      return {
        ...state,
        selectedShops: payload,
      };
    case "DASHBOARD_DATA":
      return {
        ...state,
        dashboardData: payload,
      };
    case "UPDATE_USAGE_DETAILS":
      return {
        ...state,
        userAccountDetails: {
          ...state.userAccountDetails,
          usage_details: { product_analytics_feature: payload || 0 },
        },
      };
    case "SET_SELECTED_STORE":
      return {
        ...state,
        selectedStores: payload,
      };
    default:
      break;
  }
};
