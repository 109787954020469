export const getDesingnPattern = (mode) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1500,
      xl: 1920,
    },
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#62adeb",
          },
          secondary: {
            main: "#1764BC",
            goldColor: "#FFC107",
            white: "#fff",
          },
          background: {
            default: "#ededed",
            secondary: "#fff",
          },
          appBar: {
            main: "#63afef",
          },
          fontColor: {
            secondary: " #707070",
            lightGray: "#000",
            outlinedBtnFontColor: "#00326D",
            fontColorPrimary: "#000",
          },
          cardColor: {
            main: "#fff",
          },
          checkBoxColor: {
            main: "#002753",
          },
          coloredValue: {
            red: "#B10000",
            green: "#0EB000",
            yellow: "#B1B100",
          },
          border: {
            main: "#1E6CC4",
            secondary: "#7f7f7f",
          },
          redColor: {
            main: "#BA0000",
          },
          bannerColor: {
            main: "#002753",
            secondary: "#fff",
          },
          modalBackgroundColor: {
            main: "#00000095",
          },
        }
      : {
          primary: {
            main: "#1764BC",
          },
          secondary: {
            main: "#2178DA",
            goldColor: "#FFC721",
            black: "#000",
          },
          background: {
            default: "#161c24",
            secondary: "#212B36",
          },
          appBar: {
            main: "#1464bc",
          },
          cardColor: {
            main: "#333D48",
          },
          fontColor: {
            lightGray: "#8C98A5",
            lightAsh: "#75818E",
            outlinedBtnFontColor: "#fff",
            fontColorPrimary: "#fff",
          },
          checkBoxColor: {
            main: "#2178DA",
          },
          coloredValue: {
            red: "#CB0000",
            green: "#11CA00",
            yellow: "#CBCB00",
          },
          border: {
            main: "#2178DA",
            secondary: "#7f7f7f",
          },
          redColor: {
            main: "#CA0000",
          },
          bannerColor: {
            main: "#fff",
            secondary: "#002753",
          },
          modalBackgroundColor: {
            main: "#333D4895",
          },
        }),
    greenColor: {
      main: "#0EB000",
      light: " #11CA00",
    },
    grayShades: {
      main: "#919EAB",
      secondary: "#cacaca",
    },
    whiteColor: {
      main: "#fff",
    },
    favColors: {
      main: "#f1651e",
      secondary: "#1C3872",
    },
  },

  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: "5px",
            border: mode === "light" ? `1px solid #0155B8` : `1px solid #fff`,
            "&:hover": {
              border: mode === "light" ? `1px solid #0155B8` : `1px solid #fff`,
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor:
              mode === "light" ? "rgba(217, 217, 217, 1)" : "#161c24",
            width: "9px",
            height: "9px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "rgba(20, 88, 165, 1)",
            minHeight: "5px",
            "&:hover": {
              backgroundColor: "rgba(20, 88, 165, 0.60)",
            },
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor:
              mode === "light" ? "rgba(217, 217, 217, 1)" : "#161c24",
          },
        },
      },
    },
  },
});
