export const GA_TRACKING_ID = "G-V6SXEHYL1Q";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (typeof window.gtag !== "undefined")
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (typeof window.gtag !== "undefined")
    window.gtag(
      "event",
      "video_auto_play_start",
      "general",
      "event_test_label"
    );
};
