export const cardsInfo = [
  {
    title: "Avg. Total Sales",
    value: 32,
    img: "trend.png",
    tooltipText:
      "Across all your active listings, this is your Average Total Sales per listing.",
  },
  {
    title: "Avg. Price",
    value: "$3,200",
    img: "cardIcon1.png",
    tooltipText:
      "Across all your active listings, this is your Average Price per listing.",
  },
  {
    title: "Avg. Views",
    value: 1000,
    img: "view-light.png",
    tooltipText:
      "Across all your active listings, this is your Average Views per listing.",
  },
  {
    title: "Avg. Favorites",
    value: 47,
    img: "recommend-light.png",
    tooltipText:
      "Across all your active listings, this is your Average Favorites per listing.",
  },
  {
    title: "Avg. Reviews",
    value: 32,
    img: "reviews-light.png",
    tooltipText:
      "Across all your active listings, this is your Average Reviews per listing.",
  },
  {
    title: "Avg. Conv Rate",
    value: "Top 2.2%",
    img: "avg-con-light.png",
    tooltipText:
      "Across all your active listings, this is your Average Conversion Rate for each listing.",
  },
  // {
  //   title: "Repeat Cust. Rate",
  //   value: "Top 10%",
  //   img: "aov.png",
  //   tooltipText: "hello",
  // },
  // {
  //   title: "Review Rate",
  //   value: "Top 17.2%",
  //   img: "cardIcon1.png",
  //   tooltipText: "hello",
  // },
];
