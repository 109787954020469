import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const PlanViewSideBar = ({ currentUserPlan }) => {
  return (
    <>
      {currentUserPlan && (
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            width: currentUserPlan == "BOGO" ? "70px" : "100%",
            marginBottom: currentUserPlan == "BOGO" ? "-5px" : "1.15rem",
            marginLeft: currentUserPlan == "BOGO" ? "10px" : "0px",
            background: currentUserPlan?.includes("BOGO")
              ? "linear-gradient(to left, #ff9900, #ff6600)"
              : currentUserPlan?.includes("Growth") ||
                currentUserPlan?.includes("Business")
              ? "linear-gradient(to bottom, #FFD251 1.78%, #DAA50F 100%)"
              : currentUserPlan?.includes("Pro")
              ? "linear-gradient(to bottom, #C0C0C0 0%, #737373 100%)"
              : "linear-gradient(to bottom, #b08d57 0%, #b08d57 100%)",
            fontSize: currentUserPlan == "BOGO" ? "12px" : "14px",
            fontWeight: currentUserPlan == "BOGO" ? 500 : 600,
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "10px",
            padding: "6px",
            justifyContent: "space-around",
          }}
        >
          {!currentUserPlan ? (
            <Box
              component="img"
              src={"./loadingT.gif"}
              sx={{
                width: "40px",
                justifyContent: "space-around",
              }}
              aly="loader"
            />
          ) : (
            currentUserPlan?.toUpperCase()
          )}
        </Box>
      )}
    </>
  );
};
