import { GifComponent } from "../components/gifs/GifComponent";
import amplitude from "amplitude-js";
import { saveNpsCsatData } from "../apis/account";

const logAmplitudeEvent = (eventName, eventData) => {
    amplitude.getInstance().logEvent(eventName, eventData);
};
export const stepsConfig = [
    {
        surveyType: "NPS Survey",
        type: "rating",
        content: "How likely are you to recommend us?",
        ratings: [
            { value: 0 },
            { value: 1 },
            { value: 2 },
            { value: 3 },
            { value: 4 },
            { value: 5 },
            { value: 6 },
            { value: 7 },
            { value: 8 },
            { value: 9 },
            { value: 10 },
        ],
        leftTag: "Not likely at all",
        rightTag: "Extremely likely",
        leftPosition: 55,
        ratingComponent: <GifComponent src="/cody.svg" />,
        amplitudeFunction: logAmplitudeEvent,
        backendFunction: saveNpsCsatData,
    },
    {
        type: "feedback",
        minRange: 0,
        maxRange: 6,
        numberOfPopups: 2,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "What is the primary reason for you giving this score?",
                gif: <GifComponent src="/frowning.gif" />
            },
            {
                popupType: "questionFeedback",
                description: "What would it take to improve your score to a 9 or 10?",
                gif: <GifComponent src="/smiling-eye.gif" />
            },
        ],
    },
    {
        type: "feedback",
        minRange: 7,
        maxRange: 8,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                title: "Positive Feedback Popup",
                description: "What would it take to improve your score to a 9 or 10?",
                gif: <GifComponent src="/smiling-eye.gif" />
            },
        ],
    },
    {
        type: "feedback",
        content: "Wow! We're thrilled to hear that you had an excellent experience. Let us know what stood out.",
        minRange: 9,
        maxRange: 10,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "highestRating",
                description1: "You just made our day!",
                description2: "Do you mind reviewing EverBee on the Chrome store?",
                gif: <GifComponent src="/smiling-heart.gif" />,
                feedbackLink: "https://chromewebstore.google.com/detail/everbee/oeicpkgdngoghobnbjngekclpcmpgpij/reviews"
            },
        ],
    },
];

export const productAnalyticsSteps = [
    {
        surveyType: "CSAT Survey - Product Analytics",
        type: "rating",
        content: "How was your experience using Product Analytics?",
        gifs: [
            <GifComponent src="/pouting.gif" />,
            <GifComponent src="/frowning.gif" />,
            <GifComponent src="/expressionless.gif" />,
            <GifComponent src="/smiling-eye.gif" />,
            <GifComponent src="/beaming.gif" />,
        ],
        leftPosition: 55,
        leftTag: "Very unsatisfied",
        rightTag: "Very satisfied",
        amplitudeFunction: logAmplitudeEvent,
        backendFunction: saveNpsCsatData,
    },
    {
        type: "feedback",
        minRange: 1,
        maxRange: 2,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "Sorry to hear that 😢 What could be better?",
                gif: <GifComponent src="/frowning.gif" />,
            },
        ],
    },
    {
        type: "feedback",
        minRange: 3,
        maxRange: 4,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "What could be better?",
                gif: <GifComponent src="/smiling-eye.gif" />,
            },
        ],
    },
    {
        type: "feedback",
        minRange: 5,
        maxRange: 5,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "highestRating",
                description1: "You just made our day!",
                description2: "Do you mind reviewing EverBee on the Chrome store?",
                gif: <GifComponent src="/smiling-heart.gif" />,
                feedbackLink: "https://chromewebstore.google.com/detail/everbee/oeicpkgdngoghobnbjngekclpcmpgpij/reviews"
            },
        ],
    },
];

export const keywordResearchSteps = [
    {
        surveyType: "CSAT Survey - Keyword Research",
        type: "rating",
        content: "How was your experience using Keyword Research?",
        gifs: [
            <GifComponent src="/pouting.gif" />,
            <GifComponent src="/frowning.gif" />,
            <GifComponent src="/expressionless.gif" />,
            <GifComponent src="/smiling-eye.gif" />,
            <GifComponent src="/beaming.gif" />,
        ],
        leftPosition: 55,
        leftTag: "Very unsatisfied",
        rightTag: "Very satisfied",
        amplitudeFunction: logAmplitudeEvent,
        backendFunction: saveNpsCsatData,
    },
    {
        type: "feedback",
        minRange: 1,
        maxRange: 2,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "Sorry to hear that 😢 What could be better?",
                gif: <GifComponent src="/frowning.gif" />,
            },
        ],
    },
    {
        type: "feedback",
        minRange: 3,
        maxRange: 4,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "What could be better?",
                gif: <GifComponent src="/smiling-eye.gif" />,
            },
        ],
    },
    {
        type: "feedback",
        minRange: 5,
        maxRange: 5,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "highestRating",
                description1: "You just made our day!",
                description2: "Do you mind reviewing EverBee on the Chrome store?",
                gif: <GifComponent src="/smiling-heart.gif" />,
                feedbackLink: "https://chromewebstore.google.com/detail/everbee/oeicpkgdngoghobnbjngekclpcmpgpij/reviews"
            },
        ],
    },
];

export const onboardingCsatSteps = [
    {
        surveyType: "CSAT Survey - Onboarding",
        type: "rating",
        content: "How was your experience so far?",
        gifs: [
            <GifComponent src="/pouting.gif" />,
            <GifComponent src="/frowning.gif" />,
            <GifComponent src="/expressionless.gif" />,
            <GifComponent src="/smiling-eye.gif" />,
            <GifComponent src="/beaming.gif" />,
        ],
        leftPosition: 55,
        leftTag: "Very unsatisfied",
        rightTag: "Very satisfied",
        amplitudeFunction: logAmplitudeEvent,
        backendFunction: saveNpsCsatData,
    },
    {
        type: "feedback",
        minRange: 1,
        maxRange: 2,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "Sorry to hear that 😢 What could be better?",
                gif: <GifComponent src="/frowning.gif" />,
            },
        ],
    },
    {
        type: "feedback",
        minRange: 3,
        maxRange: 4,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "questionFeedback",
                description: "What could be better?",
                gif: <GifComponent src="/smiling-eye.gif" />,
            },
        ],
    },
    {
        type: "feedback",
        minRange: 5,
        maxRange: 5,
        numberOfPopups: 1,
        thankYouGif: <GifComponent src="/partying-face.gif" />,
        popups: [
            {
                popupType: "highestRating",
                description1: "You just made our day!",
                description2: "Do you mind reviewing EverBee on the Chrome store?",
                gif: <GifComponent src="/smiling-heart.gif" />,
                feedbackLink: "https://chromewebstore.google.com/detail/everbee/oeicpkgdngoghobnbjngekclpcmpgpij/reviews"
            },
        ],
    },
];