import Image from "next/image";
import styled from "styled-components";
// import { useSelector, useDispatch } from "react-redux";
// import { toggleSidebar } from "../../store/slices/sidebarSlice";

import switchRight from "./../../assets/svg/chevron-right.svg";

const StyledWrapper = styled.button`
  all: unset;
  height: 1.6rem;
  width: 1.6rem;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  cursor: pointer;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 24px;
  right: -12px;

  .reverse-img {
    transform: rotate(180deg);
  }
`;

const SidebarSwitch = ({ isExpanded, setIsExpanded }) => {
  return (
    <StyledWrapper
      onClick={() => {
        setIsExpanded(!isExpanded);
        localStorage.setItem("sidebarExpanded", !isExpanded);
      }}
    >
      {isExpanded ? (
        <Image
          className="reverse-img"
          src={switchRight}
          alt="Sidebar expand-collapse toggle button"
        />
      ) : (
        <Image src={switchRight} alt="Sidebar expand-collapse toggle button" />
      )}
    </StyledWrapper>
  );
};

export default SidebarSwitch;
