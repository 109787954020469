import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { Router } from "next/router";
import Link from "next/link";
import { useSalesChannel } from "../../contexts/SalesChannelContext";

const useStyles = makeStyles(
  (theme) => ({
    menuItems: {
      "&.MuiButtonBase-root": {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "400",
      },
    },
  }),
  {
    name: "CalendarDropdownStyles",
  }
);
const CalendarDropdown = ({ open, setOpen, anchorRef }) => {
  const { salesChannelsDispatch } = useSalesChannel();
  const classes = useStyles();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleDateRange = (item) => {
    salesChannelsDispatch({
      type: "SET_TIME_RANGE_SELECTOR",
      payload: item,
    });
    setOpen(false);
  };
  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        style={{ zIndex: 3 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "top-start" ? "left top" : "left top",
            }}
          >
            <Paper
              sx={{
                width: { xs: "190px", sm: "200px" },
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {[
                    "Today",
                    "Yesterday",
                    "Last 7 Days",
                    "Last 30 Days",
                    "This Month",
                    "This Year",
                    "Last Year",
                  ].map((item) => (
                    <MenuItem
                      className={classes.menuItems}
                      onClick={() => handleDateRange(item)}
                    >
                      {item}
                    </MenuItem>
                  ))}
                  {["All Time"].map((item) => (
                    <MenuItem
                      disabled
                      className={classes.menuItems}
                      onClick={() => handleDateRange(item)}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default CalendarDropdown;
