export function getTrademarkRowData(data) {
  var rowDataArray = [];
  for (var i = 0; i < data.length; i++) {
    rowDataArray.push(generateTrademarkRowData(data[i]));
  }
  return rowDataArray;
}

export function generateTrademarkRowData(data) {
  const rn = Math.floor(Math.random() * 999787898894);
  const rowDataTable = {
    id: rn,
    actions: {
      listingId: data.listing_id,
      snoozeStatus: data.snoozed,
      tradeMarkScanStatus: data.trademark_rescan,
    },
    listingId: data.listing_id,
    photo: data.Images,
    productName: data.title,
    addedOnEtsy: data.created_at
      ? new Date(data.created_at).toISOString().split("T")[0]
      : "----",
    dateLastScanned: data.trademark_scanned
      ? new Date(data.trademark_scanned).toISOString().split("T")[0]
      : "----",
    trademarkAlerts: data.keyword_trademarks_count,
  };
  return rowDataTable;
}

//rightside pane table
export function getTrademarkSectionData(data) {
  var rowDataArray = [];
  for (var i = 0; i < data?.length; i++) {
    rowDataArray.push(generateTrademarkSectionData(data[i]));
  }
  return rowDataArray;
}

function generateTrademarkSectionData(data) {
  const rn = Math.floor(Math.random() * 999787898894);
  const rowDataTable = {
    id: rn,
    snoozeStatus: data.snoozed,
    trademarkId: data.trademark_id,
    trademark: data.keyword,
    trademarkCount: data.trademark_count,
    serial: data.serial_number,
    register: data.registration_number,
    type: data.type,
    keywordId: data.id
  };
  return rowDataTable;
}

export function getEnlargeTrademarkDataArray(data) {
  var rowDataArray = [];
  for (var i = 0; i < data?.length; i++) {
    rowDataArray.push(generateEnlargetrademarkData(data[i]));
  }
  return rowDataArray;
}

function generateEnlargetrademarkData(data){
  const rn = Math.floor(Math.random() * 999787898894);
  const rowDataTable = {
    id: rn,
    trademark: data.keyword,
    serial: data.serial_number,
    register: data.registration_number,
    trademarkId: data.id,
  };
  return rowDataTable
}
