import React from "react";
import { Fade, Modal, Box, Button } from "@mui/material";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Typography, ClickAwayListener } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

const SystemDownModal = ({ open, setOpenSystemDownModal }) => {
  const {
    authState: { everbeeEmail },
  } = useAuth();
  const handleClose = () => {
    setOpenSystemDownModal(false);
  };

  return (
    <>
      {open && everbeeEmail && (
        <SystemDownBackDrop>
          <ClickAwayListener onClickAway={handleClose}>
            <Box
              sx={{
                backgroundColor: "background.paper",
                height: { sm: "100%", xs: "100%", md: "100%" },
                border: "none",
                borderRadius: { md: "0px", sm: "10px", xs: "10px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <ClearIcon
                  sx={{
                    cursor: "pointer",
                    height: { sm: "30px", xs: "20px", md: "30px" },
                    width: { sm: "30px", xs: "20px", md: "30px" },
                  }}
                  onClick={handleClose}
                />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  background: "white",
                  paddingTop: { sm: "4%", xs: "2%", md: "9%" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: { sm: "23px", xs: "15px", md: "23px" },
                    color: "#002753",
                  }}
                >
                  Uh oh!
                </Typography>
                <Box
                  sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "#808080",
                    padding: "4px 50px",
                    marginTop: "3%",
                    padding: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { sm: "18px", xs: "11px", md: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    Something didn't go as planned.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { sm: "18px", xs: "11px", md: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    If reloading doesn’t work, we have live customer support
                    ready to assist you:{" "}
                    <a
                      style={{ color: "#63AFEF", cursor: "pointer" }}
                      target="_blank"
                      href="https://help.everbee.io/en/"
                    >
                      help.everbee.io
                    </a>
                  </Typography>
                </Box>
                <Box
                  component="img"
                  sx={{
                    width: { xs: "350px", sm: "380px", md: "380px" },
                    textAlign: "center",
                  }}
                  src="/Systemdown.svg"
                />
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "60px",
                      height: "29px",
                      borderRadius: "4px",
                      padding: "5px 16px",
                      backgroundColor: "#1D6BC3",
                      color: "white",
                      marginTop: "3%",
                      cursor: "pointer",
                    }}
                    onClick={() => window.location.reload()}
                  >
                    Reload
                  </Button>
                </Box>
              </Box>
            </Box>
          </ClickAwayListener>
        </SystemDownBackDrop>
      )}
    </>
  );
};

const SystemDownBackDrop = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#00000095",
        height: {
          sm: "calc(100vh - 40px)",
          xs: "calc(100vh - 140px)",
          md: "100vh",
        },
        zIndex: "1000000",
        position: "absolute",
        right: { sm: "20px", xs: "20px", md: "0px" },
        top: { sm: "40px", xs: "100px", md: "0px" },
        width: {
          xs: "calc(100vw - 40px)",
          sm: "calc(100vw - 130px)",
          md: "calc(100vw - 300px)",
        },
        borderRadius: { xs: "10px", sm: "10px", md: "10px" },
        marginLeft: { xs: "20", sm: "90px", md: "300px" },
      }}
    >
      {children}
    </Box>
  );
};
export default SystemDownModal;
