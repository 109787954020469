import {
  Avatar,
  Badge,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "../../contexts/ColorModeContext";
import {
  ExpandMore,
  ExpandLess,
  CalendarToday,
  Refresh,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Cards from "./Cards";
import { toast } from "react-toastify";
import { cardData } from "./cardData";
import HeaderDropdown from "./HeaderDropdown";
import { ClickAwayListener } from "@material-ui/core";
import { useRouter } from "next/router";
import CalendarDropdown from "./CalendarDropdown";
import { getNumberWithCommas } from "../../helper/getNumberWithCommas";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import ShopSelector from "./ShopSelector";
import {
  cardDataApi,
  reloadDashBoard,
  setDefaultCurrency,
} from "../../apis/dashboard";
import { useAuth } from "../../contexts/AuthContext";
import { currencies } from "./utils/currencies";
import { useLoader } from "../../contexts/LoaderContext";
import dateRangeConstant from "../../helper/dateRangeConstant";
import ChromeBanner from "../ChromeBanner";
import { cardsInfo } from "../myListingsComponents/cardsInfo";

const useStyles = makeStyles(
  (theme) => ({
    headerContainer: {
      width: "100%",
      position: "relative",
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: "0px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "264px",
        marginTop: "0px",
      },
    },
    layerOneImg: {
      position: "relative",
      top: 0,
      left: 0,
      height: "311px",
      [theme.breakpoints.between("sm", "md")]: {
        height: "264px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    layerTwoImg: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "300px",
      [theme.breakpoints.between("sm", "md")]: {
        height: "255px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    MobLayerOneImg: {
      position: "relative",
      top: 0,
      left: 0,
      height: "252px",
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    MobLayerTwoImg: {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "264px",
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    btnContainer: {
      display: "flex",
      gap: "10px",
    },
    customizeBtn: {
      "&.MuiBadge-root ": {
        [theme.breakpoints.between("sm", "md")]: {
          display: "none",
        },
      },
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      zIndex: "3",
      top: "55px",
      left: "30px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        left: "26px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        top: "30px",
        left: "100px",
        width: "40%",
      },
    },
    headingText: {
      "&.MuiTypography-root": {
        marginBottom: "10px",
        fontWeight: "500",
        fontSize: "30px ",
        [theme.breakpoints.down("sm")]: {
          fontSize: "24px ",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "26px",
        },
      },
    },
    rightIconsWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "14px",
      position: "absolute",
      top: "53px",
      right: "30px",
      zIndex: "3",
      [theme.breakpoints.down("sm")]: {
        top: "18px",
        right: "15px",
        display: "none",
      },
      [theme.breakpoints.between("sm", "md")]: {
        top: "43px",
        right: "64px",
      },
    },

    iconsWrapper: {
      display: "flex",
      gap: "14px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    cardsWrapper: {
      position: "absolute",
      top: "155px",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: "0px 18px",
      justifyContent: "space-around",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
        top: "0",
        flexDirection: "row",
        overflowX: "scroll",
        justifyContent: "flex-start",
        margin: "15px",
        marginBottom: "0px",
        width: "calc(100vw - 30px)",
      },
      [theme.breakpoints.between("sm", "md")]: {
        top: "150px",
        left: "90px",
        width: "calc(100vw - 95px)",
      },
    },
    menuPaper: {
      top: "187px",
      maxHeight: "50vh",
    },
  }),
  {
    name: "MuiCustomStyle",
  }
);

const Header = ({ greetingMessage, setOpenTermsModal }) => {
  const {
    salesChannelsState: {
      timeRangeSelector,
      userAccountDetails,
      selectedShops,
      salesChannelsDetails,
      sellerSalesChannelsDetails,
    },
  } = useSalesChannel();
  const { loadingSet, addToLoadingSet, removeFromLoadingSet } = useLoader();

  const { mode } = useTheme();
  const router = useRouter();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const calAnchorRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [cardResult, setCardResult] = useState();
  const [cardLoading, setCardLoading] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [disableReloadBtn, setDisableReloadBtn] = useState(false);
  const {
    authState: { everbeeName, userImageUrl },
  } = useAuth();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const { affiliate } = router.query;
  useEffect(() => {
    if (router.pathname === "/dashboard" && selectedShops.length > 0) {
      getCardData();
    }
  }, [selectedShops, timeRangeSelector]);

  const getCardData = async () => {
    setCardLoading(true);
    const timeRange = dateRangeConstant(timeRangeSelector);
    try {
      const res = await cardDataApi(timeRange, selectedShops);
      setCardResult(res.data.results);
    } catch (error) {
    } finally {
      setCardLoading(false);
    }
  };

  const updateCurrency = async (currentcode) => {
    try {
      addToLoadingSet("updateCurrency");
      const resp = await setDefaultCurrency(currentcode);
      setCurrentCurrency(currentcode);
      router.reload(window.location.pathname);
    } catch (error) {
    } finally {
      removeFromLoadingSet("updateCurrency");
    }
  };
  useEffect(() => {
    if (userAccountDetails?.default_currency_code) {
      setCurrentCurrency(userAccountDetails?.default_currency_code);
    }
  }, [userAccountDetails?.default_currency_code]);

  const reloadDashBoardFunction = async () => {
    try {
      const { status, data } = await reloadDashBoard();
      setDisableReloadBtn(true);
      if (status === 200) {
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTimeout(() => {
        setDisableReloadBtn(false);
      }, 60000);
    }
  };

  const handleEmailPricingPage = () => {
    window.open(
      `${process.env.NEXT_PUBLIC_EMAIL_FRONTEND_APP}/pricing`,
      "_blank"
    );
  };

  return (
    <>
      {" "}
      <ChromeBanner sx={{ marginTop: "20px" }} message={"Try EverBee Email"} />
      <Box
        className={classes.headerContainer}
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        {" "}
        <Box
          component="img"
          src="bg-layer1.png"
          alt="bg"
          width="100%"
          className={classes.layerOneImg}
        />{" "}
        <Box
          component="img"
          src={
            mode === "light" ? "ligthTheme-bg-img.png" : "DarkTheme-bg-img.png"
          }
          alt="BG"
          className={classes.layerTwoImg}
        />
        {/* mobile header images */}
        <Box
          component="img"
          src={"Mob-bg-layer1.png"}
          alt="bg"
          width="100%"
          className={classes.MobLayerOneImg}
        />
        <Box
          component="img"
          src="Mob-bg-layer2.png"
          alt="BG"
          className={classes.MobLayerTwoImg}
        />
        {router.pathname === "/pricing" && (
          <Box className={classes.textWrapper} sx={{ width: "85% !important" }}>
            {affiliate ? (
              <Box sx={{ textAlign: "center", marginLeft: "3%" }}>
                <Typography
                  sx={{ fontSize: "22px", textAlign: "center", marginY: "4px" }}
                >
                  Congratulations, You are eligible for this offer
                </Typography>
                <Typography
                  sx={{
                    fontSize: "36px",
                    fontWeight: "600",
                    textAlign: "center",
                    marginY: "4px",
                  }}
                >
                  Subscribe for one month, get another month free!
                </Typography>
                <Typography
                  sx={{
                    color: "#053E77",
                    fontWeight: "800",
                    textDecoration: "underline",
                    lineHeight: "3.5",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenTermsModal(true);
                  }}
                >
                  TERMS & CONDITIONS
                </Typography>
              </Box>
            ) : (
              <>
                <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
                  Find the best plan for your Etsy shop
                </Typography>
                <Typography>
                  Join EverBee to build and grow a successful business on Etsy.
                </Typography>
                <Box
                  sx={{
                    width: "110%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "3px",
                    cursor: "pointer",
                    marginTop: "10px",
                    padding: "2px",
                  }}
                  onClick={handleEmailPricingPage}
                >
                  <Box sx={{ padding: "3px" }}>
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        fontWeight: "600",
                        fontFamily: "poppins",
                        color: "#002753",
                      }}
                    >
                      Looking for EverBee Email plans? Click here!
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
        {router.pathname !== "/pricing" && (
          <Box
            sx={{
              marginTop: {
                md:
                  router.pathname == "/productAnalytics" ||
                  router.pathname == "/inventory" ||
                  router.pathname == "/favorites" ||
                  router.pathname == "/keywordResearch" ||
                  router.pathname == "/etsyCalculator" ||
                  router.pathname == "/myListings" ||
                  router.pathname == "/listingRankTracker" ||
                  router.pathname == "/tradeMarkMonitor"
                    ? "-40px"
                    : "0px",
              },
            }}
          >
            <Box
              className={classes.textWrapper}
              sx={{
                marginTop: {
                  md:
                    router.pathname == "/productAnalytics" ||
                    router.pathname == "/inventory" ||
                    router.pathname == "/favorites" ||
                    router.pathname == "/keywordResearch" ||
                    router.pathname == "/etsyCalculator" ||
                    router.pathname == "/myListings" ||
                    router.pathname == "/listingRankTracker" ||
                    router.pathname == "/tradeMarkMonitor"
                      ? "-40px"
                      : "0px",
                },
              }}
            >
              {router.pathname !== "/favorites" &&
                router.pathname !== "/inventory" &&
                router.pathname !== "/productAnalytics" &&
                router.pathname !== "/keywordResearch" &&
                router.pathname !== "/etsyCalculator" &&
                router.pathname !== "/myListings" &&
                router.pathname !== "/listingRankTracker" &&
                router.pathname !== "/tradeMarkMonitor" && (
                  <Typography className={classes.headingText}>
                    {greetingMessage}, {everbeeName?.split(" ")[0]}
                  </Typography>
                )}
              {router.pathname === "/favorites" && (
                <Typography className={classes.headingText}>
                  Favorites
                </Typography>
              )}
              {router.pathname === "/inventory" && (
                <Typography className={classes.headingText}>
                  Inventory
                </Typography>
              )}
              {router.pathname === "/productAnalytics" && (
                <Typography className={classes.headingText}>
                  Product Analytics
                </Typography>
              )}
              {router.pathname === "/keywordResearch" && (
                <Typography className={classes.headingText}>
                  Keyword Research
                </Typography>
              )}
              {router.pathname === "/myListings" && (
                <Typography className={classes.headingText}>
                  My Listings
                </Typography>
              )}
              {router.pathname === "/listingRankTracker" && (
                <>
                  <Typography className={classes.headingText}>
                    Listing Rank Tracker
                  </Typography>
                </>
              )}{" "}
              {router.pathname === "/tradeMarkMonitor" && (
                <Typography className={classes.headingText}>
                  Trademark Monitor
                </Typography>
              )}
              {/* {router.pathname !== "/favorites" &&
                router.pathname !== "/productAnalytics" &&
                router.pathname !== "/keywordResearch" &&
                router.pathname !== "/etsyCalculator" &&
                router.pathname !== "/listingRankTracker" && (
                  <Box sx={{ marginTop: "-5px" }}>
                    {sellerSalesChannelsDetails[0]?.sales_channel_shop_name ? (
                      <ShopSelector />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginLeft: "5px",
                          marginTop: "-6px",
                        }}
                      >
                        {userAccountDetails?.name}(Non seller){" "}
                      </Typography>
                    )}
                  </Box>
                )} */}
            </Box>

            <Box
              className={classes.rightIconsWrapper}
              sx={{
                marginTop: {
                  md:
                    router.pathname == "/productAnalytics" ||
                    router.pathname == "/inventory" ||
                    router.pathname == "/favorites" ||
                    router.pathname == "/keywordResearch" ||
                    router.pathname == "/etsyCalculator" ||
                    router.pathname == "/listingRankTracker" ||
                    router.pathname == "/tradeMarkMonitor"
                      ? "-40px"
                      : "0px",
                },
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  backgroundColor: "background.secondary",
                  position: "relative",
                  borderRadius: "5px",
                  display:
                    router.pathname !== "/productAnalytics" &&
                    router.pathname !== "/favorites" &&
                    router.pathname !== "/inventory" &&
                    router.pathname !== "/keywordResearch" &&
                    router.pathname !== "/etsyCalculator" &&
                    router.pathname !== "/listingRankTracker" &&
                    router.pathname !== "/tradeMarkMonitor"
                      ? "flex"
                      : "none",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                ref={calAnchorRef}
                className="time-range"
              >
                <Typography
                  fontWeight="400"
                  fontSize="12px"
                  textAlign="center"
                  color="fontColor.fontColorPrimary"
                  p="7px"
                  onClick={() => setShowCalendar((prevOpen) => !prevOpen)}
                  sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                >
                  {timeRangeSelector}
                </Typography>

                <Badge variant="dot" color="error">
                  <div style={{ position: "relative" }}>
                    <IconButton
                      sx={{
                        outline: "1px solid white",
                        borderRadius: "4px",
                        height: "32px",
                        width: "32px",
                        backgroundColor: "primary.main",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                      onClick={() => setShowCalendar((prevOpen) => !prevOpen)}
                    >
                      <CalendarToday />
                    </IconButton>
                    {showCalendar && (
                      <ClickAwayListener
                        onClickAway={() => setShowCalendar(false)}
                      >
                        <CalendarDropdown
                          open={showCalendar}
                          setOpen={setShowCalendar}
                          anchorRef={calAnchorRef}
                        />
                      </ClickAwayListener>
                    )}
                  </div>
                </Badge>
              </Box>
              {router.pathname !== "/keywordResearch" && (
                <FormControl variant="standard">
                  <Select
                    sx={{
                      border: "none",
                      color: "white",
                    }}
                    onChange={(e) => updateCurrency(e.target.value)}
                    value={currentCurrency}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {currencies.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
              <Box className={classes.iconsWrapper}>
                <div>
                  <Box
                    ref={anchorRef}
                    onClick={handleToggle}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <Avatar
                      sx={{ textTransform: "capitalize" }}
                      src={userImageUrl !== null && userImageUrl}
                    >
                      {everbeeName && userImageUrl === null && everbeeName[0]}
                    </Avatar>
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      sx={{
                        display: { xs: "flex", sm: "none", md: "flex" },
                      }}
                    >
                      {everbeeName?.split(" ")[0]}
                    </Typography>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Box>
                  <HeaderDropdown
                    open={open}
                    setOpen={setOpen}
                    anchorRef={anchorRef}
                  />
                </div>
              </Box>
            </Box>
            {router.pathname === "/dashboard" && (
              <Tooltip
                title={
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="18px"
                    pl="5px"
                  >
                    Click to reload dashboard data.
                  </Typography>
                }
              >
                <IconButton
                  onClick={reloadDashBoardFunction}
                  disabled={disableReloadBtn}
                  className={classes.rightIconsWrapper}
                  sx={{
                    marginTop: "45px",
                    zIndex: "2 !important",
                    marginRight: "-5px !important",
                  }}
                >
                  <Refresh
                    sx={{
                      fontSize: "18px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
        {/* //Cards */}
        {router.pathname === "/dashboard" && (
          <Box className={classes.cardsWrapper}>
            <Cards
              cardInfo={cardData[0]}
              value={getNumberWithCommas(
                cardResult?.total_data_points?.gross_revenue
              )}
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
            <Cards
              cardInfo={cardData[1]}
              value={getNumberWithCommas(
                cardResult?.total_data_points?.average_order_value
              )}
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
            <Cards
              cardInfo={cardData[2]}
              value={getNumberWithCommas(
                cardResult?.total_data_points?.total_orders
              )}
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
            <Cards
              cardInfo={cardData[3]}
              value={getNumberWithCommas(cardResult?.total_data_points?.ltv)}
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
            <Cards
              cardInfo={cardData[4]}
              value={
                cardResult?.total_data_points &&
                `${getNumberWithCommas(
                  cardResult?.total_data_points?.repeat_customer_rate
                )}%`
              }
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{ display: { sm: "none", xs: "flex" }, flexDirection: "column" }}
      >
        <Box
          sx={{
            flexDirection: "column",
            color: "white",
            backgroundColor: "appBar.main",
            padding: "10px 20px",
          }}
        >
          {router.pathname !== "/pricing" && (
            <>
              {router.pathname !== "/favorites" &&
                router.pathname !== "/inventory" &&
                router.pathname !== "/productAnalytics" &&
                router.pathname !== "/keywordResearch" &&
                router.pathname !== "/etsyCalculator" &&
                router.pathname !== "/listingRankTracker" &&
                router.pathname !== "/tradeMarkMonitor" && (
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "400", marginBottom: "15px" }}
                  >
                    {greetingMessage}, {everbeeName?.split(" ")[0]}
                  </Typography>
                )}
              {router.pathname === "/favorites" && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "400", marginBottom: "15px" }}
                >
                  Favorites
                </Typography>
              )}
              {router.pathname === "/listingRankTracker" && (
                <>
                  <Typography className={classes.headingText}>
                    Listing Rank Tracker
                  </Typography>
                </>
              )}
              {router.pathname === "/inventory" && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "400", marginBottom: "15px" }}
                >
                  Inventory
                </Typography>
              )}
              {router.pathname === "/productAnalytics" && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "400", marginBottom: "15px" }}
                >
                  Product Analytics
                </Typography>
              )}
              {router.pathname === "/keywordResearch" && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "400", marginBottom: "15px" }}
                >
                  Keyword Research
                </Typography>
              )}

              {router.pathname === "/tradeMarkMonitor" && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "400", marginBottom: "15px" }}
                >
                  Trademark Monitor
                </Typography>
              )}

              {router.pathname !== "/favorites" &&
                router.pathname !== "/productAnalytics" &&
                router.pathname !== "/keywordResearch" &&
                router.pathname !== "/etsyCalculator" &&
                router.pathname !== "/listingRankTracker" && (
                  <Box sx={{ marginTop: "-5px" }}>
                    {sellerSalesChannelsDetails[0]?.sales_channel_shop_name ? (
                      <ShopSelector />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          marginLeft: "5px",
                          marginTop: "-6px",
                        }}
                      >
                        {userAccountDetails?.name}(Non seller){" "}
                      </Typography>
                    )}
                  </Box>
                )}
            </>
          )}

          {router.pathname === "/pricing" && (
            <Box>
              {affiliate ? (
                <Box sx={{ textAlign: "center", marginLeft: "3%" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      textAlign: "center",
                      marginY: "4px",
                    }}
                  >
                    Congratulations, You are eligible for this offer
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                      marginY: "4px",
                    }}
                  >
                    Subscribe for one month, get another month free!
                  </Typography>
                  <Typography
                    sx={{
                      color: "#053E77",
                      fontWeight: "800",
                      textDecoration: "underline",
                      lineHeight: "3.5",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenTermsModal(true);
                    }}
                  >
                    TERMS & CONDITIONS
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
                    Find the best plan for your Etsy shop
                  </Typography>
                  <Typography>
                    Join EverBee to build and grow a successful business on
                    Etsy.
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        {router.pathname === "/dashboard" && (
          <Box className={classes.cardsWrapper}>
            <Cards
              cardInfo={cardData[0]}
              value={getNumberWithCommas(
                cardResult?.total_data_points?.gross_revenue
              )}
              currencySymbol={cardResult?.currency_symbol}
              isLoading={cardLoading}
            />
            <Cards
              cardInfo={cardData[1]}
              value={getNumberWithCommas(
                cardResult?.total_data_points?.average_order_value
              )}
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
            <Cards
              cardInfo={cardData[2]}
              value={getNumberWithCommas(
                cardResult?.total_data_points?.total_orders
              )}
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
            <Cards
              cardInfo={cardData[3]}
              value={getNumberWithCommas(cardResult?.total_data_points?.ltv)}
              currencySymbol={cardResult?.currency_symbol}
              isLoading={cardLoading}
            />
            <Cards
              cardInfo={cardData[4]}
              value={
                cardResult?.total_data_points &&
                `${getNumberWithCommas(
                  cardResult?.total_data_points?.repeat_customer_rate
                )}%`
              }
              isLoading={cardLoading}
              currencySymbol={cardResult?.currency_symbol}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Header;
