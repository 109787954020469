import Image from "next/image";
import { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import arrowDown from "../../assets/svg/arrow-down.svg";
import arrowUp from "../../assets/svg/arrow-up.svg";
import { useLoader } from "../../contexts/LoaderContext";
import StyledWrapper from "./StyledWrapper";
import { MAIN_MENU } from "../SidebarN/Sidebar";
import { useAuth } from "../../contexts/AuthContext";
import { ReconnectModal } from "../ReconnectModal";
import { Tooltip, Typography } from "@mui/material";
import LockIcon from "../../assets/svg/lock-1.svg";
import Link from "next/link";
import amplitude from "amplitude-js";

const SideMainMenu = ({
  isExpandable,
  isActive,
  navIcon,
  navText,
  menuItemName,
  setMenuState,
  menuState,
  isExpanded,
  isMenuNew,
  setIsExpanded,
  selectedStores,
  isUser,
}) => {
  //   const isSidebarExpanded = useSelector((state) => state.sidebar.isExpanded);
  //   const dispatch = useDispatch();

  const router = useRouter();
  const [reconnectModal, setReconnectModal] = useState(false);
  const { setOpenLearn, openLearn } = useLoader();
  const {
    authState: {
      everbeeEmail,
      everbeeToken,
      everbeeUserId,
      betaUser,
      emailBeta,
    },
  } = useAuth();

  const handleChangeActiveMainMenu = () => {
    if (navText !== "Dashboard") {
      setMenuState(menuItemName);
    }
    if (!isExpanded) {
      setIsExpanded(true);
      localStorage.setItem("sidebarExpanded", true);
    }
    if (navText === "Dashboard") {
      router.push("/");
      setMenuState("dashboard");
    }
    if (navText === "Optimize" && isActive && isExpanded) {
      setMenuState("dashboard");
    }
    if (navText === "Thrive" && isActive) {
      setMenuState("dashboard");
    }
    if (navText === "Research Tools" && isActive && isExpanded) {
      setMenuState("dashboard");
    }
    if (navText === "Learn") {
      setOpenLearn(true);
    }
    if (navText === "EverBee Email") {
      amplitude.getInstance().logEvent("Email sidebar");
      setIsExpanded(false);
      router.push("/evermail");
      localStorage.setItem("sidebarExpanded", false);
      // if (selectedStores?.hasAllScope && selectedStores?.isShop) {
      //   setIsExpanded(false);
      //   router.push("/evermail");
      //   localStorage.setItem("sidebarExpanded", false);
      // } else if (selectedStores?.hasAllScope == false) {
      //   setReconnectModal(true);
      // } else {
      //   router.push(
      //     `/account${!selectedStores?.isShop ? "?connectShop=true" : ""}`
      //   );
      // }
    }
    if (navText === "EverBee Print") {
      amplitude.getInstance().logEvent("Print sidebar");
      setIsExpanded(false);
      router.push("/everbeePrint");
      localStorage.setItem("sidebarExpanded", false);
    }
    if (navText === "Account") {
      router.push("/account");
    }
    if (navText === "Courses") {
      router.push("/courses");
    }
    if (navText === "Training") {
      window.open(
        "https://training.everbee.io/etsygrowth1678377092833",
        "_blank"
      );
    }
    if (navText === "Affiliate Program") {
      window.open("https://everbee.io/affiliates/", "_blank");
    }
    if (navText === "Plans") {
      router.push("/pricing");
    }
    if (navText === "Support") {
      window.open("http://help.everbee.io", "_blank");
    }
    if (navText === "Youtube") {
      window.open(
        " https://www.youtube.com/channel/UC0LAF8sq5fdTKsEW5SE6ZdA ",
        "_blank"
      );
    }
    if (navText === "Community") {
      window.open("https://www.facebook.com/groups/416630082921388", "_blank");
    }
    if (navText === "Feature request") {
      window.open("https://everbee.canny.io/wish-list", "_blank");
    }
  };
  return (
    <>
      <ReconnectModal
        reconnectModal={reconnectModal}
        setReconnectModal={setReconnectModal}
        handleClose={() => {
          setReconnectModal(false);
        }}
        everbeeToken={everbeeToken}
        from={navText}
      />
      {navText == "EverBee Print" || navText == "EverBee Email" ? (
        navText == "EverBee Print" && betaUser ? (
          <Tooltip
            PopperProps={{
              style: {
                zIndex: 9999999,
                display: isExpanded ? "none" : "block",
              },
            }}
            placement="right"
            arrow
            title={
              <Typography
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                p="8px"
              >
                {navText}
              </Typography>
            }
          >
            <StyledWrapper
              isExpanded={isExpanded}
              isExpandable={true}
              isActive={isActive}
              onClick={handleChangeActiveMainMenu}
            >
              <div className="menu-seperator">
                <Image
                  className="navitem-icon"
                  src={navIcon}
                  alt="Side navbar icon"
                />
                {isExpanded && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                    className="navitem-text"
                  >
                    {navText}
                  </motion.div>
                )}

                {isMenuNew && isExpanded && (
                  <p
                    style={{
                      backgroundColor: "#1458A5",
                      padding: "2px 4px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                      fontSize: "8px",
                      marginLeft: "12px",
                      fontWeight: "500",
                      width: "33px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    BETA
                  </p>
                )}
              </div>
            </StyledWrapper>
          </Tooltip>
        ) : navText == "EverBee Email" ? (
          <Tooltip
            PopperProps={{
              style: {
                zIndex: 9999999,
                display: isExpanded ? "none" : "block",
              },
            }}
            placement="right"
            arrow
            title={
              <Typography
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                p="8px"
              >
                {navText}
              </Typography>
            }
          >
            <StyledWrapper
              isExpanded={isExpanded}
              isExpandable={true}
              isActive={isActive}
              onClick={handleChangeActiveMainMenu}
            >
              <div className="menu-seperator">
                <Image
                  className="navitem-icon"
                  src={navIcon}
                  alt="Side navbar icon"
                />
                {isExpanded && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                    className="navitem-text"
                  >
                    {navText}
                  </motion.div>
                )}
                {/* {isMenuNew && isExpanded && (
                  <p
                    style={{
                      backgroundColor: "#1458A5",
                      padding: "2px 4px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                      fontSize: "8px",
                      marginLeft: "12px",
                      fontWeight: "500",
                      width: "33px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    BETA
                  </p>
                )} */}
              </div>
            </StyledWrapper>
          </Tooltip>
        ) : null
      ) : (
        <Tooltip
          PopperProps={{
            style: { zIndex: 9999999, display: isExpanded ? "none" : "block" },
          }}
          placement="right"
          arrow
          title={
            <Typography
              fontSize="12px"
              fontWeight="500"
              lineHeight="18px"
              p="8px"
            >
              {navText}
            </Typography>
          }
        >
          <StyledWrapper
            isExpanded={isExpanded}
            isExpandable={true}
            isActive={isActive}
            onClick={handleChangeActiveMainMenu}
            navText={navText}
            isUser={isUser}
          >
            <div className="menu-seperator">
              <Image
                className="navitem-icon"
                src={navIcon}
                alt="Side navbar icon"
              />
              {isExpanded && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.2 } }}
                  className="navitem-text lock-shadow"
                >
                  <>
                    <div>
                      {navText === "Optimize" && isUser === true ? (
                        <Tooltip
                          PopperProps={{
                            style: { zIndex: 9999999999, cursor: "pointer" },
                          }}
                          placement="right"
                          arrow
                          title={
                            <Typography
                              fontSize="12px"
                              fontWeight="500"
                              lineHeight="18px"
                              p="5px"
                            >
                              <Link href={"/pricing"}>
                                <span style={{ textDecoration: "underline" }}>
                                  Upgrade To Growth Plan
                                </span>
                              </Link>
                            </Typography>
                          }
                        >
                          <div className="optimize-div">
                            <div>{navText}</div>

                            <Image src={LockIcon} alt="lock-icon" />
                          </div>
                        </Tooltip>
                      ) : (
                        <div>{navText}</div>
                      )}
                    </div>
                  </>
                </motion.div>
              )}
              {isMenuNew && isExpanded && (
                <p
                  style={{
                    backgroundColor: "#1458A5",
                    padding: "2px 4px",
                    borderRadius: "5px",
                    color: "#FFFFFF",
                    fontSize: "8px",
                    marginLeft: "12px",
                    fontWeight: "500",
                    width: "33px",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  NEW
                </p>
              )}
            </div>
            {isExpanded && isExpandable && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.2 } }}
                className="arrowcontainer"
              >
                {" "}
                {isActive ? (
                  <Image className="arrow-up" src={arrowUp} alt="Up Arrow" />
                ) : (
                  <Image
                    className="arrow-down"
                    src={arrowDown}
                    alt="Down Arrow"
                  />
                )}
              </motion.div>
            )}
          </StyledWrapper>
        </Tooltip>
      )}
    </>
  );
};

export default SideMainMenu;
