import axios from "axios";
const AUTH_BASE_URL = process.env.NEXT_PUBLIC_SSO_APP;

export const getAllListingsScanned = (sortPattern) =>
  axios.get(
    `${AUTH_BASE_URL}/keyword_trademark?order_by=${sortPattern.sortBy}&order_direction=${sortPattern.sortDirection}&page=${sortPattern.page}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorized-token": localStorage.getItem("everbeeToken"),
      },
    }
  );
//
export const getSingleListingTradeMarkDetails = (listindId) =>
  axios.get(`${AUTH_BASE_URL}/keyword_trademark/show?listing_id=${listindId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorized-token": localStorage.getItem("everbeeToken"),
    },
  });

export const getScanSingleListingTradeMarkMonitor = (listingId) =>
  axios.post(
    `${AUTH_BASE_URL}/keyword_trademark/rescan?listing_id=${listingId}&rescan_listing=1`,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorized-token": localStorage.getItem("everbeeToken"),
      },
    }
  );

export const snoozeTrademark = (payload) =>
  axios.post(
    `${AUTH_BASE_URL}/keyword_trademark/snooze?listing_id=${payload.listingId}&trademark_id=${payload.trademarkId}&snooze=2&snooze_listing=${payload.snoozeListing}&time=${payload.time}`
  );

export const snoozeKeywordTrademark = (payload) =>
  axios.put(`${AUTH_BASE_URL}/trademark/snooze`, {
    keyword_ids: payload.keyword_ids,
  });

export const snoozeAllKeywordTrademark = () =>
  axios.put(`${AUTH_BASE_URL}/trademark/snooze_all`, {});

export const getScanAllListingsTradeMarkMonitor = () =>
  axios.post(
    `${AUTH_BASE_URL}/keyword_trademark/rescan?rescan_listing=2`,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorized-token": localStorage.getItem("everbeeToken"),
      },
    }
  );

export const unSnoozeTrademark = (payload) =>
  axios.post(
    `${AUTH_BASE_URL}/keyword_trademark/unsnooze?listing_id=${payload.listingId}&trademark_id=${payload.trademarkId}&snooze=2&snooze_listing=${payload.snoozeListing}`
  );

export const unSnoozeKeywordTrademark = (payload) =>
  axios.put(`${AUTH_BASE_URL}/trademark/unsnooze`, {
    keyword_ids: payload.keyword_ids,
  });

export const getSingleKeywordTrademarkDetails = (payload) =>
  axios.get(`${AUTH_BASE_URL}/keyword_trademark/trademarks/${payload}`);

export const getMonitoredKeywordApi = (payload) => {

  var baseMonitoredKeywordUrl = `?order_by=${payload.sortBy}&order_direction=${payload.sortDirection}&page=${payload.page}&per_page=15`;
  if (payload?.query) {
    baseMonitoredKeywordUrl =
      baseMonitoredKeywordUrl + `&search_query=${payload?.query}`;
  }
  if (payload?.showAll) {
    baseMonitoredKeywordUrl =
      baseMonitoredKeywordUrl + `&show_all=${payload.showAll}`;
  }
  if (payload?.hideNonTrademarkKeywords) {
    baseMonitoredKeywordUrl =
      baseMonitoredKeywordUrl +
      `&hide_non_trademark_keywords=${payload?.hideNonTrademarkKeywords}`;
  }

  return axios.get(
    `${AUTH_BASE_URL}/trademark/keywords${baseMonitoredKeywordUrl}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorized-token": localStorage.getItem("everbeeToken"),
      },
    }
  );
};

export const addMonitoredKeywordApi = (payload) =>
  axios.post(
    `${AUTH_BASE_URL}/trademark/keywords`,
    { keywords: payload },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorized-token": localStorage.getItem("everbeeToken"),
      },
    }
  );

export const getKeywordListingDetails = (payload, id) =>
  axios.get(`${AUTH_BASE_URL}/trademark/listings?order_by=${payload.current.sortBy}&order_direction=${payload.current.sortDirection}&page=${payload.current.page}&id=${id}&per_page=15`,
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorized-token": localStorage.getItem("everbeeToken"),
      },
    },
  )

