import React from "react";
import { useRouter } from "next/router";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Tooltip, Typography } from "@mui/material";
import NextLink from "next/link";
const LockedIconComponent = ({ iconColor }) => {
  const router = useRouter();
  return (
    <Tooltip
      title={
        <Typography fontSize="12px" fontWeight="400" lineHeight="18px" pl="5px">
          <a style={{ color: "inherit" }} href={`/pricing`} target="_blank">
            Upgrade to Growth Plan
          </a>
        </Typography>
      }
      arrow
    >
      {/* <NextLink href="/pricing" passHref> */}
      <LockOutlinedIcon
        fontSize="small"
        color={iconColor ? iconColor : "#57b3f1"}
        sx={{ color: `${iconColor ? iconColor : "#57b3f1"}` }}
        style={{ cursor: "pointer" }}
        onClick={() => router.replace("/pricing")}
      />
      {/* </NextLink> */}
    </Tooltip>
  );
};

export default LockedIconComponent;
