import styled from "styled-components";

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999999;
  .fixed-relative {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fafafa;
    width: ${({ isExpanded }) => (isExpanded ? "256px" : "78px")};
    padding-top: ${({ trialHeight }) => (trialHeight ? "10px" : "10px")};
    /* padding-left: 20px;
    padding-right: 20px; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-main {
    width: ${({ isExpanded }) => isExpanded && "84%"};
  }

  .logo-container {
    height: 2rem;
    display: flex;
    justify-content: ${({ isExpanded }) =>
    isExpanded ? "flex-start" : "center"};
  }

  .nav-sidebar-inner-scroll {
    width: 100%;
    height: ${({ isExpanded, isUser, matches, trialHeight }) =>
    isExpanded
      ? isUser
        ? matches
          ? "calc(100vh - 23rem)"
          : "calc(100vh - 20rem)"
        : matches
          ? (trialHeight ? "calc(100vh - 27rem)" : "calc(100vh - 23rem)")
          : trialHeight ? "calc(100vh - 23rem)" : "calc(100vh - 23rem)"
      : ""};
    /* height while not expanded - (Kept for reference) */
    /* height: calc(100vh - 22rem); */
    margin-top: 1.25rem;
    overflow-y: overlay;
    /* Below overflow values kept for reference */
    /* overflow-y: ${({ isExpanded }) => (isExpanded ? "auto" : "auto")}; */
    /* overflow-x: hidden; */
  }

  .sidebar-inner {
  }

  .sidebar-footer {
    width: 90%;
    position: ${({ isExpanded }) => isExpanded && "absolute"};
    bottom: ${({ isExpanded }) => isExpanded && "0"};
    margin-bottom: ${({ isExpanded }) => (isExpanded ? "0.6rem" : "0.5rem")};
  }

  .add-to-chrome {
    margin-bottom: ${({ isExpanded }) => (isExpanded ? "0rem" : "0.6rem")};
    margin-top: 0.4rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .upgrade-div {
    width: 231px;
    height: 30px;
    background: #cce6ff;
    margin-bottom: 0.7rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0.5px solid #1f76cc;
  }

  .upgrade-not-expanded {
    width: 44px;
    height: 30px;
    background: #cce6ff;
    margin-left: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 0.5px solid #1f76cc;
    align-items: center;
    margin-bottom: ${({ trialHeight }) => (trialHeight ? "-20px" : "")};
  }

  .user-info {
    display: flex;
    border-radius: 6px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    height: 66px;
    position: relative;
    cursor: pointer;
  }

  .profile-container {
    display: flex;
    align-items: center;
  }
  .product-container {
        position: relative;
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        /* padding: 0.75rem 1rem; */

        .product-selecter {
          cursor: pointer;
          margin-top: 6px;
        }

        .product-logo {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          .product-name {
            color: #1e6cc4;
            -webkit-text-stroke-width: 0.30000001192092896;
            -webkit-text-stroke-color: #fff;
            font-family: MafraW01-Bold;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem;
            letter-spacing: 0.025rem;
          }
        }

        .logo-container {
          width: 100%;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: ${({ $isExtension }) => $isExtension && "center"};
          gap: ${({ $isExtension }) => !$isExtension && "0.125rem"};

          img {
            cursor: pointer;
          }
        }
      }
`;

export default StyledWrapper;
