import React, { useRef, useEffect, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IFrame } from "./IFrame";
import amplitude from "amplitude-js";

const VideoTab = ({ title, subtitle, articlelink, video }) => {
  const handleInferredClick = () => {
    console.log("amplitude");
    amplitude.getInstance().logEvent("Learn - video clicked");
  };
  return (
    <Box
      sx={{
        marginLeft: "20px",
        marginBottom: "80px",
        width: "95%",
        paddingTop: "10px",
      }}
    >
      <Box>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#14396E" }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            color: "#55555",
            fontFamily: "500",
            padding: "2px 0px 15px 0px",
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "20px",
          }}
          dangerouslySetInnerHTML={{ __html: articlelink }}
        />
      </Box>
      <Box sx={{ height: "200px" }}>
        <IFrame
          width="457"
          height="267"
          src={video}
          title="YouTube video player"
          frameborder="0"
          onInferredClick={handleInferredClick}
        />
      </Box>
    </Box>
  );
};
export default VideoTab;
