export const cardData = [
  {
    img: "cardIcon1.png",
    title: "Gross revenue",
    percentage: "10.30%",
    tooltipText:
      "This is the total amount of money that has been processed through your shop. This includes shipping, sales tax, etc. But it doesn't reflect any refunds or cancelled orders.",
  },
  {
    img: "aov.png",
    title: "Avg. Order Value",
    percentage: "20.30%",
    tooltipText:
      "Average order value (AOV) tracks the average amount spent each time a customer places an order with you. Total Revenue / # of orders = AOV. Higher the better.",
  },

  {
    img: "totalOrderLight.png",
    title: "Total Orders",
    percentage: "47%",
    tooltipText: "The total amount of orders placed in the selected timeframe.",
  },
  {
    img: "lvtLight.png",
    title: "Lifetime Value",
    percentage: "27%",
    tooltipText:
      "Lifetime Value (LTV) is CLTV is a metric that indicates how valuable a single customer is to your company across their entire “lifetime,” ie. throughout the business relationship. Also referred to as Customer Lifetime Value (CLTV). Higher the better.",
  },
  {
    img: "shopRankLight.png",
    title: "Repeat Rate",
    percentage: "54%",
    tooltipText:
      "Repeat customer rate, or repeat purchase rate, is the percentage of your existing customers who have purchased from you two or more times. Higher the better.",
  },
];
