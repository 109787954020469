import { createContext, useContext, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { accountDetails, salesChannels } from "../apis/account";
import { dashboardData, setDefaultSalesChannels } from "../apis/dashboard";
import dateRangeConstant from "../helper/dateRangeConstant";
import { salesChannelsReducer } from "../reducers/SalesChannelsReducer";
import { useAuth } from "./AuthContext";
import { useLoader } from "./LoaderContext";
const salesChannelsContext = createContext();
const initialState = {
  userAccountDetails: {},
  salesChannelsDetails: [],
  sellerSalesChannelsDetails: [],
  timeRangeSelector: "Last 30 Days",
  selectedShops: [],
  dashboardData: {},
  selectedStores: [],
};

const SaleChannelProvider = ({ children }) => {
  const [salesChannelsState, salesChannelsDispatch] = useReducer(
    salesChannelsReducer,
    initialState
  );
  const {
    authState: { everbeeEmail, everbeeToken },
  } = useAuth();
  const { addToLoadingSet, removeFromLoadingSet } = useLoader();
  const getSalesChannels = async () => {
    try {
      addToLoadingSet("getSalesChannels");
      const { data, status } = await salesChannels(everbeeEmail);
      if (status === 200) {
        var seller_sales_channel = data?.sales_channels?.filter(
          (item) => item.sales_channel_shop_id != null
        );
        salesChannelsDispatch({
          type: "SET_CHANNELS_DETAILS",
          payload: {
            salesChannels: data?.sales_channels,
            sellerSalesChannels:
              seller_sales_channel?.length > 0
                ? seller_sales_channel
                : data?.sales_channels || data?.sales_channels,
          },
        });
      }
    } catch (err) {
      toast.error("Something went wrong. Try again!");
    } finally {
      removeFromLoadingSet("getSalesChannels");
    }
  };

  const getAccountDetails = async () => {
    try {
      addToLoadingSet("getAccountDetails");
      const { data, status } = await accountDetails(everbeeToken);
      if (status === 200) {
        salesChannelsDispatch({
          type: "SET_USER_ACCOUNT_DETAILS",
          payload: data.user_profile,
        });
        salesChannelsDispatch({
          type: "SHOP_SELECTOR",
          payload: data.user_profile
            ? data.user_profile?.default_sales_channel_ids
            : [],
        });
      }
    } catch (err) {
      // toast.error("Something went wrong. Try again!");
    } finally {
      removeFromLoadingSet("getAccountDetails");
    }
  };

  useEffect(() => {
    if (everbeeToken) {
      getSalesChannels();
      getAccountDetails();
    }
  }, [everbeeToken]);

  const updateSelectedSalesChannels = async (channelIds) => {
    try {
      addToLoadingSet("updateSelectedSalesChannels");

      const {
        data: { current_user },
      } = await setDefaultSalesChannels(channelIds);
      localStorage.removeItem("printPrevPage");
      window.location.reload();
    } catch (error) {
    } finally {
      removeFromLoadingSet("updateSelectedSalesChannels");
    }
  };

  const setStoreSelected = (store) => {
    salesChannelsDispatch({
      type: "SET_SELECTED_STORE",
      payload: store,
    });
  };

  return (
    <salesChannelsContext.Provider
      value={{
        salesChannelsState,
        salesChannelsDispatch,
        getSalesChannels,
        getAccountDetails,
        updateSelectedSalesChannels,
        setStoreSelected,
      }}
    >
      {children}
    </salesChannelsContext.Provider>
  );
};

const useSalesChannel = () => useContext(salesChannelsContext);

export { useSalesChannel, SaleChannelProvider };
