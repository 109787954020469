import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Popover } from "@mui/material";
import Image from "next/image";
import researchLogo from "../../assets/svg/research_logo.svg";
import printLogo from "../../assets/svg/print_logo.svg";
import emailLogo from "../../assets/svg/email_logo.svg";
import arrowUp from "../../assets/svg/arrow_up.svg";
import { useAuth } from "../../contexts/AuthContext";

const StyledWrapper = styled.div`
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 20px 25px -8px rgba(0, 0, 0, 0.06),
    0px 8px 10px -6px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);

  .selector-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 6px;

    .single-product {
      width: 94%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      border-radius: 4px;
      background: #fff;
      cursor: pointer;

      .logo-container {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.61px 0 2px;
      }
      .details-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .product-name {
          color: #1f76cc;
          -webkit-text-stroke-width: 0.20000000298023224;
          -webkit-text-stroke-color: #fff;
          font-family: MafraW01-Bold;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.14px;
          display: flex;
          align-items: center;
          gap: 8px;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 10px;
            border-radius: 8px;
            background: #f2f2f2;
            color: #1f76cc;
            text-align: center;
            font-family: sans-serif;
            font-size: 9.6px;
            font-weight: 700;
            line-height: 8px;
          }
        }
        .product-desc {
          color: #646566;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.48px;
        }
      }
    }
    .default-product {
      border-radius: 4px;
      border: 1px solid #3d98f2;
      background: #f3f9ff;
    }
  }
`;

const ProductSelector = ({
  isSelectorActive,
  setIsSelectorActive,
  productSelectorRef,
}) => {
  const {
    authState: { printBeta, storeBeta },
  } = useAuth();
  const productToggle = [
    {
      name: "EverBee Research",
      logo: "https://db2l6eppot2a3.cloudfront.net/bee-logo.png",
      url: process.env.NEXT_PUBLIC_CORE_FRONTEND_APP,
      description: "Find the best products to sell on Etsy",
      default: true,
    },
    {
      name: "EverBee Email",
      logo: "https://db2l6eppot2a3.cloudfront.net/bee-logo.png",
      url: process.env.NEXT_PUBLIC_EMAIL_FRONTEND_APP,
      description: "Get more sales on autopilot",
      default: false,
    },
    ...(printBeta
      ? [
          {
            name: "EverBee Print",
            logo: "https://db2l6eppot2a3.cloudfront.net/bee-logo.png",
            url: process.env.NEXT_PUBLIC_PRINT_FRONTEND_APP,
            description: "Sell print-on-demand products on your shop",
            default: false,
            badges: ["Beta"],
          },
        ]
      : []),
    ...(storeBeta
      ? [
          {
            name: "EverBee Store",
            logo: "https://db2l6eppot2a3.cloudfront.net/bee-logo.png",
            url: process.env.NEXT_PUBLIC_STORE_FRONTEND_APP,
            description: "Make your own website and sell, sell, sell!",
            default: false,
            badges: ["Beta"],
          },
        ]
      : []),
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProductClick = (url) => {
    window.open(url, "_blank");
    setIsSelectorActive(false);
  };

  useEffect(() => {
    if (isSelectorActive) {
      setAnchorEl(productSelectorRef.current);
    } else setAnchorEl(null);
  }, [isSelectorActive]);

  return (
    <Popover
      sx={{ marginTop: "1rem", zIndex: "10000000000" }}
      open={isSelectorActive}
      anchorEl={anchorEl && anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={() => {
        setIsSelectorActive(false);
      }}
    >
      <StyledWrapper>
        <div className="selector-wrapper">
          {productToggle.map((item) => (
            <div
              key={item.name}
              className={`single-product ${
                item.default ? "default-product" : ""
              }`}
              onClick={() => handleProductClick(item.url)}
            >
              <div className="logo-container">
                <Image src={item.logo} width="30%" height="30%" />
              </div>
              <div className="details-container">
                <span className="product-name">
                  {item.name}
                  {item.badges &&
                    item.badges.length > 0 &&
                    item.badges.map((badge) => <div key={badge}>{badge}</div>)}
                </span>
                <span className="product-desc">{item.description}</span>
              </div>
            </div>
          ))}
        </div>
      </StyledWrapper>
    </Popover>
  );
};

export default ProductSelector;
