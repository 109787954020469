import styled from "styled-components";

const ThreeDaysMainWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 622px;
  height: auto;
  background-color: white;
  padding: 18px 20px 24px 20px;
  flex-grow: 1;
  border-radius: 15px;
  border: 5px solid #eaeaea;

  .close-wrapper {
    background: #f7f7f7;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trial-ending {
    display: flex;
    justify-content: center;
    padding: 16px;
    gap: 0.5rem;
  }

  .plan-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 16px 0px 16px 0px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    padding: 16px;
    gap: 17px;
  }

  .border-wrapper {
    border: 1px solid #ebebeb;
    margin-right: 10px;
    margin-left: 9px;
  }
`;

export default ThreeDaysMainWrapper;
