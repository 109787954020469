import { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  Box,
  IconButton,
  Radio,
} from "@mui/material";
import {
  AddCircle,
  VisibilityRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import { useSalesChannel } from "../../contexts/SalesChannelContext";
import { useAuth } from "../../contexts/AuthContext";
import { useLoader } from "../../contexts/LoaderContext";
import { setHideShopName } from "../../apis/dashboard";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "250px",
      borderRadius: "10px",
    },
  },
};

const ShopSelector = () => {
  const {
    salesChannelsState: {
      selectedShops,
      salesChannelsDetails,
      sellerSalesChannelsDetails,
      userAccountDetails,
    },
    updateSelectedSalesChannels,
    salesChannelsDispatch,
  } = useSalesChannel();
  const default_sales_channel_ids =
    userAccountDetails?.default_sales_channel_ids;

  const {
    authState: { everbeeUserId },
  } = useAuth();
  const { setLoadingSet, addToLoadingSet, removeFromLoadingSet } = useLoader();
  const scopes =
    "listings_r listings_w listings_d email_r shops_r shops_w transactions_r transactions_w profile_r address_r";
  //It contains only valid sales channels
  const sellerChannelsDetails = sellerSalesChannelsDetails?.filter(
    (channels) => channels.sales_channel_shop_name !== null
  );

  const handleChange = (event) => {
    const { value } = event.target;
    updateSelectedSalesChannels(value);
  };
  const updateHide = async (hide) => {
    try {
      addToLoadingSet("updateCurrency");
      const resp = await setHideShopName(hide);
      salesChannelsDispatch({
        type: "SET_USER_ACCOUNT_DETAILS",
        payload: {
          ...userAccountDetails,
          hide_details: resp.data.current_user.hide_details,
        },
      });
    } catch (error) {
    } finally {
      removeFromLoadingSet("updateCurrency");
    }
  };

  const getDisabled = (salesChannelId) => {
    if (
      selectedShops.length === 1 &&
      sellerChannelsDetails.find(
        (item) => item.sales_channel_id === salesChannelId
      )
    ) {
      return selectedShops.find((item) => item === salesChannelId);
    }
    return;
  };

  return (
    <>
      <FormControl className="shop-selector">
        <Typography
          sx={{
            outline: "none",
            color: "white",
            fontSize: "12px",
            marginTop: "-10px",
          }}
        >
          Select Shop
        </Typography>
        <Select
          value={selectedShops}
          onChange={handleChange}
          variant="outlined"
          renderValue={(selectedShops) =>
            !userAccountDetails?.hide_details
              ? selectedShops.map(
                  (item) =>
                    item &&
                    sellerChannelsDetails.find(
                      (value) => value.sales_channel_id == item
                    )?.sales_channel_shop_name
                )
              : "************"
          }
          MenuProps={MenuProps}
          autoWidth={false}
          sx={{
            height: "30px",
            width: "250px",
            borderRadius: "5px",
            border: "1.5px solid white",
            color: "white",
            fontSize: "14px",
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          }}
        >
          {salesChannelsDetails?.map(
            (salesChannelIds) =>
              salesChannelIds.sales_channel_shop_name && (
                <MenuItem
                  key={salesChannelIds.sales_channel_id}
                  // disabled={
                  //   salesChannelIds.sales_channel_id ===
                  //   getDisabled(salesChannelIds.sales_channel_id)
                  // }
                  value={salesChannelIds.sales_channel_id}
                  style={{
                    height: "45px",
                  }}
                >
                  <ListItemIcon>
                    <Radio
                      checked={
                        selectedShops?.indexOf(
                          salesChannelIds?.sales_channel_id
                        ) > -1
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      !userAccountDetails?.hide_details
                        ? salesChannelIds?.sales_channel_shop_name
                        : "*************"
                    }
                  />
                </MenuItem>
              )
          )}
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              cursor: "pointer",
              "&:hover": {
                color: "#99aac6",
              },
            }}
            onClick={() => {
              setLoadingSet("etsyConnect");

              var urlState = "";
              urlState = `${everbeeUserId}.${window.location.href}`;
              window.location.href = `https://www.etsy.com/oauth/connect?response_type=code&client_id=uarm508l32t4exk5sjengk1c&redirect_uri=${process.env.NEXT_PUBLIC_REDIRECT_URI}&scope=${scopes}&state=${urlState}&code_challenge=wq9up7xVG7ADNs_yHtTjH2Vm1lL0BU6pVhZpXBeXTpg
                    &code_challenge_method=S256`;
            }}
          >
            <AddCircle
              color="primary"
              fontSize="small"
              sx={{ marginLeft: "26px" }}
            />

            <Typography sx={{ margin: "-2px 0px 0px 10px" }}>
              Add New
            </Typography>
          </Box>
        </Select>
      </FormControl>
      <IconButton
        onClick={() => {
          updateHide(!userAccountDetails?.hide_details);
        }}
        sx={{ margin: "2px 0px 0px 2px" }}
      >
        {!userAccountDetails?.hide_details ? (
          <VisibilityOffRounded />
        ) : (
          <VisibilityRounded />
        )}
      </IconButton>
    </>
  );
};

export default ShopSelector;
