import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 1.5rem;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #19191a;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    img {
      width: 1.75rem;
      height: 1.75rem;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  .upgrade-failed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0.5rem;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #ffdcda;

    img {
      width: 3.75rem;
      height: 3.75rem;
    }
    span {
      color: #dc3c36;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.75rem;
    }
  }
  .plan-details {
    width: 100%;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
    // gap: 1.5rem;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 1px solid #e6e6e6;
    background: #f7f7f7;

    .details-left {
      display: flex;
      flex-direction: column;

      .heading {
        color: #19191a;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
      }
      .tagline {
        color: #19191a;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }

    .details-right {
      span {
        color: #19191a;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }
  }
  .stripe-btn {
    width: 100%;

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      gap: 0.25rem;
      align-self: stretch;
      border-radius: 0.5rem;
      background: #3d98f2;
      box-shadow: 0px 1px 1px 0px rgba(13, 97, 181, 0.4),
        0px 0px 0px 1px #2e8ae5;
      outline: none;
      border: none;
      color: #fff;
      font-size: 0.875rem;
      line-height: 1.25rem;
      cursor: pointer;
    }
  }
`;
