import React from "react";
import Image from "next/image";
import { Modal, Box } from "@mui/material";
import { StyledWrapper } from "./StyledWrapper";
import closeButton from "../../assets/svg/close-button.svg";
import dangerIcon from "../../assets/svg/danger.svg";
import { getStripeCheckoutLink } from "../../apis/stripe";
import { useLoader } from "../../contexts/LoaderContext";

const CardFailModal = ({ handleClose, openCardFailModal }) => {
  const { addToLoadingSet, removeFromLoadingSet } = useLoader();
  const handleClick = async () => {
    try {
      addToLoadingSet("handleClick");
      const res = await getStripeCheckoutLink(
        openCardFailModal[0].priceId,
        "https://everbee.io/congratulations/",
        window.location.href
      );
      if (openCardFailModal && res.data.checkout_url) {
        window.open(res.data.checkout_url, "_self");
        handleClose();
      }
    } catch (error) {
      removeFromLoadingSet("handleClick");
    }
  };

  return (
    <Modal
      disableAutoFocus
      open={openCardFailModal.length > 0}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        component="main"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30.5rem",
          backgroundColor: "#FFF",
          borderRadius: "1rem",
          boxShadow: "-2px 0px 8px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <StyledWrapper>
          <div className="header">
            <span>Upgrade Plan</span>
            <Image src={closeButton} alt="close button" onClick={handleClose} />
          </div>
          <div className="upgrade-failed">
            <Image src={dangerIcon} alt="Danger-icon" />
            <span>Upgrade failed</span>
          </div>
          <div className="plan-details">
            <div className="details-left">
              <span className="heading">
                {openCardFailModal && openCardFailModal[0]?.plan} plan
              </span>
              <span className="tagline">
                {openCardFailModal && openCardFailModal[0]?.subDesc.join(", ")}
              </span>
            </div>
            <div className="details-right">
              <span>
                ${openCardFailModal && openCardFailModal[0]?.price}/mo
              </span>
            </div>
          </div>
          <div className="stripe-btn">
            <button onClick={handleClick}>Pay with Stripe</button>
          </div>
        </StyledWrapper>
      </Box>
    </Modal>
  );
};

export default CardFailModal;
