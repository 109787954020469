import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Typography, Box } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function FaqList({ questionText, answerText }) {
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        sx={{
          backgroundColor: "background.secondary",
          padding: "14px",
          marginBottom: "4px",
          backgroundColor: "cardColor.main",
          color: "#14396E",
          height: "53px",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={onClick}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#14396E",
          }}
        >
          {questionText}
        </Typography>
        <Box sx={{}} className="logo-box" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ marginBottom: "4px", marginTop: "-4px" }}
      >
        {answerText.map((obj, index) => (
          <ListItemButton
            sx={{
              backgroundColor: "background.secondary",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "400",
                color: "#555555",
                lineHeight: "normal",
              }}
              dangerouslySetInnerHTML={{ __html: obj }}
            />
          </ListItemButton>
        ))}
      </Collapse>
    </>
  );
}
