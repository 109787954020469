import styled from "styled-components";

const StyledWrapper = styled.div`
  margin-top: 6px !important;
  .store-wrapper {
    position: relative;
    height: 4rem;
    width: 100%;
    padding-inline: ${({ isExpanded }) => (isExpanded ? "10px" : 0)};
    cursor: pointer;
    border-radius: 6px;
    background-color: ${({ isExpanded }) =>
      isExpanded ? "#F2F2F2" : "inherit"};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .store-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .store-container-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .store-avatar-img {
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
  }

  .store-name {
    color: #19191a;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .store-sales {
    color: #646566;
    font-weight: 400;
    font-size: 0.75rem;
  }

  // STORE SELECT MENU

  .store-select-menu {
    z-index: 2;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px 20px 25px -8px rgba(0, 0, 0, 0.06),
      0px 8px 10px -6px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }

  .sales-channels {
    height: 2.75rem;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 700;
    padding-left: 1rem;
    margin-bottom: 8px;
    color: gray800;
    border-bottom: 1px solid #ebebeb;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .store-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add-new-wrapper {
    height: 3.75rem;
    width: 100%;
    margin-top: 8px;
    border-top: 1px solid #ebebeb;

    display: flex;
    align-items: center;
    padding-left: 1rem;
  }

  .btn-add-new {
    height: 2rem;
    width: 6.25rem;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    background: transparent;
    cursor: pointer;
  }

  .btn-text {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .show-hide-wrapper {
    display: flex;
    align-items: center;
  }

  .text-wrapper {
    font-size: 0.7rem;
    font-weight: 700;
  }
  .add-store-container {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;

export default StyledWrapper;
