export default function dateRangeConstant(dateRange) {
  let timeRange;
  switch (dateRange) {
    case "Today":
      timeRange = "today";
      break;
    case "Yesterday":
      timeRange = "yesterday";
      break;
    case "Last 7 Days":
      timeRange = "last_7_days";
      break;
    case "Last 30 Days":
      timeRange = "last_30_days";
      break;
    case "This Month":
      timeRange = "this_month";
      break;
    case "This Year":
      timeRange = "this_year";
      break;
    case "Last Year":
      timeRange = "last_year";
      break;
    case "All Time":
      timeRange = "all_time";
      break;
    default:
      timeRange = "all_time";
      break;
  }
  return timeRange;
}
