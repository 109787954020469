import styled from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ isExpanded }) => (isExpanded ? "10px" : "27px")};
  .footer-pa-container {
    width: 100%;
    /* height: 6.125rem; */
    /* height: 4.125rem; */
    height: ${({ isExpanded }) => (isExpanded ? "3.4rem" : "2rem")};
    padding: 10px;
    box-shadow: ${({ isExpanded }) =>
      isExpanded ? "0px 1px 2px rgba(0, 0, 0, 0.1)" : null};
    border-radius: 6px;
  }

  .analytics-header {
    /* display: flex; */
    /* justify-content: space-between; */
    font-size: 0.875rem;
  }

  .analytics-progressbar {
    width: 100%;
    height: 0.375rem;
    margin-top: 0.5rem;
    background-color: #f2f2f2;
    border-radius: 100px;
  }

  .progress {
    height: 100%;
    background-color: #3d98f2;
    width: ${({ percent }) => `${percent > 100 ? 100 : percent}%`};
    border-radius: 100px;
    transition: width 1s ease-in-out;
  }

  .upgrade-plan {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    color: #3d98f2;
    font-size: 0.75rem;
    cursor: pointer;
  }

  .collapsed {
    margin-top: 2rem;
  }
`;

export default StyledWrapper;
