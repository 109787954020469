import { getCookie } from "../helper/cookies";

export const authReducer = (state, { type, payload }) => {
  const token = getCookie(`${process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME}`);
  switch (type) {
    case "AUTHENTICATION_SUCCESS": {
      return {
        ...state,
        everbeeName: payload.name,
        everbeeToken: token,
        everbeeEmail: payload.email,
        everbeeUserId: payload.id,
        navigateToEtsy: false,
        pricingPageCategory: payload?.pricing_page_category || "a",
        bogoOffer: payload?.bogo,
        planSubCategory: payload?.hobby_plan_sub_category || "",
        betaUser: payload?.is_beta || false,
        emailBeta: payload?.email_beta || false,
        limitForUser: payload?.limit_for_user || 10,
        productAnalyticsFeature:
          payload?.usage_details?.product_analytics_feature || 0,
        trialData: {
          trial_end_date: payload?.trial_end_date,
          trial_end_days_left: payload?.trial_end_days_left,
          trial_popup_to_show: payload?.trial_popup_to_show,
          trial_start_date: payload?.trial_start_date,
          trial_exp_category: payload?.trial_exp_category,
          on_free_trial: payload?.on_free_trial,
          total_trial_length: payload?.total_trial_length,
          on_free_trial: payload?.on_free_trial,
          trial_intro_popup: payload?.trial_intro_popup || false,
        },
        is_token_revoked: payload?.is_token_revoked,
        shop_name: payload?.shop_name,
        is_everbee_email_active: payload?.is_everbee_email_active,
        userImageUrl: payload?.image,
        productAnalyticsTableState: payload?.product_analytics_column_order,
        columnVisibilityModel: payload?.product_analytics_column_order?.columns
          ?.columnVisibilityModel || {
          whenMade: false,
          tagsUsed: false,
          autoRenews: false,
          isCustomizable: false,
          isPersonalizable: false,
          // descriptionCharacterCount: false,
          hasVariations: false,
          isSupply: false,
          minimumProcessing: false,
          placementOfListingInShop: false,
          shippedFrom: false,
          titleCharacter: false,
          whoMade: false,
          shopReviewRatio: false,
          listingTye: false,
          tags: false,
          shopDigitalListingCount: false,
          productLink: false,
          shopLink: false,
        },
        onboarding_flow: payload?.onboarding_flow,
        any_survey_answered_or_skipped_today:
          payload?.any_survey_answered_or_skipped_today || false,
        surveys_pending: payload?.surveys_pending || [],
        sidebar_onboarding_shown : payload?.sidebar_onboarding_shown,
        printBeta: payload?.is_print_beta,
        storeBeta: payload?.is_store_beta,
        upsell_test_group: payload?.upsell_group
      };
    }
    case "LOGOUT_USER": {
      return {
        ...state,
        everbeeName: payload.name,
        everbeeToken: token,
        everbeeEmail: payload.email,
        everbeeUserId: payload.id,
        navigateToEtsy: false,
      };
    }
    case "NAVIGATE_TO_ETSY": {
      return {
        ...state,
        navigateToEtsy: payload,
      };
    }
    case "TABLE_STATE_CHANGE": {
      return {
        ...state,
        productAnalyticsTableState: payload,
      };
    }
    case "TABLE_COLUMN_VISIBILITY": {
      return {
        ...state,
        columnVisibilityModel: payload,
      };
    }
    case "FEEDBACK_DONE": {
      return {
        ...state,
        any_survey_answered_or_skipped_today: payload,
      };
    }
    case "SIDEBAR_FLOW_DONE": {
      return {
        ...state,
        sidebar_onboarding_shown: payload,
      };
    }
    case "ASSIGN_UPSELL_TEST_GROUP": {
      return {
        ...state,
        upsell_test_group : payload,
      };
    }
  }
};
