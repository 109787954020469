import { onboardingInfoFlow } from "../apis/account";
import introJs from "intro.js";
//remove onboarding flow
export const removeInformationTooltip = async () => {
  try {
    const res = await onboardingInfoFlow();
  } catch (error) {}
};
export function startObjectsIntroTooltip(callback,  authDispatch) {
  var options = {
    hidePrev: true,
    showBullets: false,
    showBackButtons: false,
    exitOnEsc: false,
    exitOnOverlay: false,
    doneLabel: "Great",
    exitOnOverlayClick: false,
    steps: [
      {
        title: "EverBee Apps",
        element: document.querySelector(".product-selecter"),
        intro: "Looking for EverBee Email? Click here ✨",
        position: "bottom",
        tooltipClass: 'custom-highlight',
        highlightClass: 'custom-tooltip',
        buttonClass: 'my-button-class',
      },
    ],
  };
  var intro = introJs();
  intro.setOptions(options);
  intro.start();
  setTimeout(() => {
    const helperLayer = document.querySelector(".introjs-helperLayer");
    if (helperLayer) {
      helperLayer.addEventListener("click", function () {
        intro.exit();
        callback(); // Trigger the callback when the helper layer is clicked
      });
    }
    const startButton = document.querySelector(".introjs-donebutton");
    if (startButton) {
      startButton.addEventListener("click", callback);
    }
  }, 100);
  intro.onexit(function () {
    removeInformationTooltip();
    authDispatch({ type: 'SIDEBAR_FLOW_DONE', payload: true })
  });
}
