import { getMonitoredKeywordApi } from "../apis/tradeMarkMonitor";

export async function getMonitoredKeywordData(sortPattern) {
  try {
    const res = await getMonitoredKeywordApi(sortPattern);

    var rowDataArrays = [];
    const apiResult = res?.data?.keywords;
    const totalCount = res?.data.total_count;
    if (apiResult) {
      for (var i = 0; i < apiResult.length; i++) {
        if (apiResult[i] != null) {
          rowDataArrays.push(generateMonitoredKeyword(apiResult[i]));
        }
      }
      return {
        rowDataArrays: rowDataArrays,
        totalCount: totalCount,
      };
    }
  } catch (err) {
    console.log("error", err);
  }
}

export function generateMonitoredKeyword(data) {
  const rn = Math.floor(Math.random() * 999787898894);
  const rowDataTable = {
    id: rn,
    select: false,
    keywordId: data["id"],
    freq: data["trademark_count"],
    trademark: data["keyword"],
    listings: data["listing_count"],
    snoozeStatus: data.snoozed,
  };
  return rowDataTable;
}
