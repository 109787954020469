import React, { useState } from "react";
import {
  Modal,
  Box,
  Container,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import Timer from "../../assets/svg/timer.svg";
import Image from "next/image";
import CheckoutModal from "../pricing/CheckoutModal";
import { TrialPricing } from "../pricing/ContainerConstants";
import { Close } from "@mui/icons-material";
import ThreeDaysMainWrapper from "./DaysLeftWrapper";
import { useLoader } from "../../contexts/LoaderContext";
import { StayOnFreePlan } from "../../apis/account";
import { RemindMeLater } from "../../apis/account";
import RadioButton from "../RadioButton/RadioButtom";

export const ThreeDaysModal = ({
  open,
  initialReminder,
  onClose,
  reverseReminder,
  trialEndedFree,
  remainingDay,
  getCurrentUser,
}) => {
  const { addToLoadingSet, removeFromLoadingSet } = useLoader();
  const [openPricingModal, setOpenPricingModal] = useState(false);
  const [monthlyChecked, setMonthlyChecked] = useState(false);
  const [annuallyChecked, setAnnuallyChecked] = useState(true);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "350px", sm: "500px", md: "500px" },
    borderRadius: "1rem",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const ThreeDaysReminder = [
    {
      value: "Product Analytics:",
      des: "Sales & revenue estimates, tags + more",
    },
    {
      value: "Shop Analyzer:",
      des: "Shop age, review count + more",
    },
    {
      value: "Keyword Research:",
      des: "Popular search terms, volumes, scores + more ",
    },
    {
      value: "Listing Rank Tracker:",
      des: "Track a listing's ranking on Etsy",
    },
    {
      value: "Trademark Monitor:",
      des: "Scans your listings for any trademarked infringements",
    },
    {
      value: "EverBee Email:",
      des: "Boost your sales and reviews on autopilot!",
    },
  ];

  const FinalReminder = [
    {
      value: "Product Analytics:",
      des: "Sales & revenue estimates, tags + more",
    },
    {
      value: "Shop Analyzer:",
      des: "Shop age, review count + more",
    },
    {
      value: "Keyword Research:",
      des: "Popular search terms, volumes, scores + more ",
    },
    {
      value: "Listing Rank Tracker:",
      des: "Track a listing's ranking on Etsy",
    },
    {
      value: "Trademark Monitor:",
      des: "Scans your listings for any trademarked infringements",
    },
    {
      value: "EverBee Email:",
      des: "Boost your sales and reviews on autopilot!",
    },
  ];

  const handleAnnuallyChange = () => {
    setAnnuallyChecked(true);
    setMonthlyChecked(false);
  };

  const handleMonthlyChange = () => {
    setMonthlyChecked(true);
    setAnnuallyChecked(false);
  };

  const updateStayOnFreePlan = async () => {
    try {
      addToLoadingSet("stayOnFreePlan");
      const res = await StayOnFreePlan();
      //   await getCurrentUser();
      onClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      removeFromLoadingSet("stayOnFreePlan");
    }
  };

  const updateRemindMeLater = async () => {
    try {
      addToLoadingSet("remindMeLater");
      const data = await RemindMeLater();
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      removeFromLoadingSet("remindMeLater");
    }
  };

  return (
    <>
      <Modal
        disableAutoFocus
        open={open}
        onClose={(initialReminder || reverseReminder) && onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThreeDaysMainWrapper>
          {(initialReminder || reverseReminder) && (
            <div className="close-wrapper">
              <Close
                onClick={onClose}
                sx={{
                  fontSize: { xs: "14px", md: "20px" },
                  color: "#646566",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
          <div className="trial-ending">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Image src={Timer} alt="timer-icon" />
              {initialReminder ? (
                <Typography
                  fontWeight="500"
                  color="#19191a"
                  fontSize="18px"
                  lineHeight="24px"
                >
                  {remainingDay === 1
                    ? `Your trial ends in ${remainingDay} day`
                    : `Your trial ends in ${remainingDay} days`}
                </Typography>
              ) : (
                <Typography
                  fontWeight="500"
                  color="#19191a"
                  fontSize="18px"
                  lineHeight="24px"
                >
                  Your trial has ended
                </Typography>
              )}
            </Box>
          </div>
          <div className="border-wrapper"></div>
          <Box sx={{ display: "flex", padding: "10px" }}>
            {initialReminder ? (
              <Typography color="#032954" fontWeight="400" fontSize="14px">
                Enjoying Everbee?{" "}
                <span style={{ color: "#19191A", fontWeight: "500" }}>
                  {remainingDay === 1
                    ? `Your free trial will end in ${remainingDay} day.`
                    : `Your free trial will end in ${remainingDay} days.`}
                </span>{" "}
                To keep using:
              </Typography>
            ) : (
              <Typography color="#032954" fontWeight="400" fontSize="14px">
                Enjoying Everbee?{" "}
                <span style={{ color: "#19191A", fontWeight: "500" }}>
                  Your free trial has ended.
                </span>{" "}
                To keep using:
              </Typography>
            )}
          </Box>
          <Box sx={{ padding: "10px 10px 10px 30px" }}>
            {initialReminder
              ? ThreeDaysReminder.map((item) => (
                  <>
                    <Box sx={{ display: "flex" }}>
                      <ul>
                        <li>
                          <Typography
                            component="span"
                            fontWeight="700"
                            color="#19191a"
                            fontSize="14px"
                            lineHeight="24px"
                          >
                            {item.value}
                          </Typography>{" "}
                          <Typography
                            component="span"
                            fontWeight="500"
                            color="#032954"
                            fontSize="14px"
                            lineHeight="24px"
                          >
                            {item.des}
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                  </>
                ))
              : FinalReminder.map((item) => (
                  <>
                    <Box sx={{ display: "flex" }}>
                      <ul>
                        <li>
                          <Typography
                            component="span"
                            fontWeight="700"
                            color="#19191a"
                            fontSize="14px"
                            lineHeight="24px"
                          >
                            {item.value}
                          </Typography>{" "}
                          <Typography
                            component="span"
                            fontWeight="500"
                            color="#032954"
                            fontSize="14px"
                            lineHeight="24px"
                          >
                            {item.des}
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                  </>
                ))}
          </Box>
          <Box sx={{ padding: "10px" }}>
            <Typography>Switch To Growth Plan</Typography>
          </Box>
          <div className="plan-wrapper">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                border: annuallyChecked
                  ? "1px solid #2E8AE5"
                  : "1px solid #E6E6E6",
                borderRadius: "8px",
                width: "47%",
                height: "75px",
                cursor: "pointer",
              }}
              onClick={handleAnnuallyChange}
            >
              <div
                style={{
                  padding: "5px",
                  marginBottom: "22px",
                  marginLeft: "28px",
                }}
              >
                <RadioButton checked={annuallyChecked} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8px",
                }}
              >
                <Typography fontSize="14px" lineHeight="18.9px" color="#313233">
                  Pay Annually
                </Typography>
                <Typography
                  fontWeight="700"
                  color="#313233"
                  fontSize="14px"
                  lineHeight="14px"
                >
                  $19.99 per month
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                border: monthlyChecked
                  ? "1px solid #2E8AE5"
                  : "1px solid #E6E6E6",
                borderRadius: "8px",
                width: "47%",
                height: "75px",
                cursor: "pointer",
              }}
              onClick={handleMonthlyChange}
            >
              <div
                style={{
                  padding: "5px",
                  marginBottom: "22px",
                  marginLeft: "28px",
                }}
              >
                <RadioButton checked={monthlyChecked} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "8px",
                }}
              >
                <Typography color="#313233" fontSize="14px" lineHeight="18.9px">
                  Pay Monthly
                </Typography>
                <Typography
                  fontWeight="700"
                  fontSize="14px"
                  lineHeight="14px"
                  color="#313233"
                >
                  $29.99 per month
                </Typography>
              </Box>
            </Box>
          </div>

          <div className="border-wrapper"></div>

          <div className="button-wrapper">
            {initialReminder ? (
              <>
                <Button
                  sx={{
                    "&.MuiButton-root": {
                      color: "#313233",
                      border: "1px solid #EBEBEB",
                    },
                  }}
                  variant="outlined"
                  onClick={() => updateRemindMeLater()}
                >
                  Remind Me Later
                </Button>

                <Button
                  sx={{
                    "&.MuiButton-root": {
                      color: "#FFFFFF",
                    },
                  }}
                  variant="contained"
                  onClick={() => setOpenPricingModal(true)}
                >
                  Upgrade
                </Button>
              </>
            ) : trialEndedFree ? (
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: "#FFFFFF",
                  },
                }}
                variant="contained"
                onClick={() => setOpenPricingModal(true)}
              >
                Upgrade
              </Button>
            ) : (
              <>
                <Button
                  sx={{
                    "&.MuiButton-root": {
                      color: "#313233",
                      border: "1px solid #EBEBEB",
                    },
                  }}
                  variant="outlined"
                  onClick={() => updateStayOnFreePlan()}
                >
                  Downgrade to Hobby Plan
                </Button>
                <Button
                  sx={{
                    "&.MuiButton-root": {
                      color: "#FFFFFF",
                    },
                  }}
                  variant="contained"
                  onClick={() => setOpenPricingModal(true)}
                >
                  Upgrade
                </Button>
              </>
            )}
          </div>
          {!initialReminder && (
            <Box sx={{ display: "flex", gap: "3px", justifyContent: "center" }}>
              <Typography
                fontWeight="400"
                color="#19191a"
                fontSize="12px"
                lineHeight="16px"
              >
                If you are not interested in upgrading, please
              </Typography>
              <Typography
                fontWeight="400"
                color="#1F76CC"
                fontSize="12px"
                lineHeight="16px"
                onClick={() =>
                  window.open("https://forms.gle/N4EejFb1szZeDFjD7", "_blank")
                }
                sx={{ cursor: "pointer" }}
              >
                tell us why
              </Typography>
            </Box>
          )}
        </ThreeDaysMainWrapper>
      </Modal>
      <Modal
        disableAutoFocus
        open={openPricingModal}
        onClose={() => setOpenPricingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CheckoutModal
            details={
              monthlyChecked === true ? TrialPricing[0] : TrialPricing[1]
            }
            priceId={
              monthlyChecked === true
                ? TrialPricing[0].priceId
                : TrialPricing[1].priceId
            }
            offer={false}
          />
        </Box>
      </Modal>
    </>
  );
};
