import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

export default function FormComponent({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "800px" },
    display: "flex",
    flexDirection: "row",
  };
  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSf2Sen1XgEmWmWUZ7EYpPSudGuquE5OiUbg-PbRPzskYHOVgQ/viewform"
          width="800"
          height="650"
        ></iframe>
        <Button
          variant="text"
          onClick={handleClose}
          color="primary"
          style={{
            color: "white",
            height: "50px",
            fontSize: "40px",
            width: "20px",
          }}
        >
          &#x2717;
        </Button>
      </Box>
    </Modal>
  );
}
