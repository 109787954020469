import React from "react";
import styled from "styled-components";

const RadioButton = ({ checked, onClick }) => {
  return (
    <RadioContainer>
      <RadioInput
        type="radio"
        // onClick={(e) => e.stopPropagation()}
        checked={checked}
        onChange={onClick}
      />
      <RadioCustom />
    </RadioContainer>
  );
};

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RadioInput = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
`;

const RadioCustom = styled.span`
  position: relative;
  display: inline-block;
  width: 14.25px;
  height: 14px;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: all 0.3s ease;

  ${RadioInput}:checked + & {
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2e8ae5;
  }

  ${RadioInput}:checked + &::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 6.25px;
    height: 6px;
    background-color: #2e8ae5;
    border-radius: 50%;
    /* transform: rotate(45deg); */
    /* margin-left: 5px;
    margin-top: 3px; */
  }
`;

export default RadioButton;
