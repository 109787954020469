import styled from "styled-components";

const UserWrapper = styled.div`
  .store-select-menu {
    z-index: 2;
    position: absolute;
    bottom: 72px;
    left: 10px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 20px 25px -8px rgba(0, 0, 0, 0.06),
      0px 8px 10px -6px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 12px 0px 12px 0px;
  }

  .user-menu-options {
    display: flex;
    padding: 5px;
    justify-content: space-around;
    cursor: pointer;
  }

  .log-out-menu {
    padding: 8px 12px 8px 12px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: flex-start;
    height: 40px;
    width: 180px;
    margin-left: 24px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 6px;
  }

  .visit-icon {
    margin-left: 14px;
  }

  .help-icon-user {
    margin-left: 14px;
  }

  .menu-item {
    margin-right: 47px;
  }

  .menu-item-help {
    margin-right: 84px;
  }

  /* .log-icon {
    margin-left: 33px;
  } */

  .log-item {
    margin-left: 8px;
  }

  .border-divider {
    border: 1px solid #ebebeb;
    width: 100%;
    margin-top: 12px;
  }
`;

export default UserWrapper;
