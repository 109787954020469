import React, { useEffect, useRef, useState } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { makeStyles, withStyles } from "@mui/styles";
import MuiListItem from "@material-ui/core/ListItem";
import {
  AppBar,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  ClickAwayListener,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DashboardRounded,
  Inventory2Rounded,
  Language,
  Help,
  Storefront,
  FindInPage,
  AddCircle,
  Menu,
  ExpandLess,
  ExpandMore,
  MonetizationOn,
  Search,
  StarBorderRounded,
  TrendingUpRounded,
  LocalShippingRounded,
  StorageRounded,
  CalendarToday,
  CalculateRounded,
  StoreOutlined,
  QueryStats,
  WarningRounded,
} from "@mui/icons-material";
import FormComponent from "./FormComponent";
import HeaderDropdown from "./dashboardComponents/HeaderDropdown";
import { useSalesChannel } from "../contexts/SalesChannelContext";
import { useAuth } from "../contexts/AuthContext";
import { currencies } from "./dashboardComponents/utils/currencies";
import { setDefaultCurrency } from "../apis/dashboard";
import CalendarDropdown from "./dashboardComponents/CalendarDropdown";
import { useLoader } from "../contexts/LoaderContext";
import { PlanViewSideBar } from "./PlanViewSidebar";
import { useTradeMarkMonitor } from "../contexts/TradeMarkMonitorContext";
import Sidebar from "./SidebarN/Sidebar";

const useStyles = makeStyles(
  (theme) => ({
    menuPaper: {
      marginTop: "35px",
    },
    drawerStyles: {
      height: "100%",
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        height: "100%",
        border: "none",
        overflow: "auto",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        height: "100%",
        display: "block",
      },
    },
    appBarWrapper: {
      display: "none",
      zIndex: "5",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.between("sm", "md")]: {
        display: "none",
      },
    },
  }),
  {
    name: "MuiCustomStyles",
  }
);

const ListItem = withStyles(
  (theme) => ({
    root: {
      paddingTop: "0",
      paddingBottom: "0",
      display: "inline-block",
      "&": {
        "& .MuiListItemButton-root": {
          padding: "6px",
        },
        "& .MuiListItemText-primary": {
          fontSize: "14px",
        },
      },
      "&:hover": {
        "& .MuiListItemIcon-root": {},
      },
      "&$selected": {
        color: "#2178DA",
        backgroundColor: "tranparent",
        borderLeft: "6px solid #1E6CC4 ",
        "& .MuiListItemIcon-root": {
          color: "#1E6CC4",
        },
      },
    },
    selected: {},
  }),
  {
    name: "MuiCustomStyle",
  }
)(MuiListItem);

const AppBarComponent = ({ isExpanded, setIsExpanded }) => {
  const {
    authState: { everbeeName },
  } = useAuth();

  const {
    salesChannelsState: {
      selectedShops,
      timeRangeSelector,
      userAccountDetails,
      salesChannelsDetails,
    },
    updateSelectedSalesChannels,
  } = useSalesChannel();
  const {
    tradeMarkMonitorDispatch,
    tradeMarkMonitorState: { trademarkIndicatorCount },
  } = useTradeMarkMonitor();
  const { loadingSet, setLoadingSet, addToLoadingSet, removeFromLoadingSet } =
    useLoader();
  const classes = useStyles();

  const router = useRouter();
  const { tab } = router.query;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expandSalesChannels, setExpandSalesChannels] = useState(false);
  const [expandResearchTools, setExpandResearchTools] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const calAnchorRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const updateCurrency = async (currentcode) => {
    try {
      addToLoadingSet("updateCurrency");
      const resp = await setDefaultCurrency(currentcode);
      setCurrentCurrency(currentcode);
      router.reload(window.location.pathname);
    } catch (error) {
    } finally {
      removeFromLoadingSet("updateCurrency");
    }
  };
  useEffect(() => {
    if (userAccountDetails?.default_currency_code) {
      setCurrentCurrency(userAccountDetails.default_currency_code);
      tradeMarkMonitorDispatch({
        type: "UPDATE_SIDEBAR_MENU_INDICATOR",
        payload: parseInt(localStorage.getItem("everbeeFlaggedCount")),
      });
    }
  }, [userAccountDetails?.default_currency_code]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDrawerToggle = () => {
    setIsExpanded(true);
    setMobileOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (
      router.pathname === "/favorites" ||
      router.pathname === "/productAnalytics"
    ) {
      setExpandResearchTools(true);
    }
  }, [router.asPath]);

  return (
    <Box className={classes.appBarWrapper}>
      <AppBar
        position="static"
        className={classes.appBar}
        sx={{
          "&.MuiPaper-root": {
            backgroundColor: "background.secondary",
          },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <Menu fontSize="large" sx={{ color: "primary.main" }} />
          </IconButton>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
              gap: "15px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }} className="time-range">
              <Box
                sx={{
                  height: "32px",
                  backgroundColor: "background.default",
                  position: "relative",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                ref={calAnchorRef}
              >
                <Badge variant="dot" color="error">
                  <div style={{ position: "relative" }}>
                    <IconButton
                      sx={{
                        outline: "1px solid white",
                        borderRadius: "4px",
                        height: "32px",
                        width: "32px",
                      }}
                      onClick={() => setShowCalendar((prevOpen) => !prevOpen)}
                    >
                      <CalendarToday />
                    </IconButton>
                    {showCalendar && (
                      <ClickAwayListener
                        onClickAway={() => setShowCalendar(false)}
                      >
                        <CalendarDropdown
                          open={showCalendar}
                          setOpen={setShowCalendar}
                          anchorRef={calAnchorRef}
                        />
                      </ClickAwayListener>
                    )}
                  </div>
                </Badge>
              </Box>
              <FormControl variant="standard">
                <Select
                  sx={{
                    border: "none",
                  }}
                  onChange={(e) => updateCurrency(e.target.value)}
                  value={currentCurrency}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  {currencies.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Avatar
              sx={{ textTransform: "capitalize" }}
              ref={anchorRef}
              onClick={handleToggle}
            >
              {everbeeName && everbeeName[0]}
            </Avatar>
            <HeaderDropdown
              open={open}
              setOpen={setOpen}
              anchorRef={anchorRef}
            />
          </div>
        </Toolbar>
      </AppBar>
      <FormComponent open={openForm} setOpen={setOpenForm} />
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "background.secondary",
              width: "70%",
              maxWidth: "260px",
            },
          }}
        >
          <Sidebar
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            hideCollapse={true}
          />
        </Drawer>
      </Box>
    </Box>
  );
};

export default AppBarComponent;
