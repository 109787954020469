import React from "react";
import { SwipeableDrawer } from "@mui/material";
import LearningModule from "./LearningModule";
import { useLoader } from "../../contexts/LoaderContext";

const LearningModulePane = () => {
  const { openLearn, setOpenLearn } = useLoader();
  const handleClose = () => {
    setOpenLearn(false);
  };
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={openLearn}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "522px",
          overflowX: "hidden",
        },
      }}
      sx={{ zIndex: "999999999999999" }}
    >
      <LearningModule setOpenLearningModule={setOpenLearn} />
    </SwipeableDrawer>
  );
};

export default LearningModulePane;
