import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { createTheme } from "@mui/material/styles";
import { getDesingnPattern } from "../theme/index";

const ColorModeContext = createContext();
const getThemeFromLocalStorage = () => {
  let theme;
  if (typeof window !== "undefined") {
    theme = localStorage.getItem("theme");
  }
  return theme;
};
const themeMode = getThemeFromLocalStorage()
  ? getThemeFromLocalStorage()
  : "light";

const ColorModeProvider = ({ children }) => {
  const [mode, setMode] = useState(themeMode);
  const theme = useMemo(() => createTheme(getDesingnPattern(mode)), [mode]);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);
  return (
    <ColorModeContext.Provider value={{ colorMode, mode, setMode, theme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {children}
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const useTheme = () => useContext(ColorModeContext);

export { ColorModeProvider, useTheme };
