import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import Image from "next/image";
import { Close } from "@mui/icons-material";
import IntroductoryWrapper from "./IntroductoryWrapper";
import Joy from "../../assets/svg/joy.svg";
import { useAuth } from "../../contexts/AuthContext";
import { Avatar } from "@mui/material";
import codyImage from "../../assets/images/cody.png"

export const IntroductoryModal = ({ open, onClose }) => {
    const {
        authState: { trialData },
    } = useAuth();
    const ThreeDaysReminder = [
        {
            value: "Product Analytics:",
            des: "sales & revenue estimates, tags + more",
        },
        {
            value: "Shop Analyzer:",
            des: "Shop age, review count + more",
        },
        {
            value: "Keyword Research:",
            des: "Popular search terms, volumes, scores + more ",
        },
        {
            value: "Listing Rank Tracker:",
            des: "Track a listing's ranking on Etsy",
        },
        {
            value: "Trademark Monitor:",
            des: "Scans your listings for any trademarked infringements",
        },
        {
            value: "Everbee Email:",
            des: "Boost your sales and reviews on autopilot!",
        },
    ];

    return (
        <>
            <Modal
                disableAutoFocus
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <IntroductoryWrapper>

                    <div className="close-wrapper">
                        <Close
                            onClick={onClose}
                            sx={{
                                fontSize: { xs: "14px", md: "20px" },
                                color: "#646566",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '-37px',
                            left: '280px',
                            width: "70px",
                            height: "70px",
                            borderRadius: '50%',
                            overflow: 'hidden'
                        }}

                    >
                        <Image src={codyImage} />
                    </Box>
                    <div className="trial-ending">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <div>
                                <Image src={Joy} alt="timer-icon" />
                            </div>
                            <div>
                                <Typography
                                    fontWeight="500"
                                    color="#19191a"
                                    fontSize="22px"
                                    lineHeight="24px"
                                >
                                    Welcome To Everbee!
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    fontWeight="500"
                                    color="#19191a"
                                    fontSize="18px"
                                    lineHeight="24px"
                                >
                                    &#128640;
                                </Typography>
                            </div>
                        </Box>
                    </div>
                    {/* <div className="border-wrapper"></div> */}
                    <Box sx={{ display: "flex", padding: "10px" }}>
                        <Typography color="#032954" fontWeight="400" fontSize="16px">
                            We're excited to have you on board for your Free Trial! Over the
                            next{" "}
                            <span style={{ color: "#19191A", fontWeight: "500" }}>
                                {trialData?.total_trial_length} days,
                            </span>{" "}
                            you'll have full access to all the powerful tools:
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "10px 10px 10px 30px" }}>
                        {ThreeDaysReminder.map((item) => (
                            <>
                                <Box sx={{ display: "flex" }}>
                                    <ul>
                                        <li>
                                            <Typography
                                                component="span"
                                                fontWeight="700"
                                                color="#19191a"
                                                fontSize="14px"
                                                lineHeight="24px"
                                            >
                                                {item.value}
                                            </Typography>{" "}
                                            <Typography
                                                component="span"
                                                fontWeight="500"
                                                color="#032954"
                                                fontSize="14px"
                                                lineHeight="24px"
                                            >
                                                {item.des}
                                            </Typography>
                                        </li>
                                    </ul>
                                </Box>
                            </>
                        ))}
                    </Box>
                    <Box sx={{ padding: "8px" }}>
                        <Typography>
                            Make every day of your trial count and take a big step towards
                            Etsy success! &#127775;{" "}
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "8px" }}>
                        <Typography>
                            Learn how to find the best products on Etsy...{" "}
                        </Typography>
                    </Box>
                    <div className="button-wrapper">
                        <Button
                            sx={{
                                "&.MuiButton-root": {
                                    color: "#FFFFFF",
                                },
                            }}
                            variant="contained"
                            onClick={onClose}
                        >
                            Get Started
                        </Button>
                    </div>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "3px",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            fontWeight="400"
                            color="#19191a"
                            fontSize="12px"
                            lineHeight="16px"
                        >
                            Do you have any questions or need assistance, we are just{" "}
                        </Typography>
                        <Typography
                            fontWeight="400"
                            color="#1F76CC"
                            fontSize="12px"
                            lineHeight="16px"
                            onClick={() => gist.chat("open")}
                            sx={{ cursor: "pointer", textDecoration: "underline" }}
                        >
                            a click away
                        </Typography>
                    </Box>
                </IntroductoryWrapper>
            </Modal>
        </>
    );
};
