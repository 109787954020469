import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

const MaintenancePage = () => {
  return (
    <StyledWrapper>
      <p>
        EverBee is currently under maintenance. We will be back up and running
        soon.
      </p>
    </StyledWrapper>
  );
};

export default MaintenancePage;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw; /* Ensure the wrapper takes full width of the viewport */
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* Include padding in the element's total width and height */

  p {
    font-size: 25px;
    margin: 0;
  }
`;
