import { createContext, useContext, useState } from "react";
const DashboardContext = createContext();
const DashboardProvider = ({ children }) => {
  //gross_revenue average_order_value total_orders ltv
  const [option, setOption] = useState("gross_revenue");

  return (
    <DashboardContext.Provider
      value={{
        option,
        setOption,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
const useDashboard = () => useContext(DashboardContext);
export { DashboardProvider, useDashboard };
