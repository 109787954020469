import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Avatar, ClickAwayListener, IconButton, Box } from "@mui/material";
import UserWrapper from "./UserWrapper";
import VisitIcon from "../../assets/svg/visit.svg";
import HelpIcon from "../../assets/svg/help.svg";
import Image from "next/image";
import LogOutIcon from "../../assets/svg/out.svg";
import { useAuth } from "../../contexts/AuthContext";
import { useExtension } from "../../contexts/ExtensionContext";
import { useRouter } from "next/router";
import amplitude from "amplitude-js";
import { googleAnalyticsEvent } from "../../apis/account";

export const UserCardInfo = ({ isOpen, anchorEl, onClose, children }) => {
  const popoverRef = useRef();
  const {
    authState: { everbeeName, everbeeEmail, everbeeUserId },
    logoutUser,
  } = useAuth();
  const { extInstalled } = useExtension();
  const router = useRouter();

  const goToEtsy = () => {
    if (extInstalled) {
      router.push(`https://www.etsy.com`);
    } else {
      amplitude.getInstance().logEvent("Install Extension Modal");
      googleAnalyticsEvent({
        eventName: "InstallExtensionModal",
        eventPrams: {},
      });
      router.push("https://everbee.io/install/");
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <UserWrapper>
        <motion.div
          className="popover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.2 } }}
          ref={popoverRef}
        >
          <div className="store-select-menu">
            <div className="user-menu-options" onClick={goToEtsy}>
              <div className="visit-icon">
                <Image src={VisitIcon} alt="visit-icon" />
              </div>
              <div className="menu-item">
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="#19191A"
                  lineHeight="20px"
                  textTransform="initial"
                >
                  Visit chrome app
                </Typography>
              </div>
            </div>
            <div
              className="user-menu-options"
              onClick={() => {
                gist.chat("open");
              }}
            >
              <div className="help-icon-user">
                <Image src={HelpIcon} alt="help-icon" />
              </div>
              <div className="menu-item-help">
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="#19191A"
                  lineHeight="20px"
                  textTransform="initial"
                >
                  Help Center
                </Typography>
              </div>
            </div>
            <div className="border-divider"></div>
            <div className="log-out-menu" onClick={logoutUser}>
              <div className="log-icon">
                <Image src={LogOutIcon} alt="logout-icon" />
              </div>
              <div className="log-item">
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="#19191A"
                  lineHeight="20px"
                  textTransform="initial"
                >
                  Log out
                </Typography>
              </div>
            </div>
          </div>
        </motion.div>
      </UserWrapper>
    </ClickAwayListener>
  );
};
