import React from "react";
import { useLoading, Bars } from "@agney/react-loading";
import Backdrop from "@mui/material/Backdrop";
import { Typography, Box } from "@mui/material";

export default function Loader({ isLoading }) {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: (
      <Box
        component="img"
        src="/loadingT.gif"
        alt="loader"
        sx={{ width: "200px" }}
      />
    ),
  });
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999999 }}
        open={isLoading}
      >
        <section
          {...containerProps}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {indicatorEl}
        </section>
      </Backdrop>
    </>
  );
}
