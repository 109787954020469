import React, { useEffect, useState } from "react";
import { ThreeDaysModal } from "./Trial/ThreeDaysLeftModal";
import { TrialReminder } from "./Trial/TrialReminder";
import { useAuth } from "../contexts/AuthContext";
import { useRouter } from "next/router";
import amplitude from "amplitude-js";
import { IntroductoryModal } from "./Trial/IntroductoryPopup";
import { closeTrialIntoModal } from "../apis/account";
import { useLoader } from "../contexts/LoaderContext";
export const TrialPopUpDisplay = ({ getCurrentUser }) => {
  const {
    authState: { trialData },
  } = useAuth();
  const router = useRouter();
  const { addToLoadingSet, removeFromLoadingSet } = useLoader();
  const [modal, setModal] = useState(true);
  const [openIntroModal, setOpenIntroModal] = useState(true);

  const onCloseIntroModal = async () => {
    try {
      addToLoadingSet("onCloseIntroModal");
      const res = await closeTrialIntoModal();
      setOpenIntroModal(false);
    } catch (error) {
    } finally {
      removeFromLoadingSet("onCloseIntroModal");
    }
  };

  const onClose = () => {
    setModal(false);
  };

  useEffect(() => {
    if (router.pathname != "/pricing" && modal == true) {
      if (trialData?.trial_popup_to_show?.toString()?.includes("trial_ends")) {
        amplitude.getInstance().logEvent("3 Days Left Pop Up");
      } else if (
        trialData?.trial_popup_to_show?.toString()?.includes("trial_ended") &&
        trialData?.trial_exp_category?.toString()?.includes("free_trial")
      ) {
        amplitude.getInstance().logEvent("End of Trial Pop Up");
      } else if (
        trialData?.trial_popup_to_show?.toString()?.includes("trial_ended") &&
        trialData?.trial_exp_category?.toString()?.includes("reverse_trial")
      ) {
        amplitude.getInstance().logEvent("End of Trial Pop Up");
      } else if (
        trialData?.trial_popup_to_show
          ?.toString()
          ?.includes("trial_extension_popup") &&
        trialData?.trial_exp_category?.toString()?.includes("free_trial")
      ) {
        amplitude.getInstance().logEvent("Trial Extension Pop Up");
      } else if (
        trialData?.trial_popup_to_show
          ?.toString()
          ?.includes("trial_extension_popup") &&
        trialData?.trial_exp_category?.toString()?.includes("reverse_trial")
      ) {
        amplitude.getInstance().logEvent("Trial Extension Pop Up");
      }
    }
  }, [modal]);

  if (router.pathname != "/pricing") {
    if (
      trialData?.trial_popup_to_show?.toString()?.includes("trial_intro_popup")
    ) {
      return (
        <IntroductoryModal open={openIntroModal} onClose={onCloseIntroModal} />
      );
    } else if (
      trialData?.trial_popup_to_show?.toString()?.includes("trial_ends")
    ) {
      return (
        <ThreeDaysModal
          open={modal}
          initialReminder={true}
          onClose={onClose}
          remainingDay={trialData.trial_end_days_left}
          getCurrentUser={getCurrentUser}
        />
      );
    } else if (
      trialData?.trial_popup_to_show?.toString()?.includes("trial_ended") &&
      trialData?.trial_exp_category?.toString()?.includes("free_trial")
    ) {
      return (
        <ThreeDaysModal
          open={modal}
          onClose={onClose}
          trialEndedFree={true}
          remainingDay={trialData.trial_end_days_left}
          getCurrentUser={getCurrentUser}
        />
      );
    } else if (
      trialData?.trial_popup_to_show?.toString()?.includes("trial_ended") &&
      trialData?.trial_exp_category?.toString()?.includes("reverse_trial")
    ) {
      return (
        <ThreeDaysModal
          open={modal}
          onClose={onClose}
          reverseReminder={true}
          remainingDay={trialData.trial_end_days_left}
          getCurrentUser={getCurrentUser}
        />
      );
    } else if (
      trialData?.trial_popup_to_show
        ?.toString()
        ?.includes("trial_extension_popup") &&
      trialData?.trial_exp_category?.toString()?.includes("free_trial")
    ) {
      return (
        <TrialReminder
          open={modal}
          onClose={onClose}
          getCurrentUser={getCurrentUser}
        />
      );
    } else if (
      trialData?.trial_popup_to_show
        ?.toString()
        ?.includes("trial_extension_popup") &&
      trialData?.trial_exp_category?.toString()?.includes("reverse_trial")
    ) {
      return (
        <TrialReminder
          reverseReminder={true}
          open={modal}
          onClose={onClose}
          getCurrentUser={getCurrentUser}
        />
      );
    } else return <></>;
  } else return <></>;
};
