import styled from "styled-components";

const PlanSuccessWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 280px;
  background-color: white;
  padding: 18px 20px 24px 20px;
  flex-grow: 1;
  border-radius: 15px;
  border: 5px solid #eaeaea;

  .close-wrapper {
    background: #f7f7f7;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .border-wrapper {
    border: 1px solid #ebebeb;
    margin-right: 10px;
  }

  .trial-extended {
    display: flex;
    padding: 16px;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
  }
`;

export default PlanSuccessWrapper;
