import styled from "styled-components";

const StyledWrapper = styled.ul`
  margin-left: 2.15rem;
  margin-top: 0.5rem;
  cursor: pointer;
  .sub-menu-item {
    font-size: 14px;
    color: #646566;
    /* border: 1px solid red; */
    line-height: 25px;
    list-style-type: none;
  }

  .active-sub-menu {
    color: #19191a;
    /* color: red; */
  }

  & li {
    position: relative;
  }

  .tree-box-left-only {
    position: absolute;
    bottom: 20px;
    left: -19.5px;
    width: 10.5px;
    height: 1.35em;
    border-left: 1px solid #e6e6e6;
  }

  .active-tree-left-only {
    border-left: 1px solid #b1b1b2;
  }

  .active-tree-left-only-height {
    height: 1.25em;
  }

  & li:first-child .tree-box-left-only {
    display: none;
  }

  .tree-box-left-bottom {
    position: absolute;
    top: 0px;
    left: -19.5px;
    width: 10.5px;
    height: 1em;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-bottom-left-radius: 4px;
  }

  .active-tree-left-bottom {
    border-bottom: 1px solid #b1b1b2;
    border-left: 1px solid #b1b1b2;
  }

  .lower-active-tree-left-bottom {
    border-left: 1px solid #b1b1b2;
  }

  .lock-wrapper {
    display: flex;
    gap: 0.5rem;
    height: 25px;
  }

  .lock-show {
    color: #CCCCCC;
  }

  .lock-span {
    margin-top: 3px;
  }

`;

export default StyledWrapper;
