import React from "react";
import Joy from "../../assets/svg/joy.svg";
import Tick from "../../assets/svg/tick.svg";
import { Modal, Box, Typography, Button } from "@mui/material";
import Image from "next/image";
import { Close } from "@mui/icons-material";
import PlanSuccessWrapper from "./PlanSuccessWrapper";

export const TrialEnded = ({ open, onClose }) => {
  return (
    <>
      <Modal
        disableAutoFocus
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PlanSuccessWrapper>
          <div className="close-wrapper">
            <Close
              onClick={onClose}
              sx={{
                fontSize: { xs: "14px", md: "20px" },
                color: "#646566",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="trial-extended">
            <Image src={Joy} alt="gift-icon" />
            <Typography
              fontWeight="500"
              color="#19191a"
              fontSize="18px"
              lineHeight="24px"
            >
              Your free trial has been extended
            </Typography>
            <Image src={Tick} alt="tick-icon" />
          </div>
          <div className="border-wrapper"></div>
          <Box
            sx={{ display: "flex", padding: "16px", justifyContent: "center" }}
          >
            <Typography
              color="#19191A"
              fontWeight="700"
              fontSize="14px"
              lineHeight="21px"
            >
              You can now enjoy the growth plan benefits
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "10px 0px 15px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography fontSize="14px" lineHeight="21px">
              If you have any questions at all, please chat with us at{" "}
              <span
                style={{
                  color: "#19191A",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://help.everbee.io/en/", "_blank")
                }
              >
                help.everbee.io
              </span>
            </Typography>
          </Box>
          <div className="border-wrapper"></div>
          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "20px" }}
          >
            <Button
              sx={{
                "&.MuiButton-root": {
                  color: "#FFFFFF",
                },
              }}
              variant="contained"
              onClick={() => {
                window.location.href = "/dashboard";
              }}
            >
              Close
            </Button>
          </Box>
        </PlanSuccessWrapper>
      </Modal>
    </>
  );
};
