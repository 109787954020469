import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Tabs, Tab, TabList } from "@mui/material";
import { Close } from "@mui/icons-material";
import FaqList from "./FaqList";
import VideoTab from "./VideoTab";
import { useLoader } from "../../contexts/LoaderContext";
import amplitude from "amplitude-js";
import { useRouter } from "next/router";

const useStyles = makeStyles(
  (theme) => ({
    textHeaders: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "24px",
      width: "auto",
      margin: "19px 19px 8px 19px",
      color: "#002753",
    },
  }),
  {
    name: "MuiCustomStyles",
  }
);

const faqList = [
  {
    question: " Can I use EverBee on my tablet/mobile device?",
    answer: [
      `We are excited to let you know that our program, <a href="https://everbee.io/" class="faqLink">everbee.io</a>, is available for both tablet and mobile devices.`,
      "Here are the steps to get started",
      `1. Open your browser and navigate to <a href="https://everbee.io/" class="faqLink" target="blank">Everbee.io </a>`,
      "2. Click on Try for free located on the homepage",
      "3. Next, you will be directed to the registration page where you will need to provide your credentials to sign up for an account.",
      "Once you have successfully registered, you can log in and start using our dashboard and its features on your tablet or mobile device.",
      `<div class= "infoBox">Please, let's take into consideration that <a  href="https://chrome.google.com/webstore/detail/everbee/oeicpkgdngoghobnbjngekclpcmpgpij" class= "link" target="blank">EverBee Chrome extension </a> will work only work on desktop computers. </div>`,
      `<a href="https://help.everbee.io/article/31-can-i-use-everbee-on-my-tablet-mobile-device" class="helpLink" target="blank"> View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg></a>`,
    ],
  },
  {
    question: "Can EverBee analyze all listings of a specific keyword/store?",
    answer: [
      "<h4>Extension</h4>",
      "Yes!! Everbee will analyze all the products with prices of that specific Keyword/ store you are looking at. So, if you are on the first page of your search, Everbee will review and analyze the products that are on that page.",
      "Usually, Etsy shows 64 listings per page, which means that Everbee is analyzing 64 listings. It's necessary to follow the same process for each page for them to be shown in our listing. In case, a store has fewer listings, the number will change.",
      "<h4>Dashboard</h4>",
      `1. Go to your <a href="https://app.everbee.io/account" target="blank" class="faqLink">EverBee Dashboard</a>`,
      `2. Click on "Product Analytics" on your side bar`,
      `3. Click on "Analyze by Shop Name"`,
      "4. Search Shop",
      `<span class="footer">*Once you analyze the Etsy shop on your Dashboard, EverBee will display <span class="boldLine">all the listings</span> of this shop.</span>`,
      `<a href="https://help.everbee.io/article/35-can-everbee-analyze-all-the-listings-of-a-specific-keyword-store" class="helpLink" target="blank"> View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg></a>`,
    ],
  },
  {
    question: "Can I use EverBee without having a store?",
    answer: [
      `Yes! It is possible to use our software without having a store. You just need to create an <a href="https://www.etsy.com/" class="faqLink" target="blank">Etsy account</a> (it can be as a seller or buyer) and connect this one with EverBee.`,
      `<a href="https://help.everbee.io/article/37-can-i-use-everbee-without-having-a-store" class="helpLink" target="blank"> View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg></a>`,
    ],
  },
  {
    question: "What happens with my personal data?",
    answer: [
      `We do not and will never <span class="boldLine">share, disclose, sell, rent, or otherwise provide personal information to other third parties</span> or companies for the marketing of their own products or services.`,
      " This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.",
      "Still, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful requests for information we receive, or to otherwise protect our rights.",
      `For more information, you can visit our <a href="https://everbee.io/privacy-policy/" class="faqLink" target="blank"> Privacy Policy </a>😊`,
      `<a href="https://help.everbee.io/article/73-my-personal-data" class="helpLink" target="blank"> View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg></a>`,
    ],
  },
  // {
  //   question: "When do my searches reset?",
  //   answer: [
  //     "Searches reset the 1st of the month.",
  //     `<a href="https://help.everbee.io/en/article/when-my-analytics-searches-gets-renew-tzf13r/" class="helpLink" target="blank"> View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg></a>`,
  //   ],
  // },
  {
    question: " What counts as an Analytic Search?",

    answer: [
      `EverBee will consider a search once you click on our Product Analytics button.`,
      `<a href="https://help.everbee.io/article/36-what-counts-as-an-analytic-search" class="helpLink" target="blank"> View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg></a>`,
    ],
  },
];

const videoList = [
  {
    id: "cLJCGffIIVg",
    title: "Intro",
    subtitle: "Successful companies don’t invent products from scratch",
    articlelink: `<a href="https://help.everbee.io/article/1-welcome-to-everbee" class="faqLink" target="blank">View in Help Center<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg> </a>`,
    video: "https://www.youtube.com/embed/cLJCGffIIVg?autoplay=0",
  },
  {
    title: "Product Analytics tutorial",
    subtitle: "Learn how to analyze products the EverBee way",
    articlelink: `<a href="https://help.everbee.io/article/3-product-analytics" class="faqLink" target="blank">View in Help Center<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path  fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg> </a>`,
    video: "https://www.youtube.com/embed/m8mau7docow?autoplay=0",
  },
  {
    title: "How successful shops succeed - modeling other shops",
    subtitle: "Learn the secret sauce to owning a successful venture",
    articlelink: `<a href="https://help.everbee.io/article/63-find-the-top-selling-products-for-your-niche" class="faqLink" target="blank" >View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path class="viewIcon" fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg> </a>`,
    video: "https://www.youtube.com/embed/ST3hVzDDAHc?autoplay=0",
  },
  {
    title: "Shop Analyzer tutorial",
    subtitle: "Analyze your competition",
    articlelink: `<a href="https://help.everbee.io/article/2-shop-analyzer" class="faqLink" target="blank" >View in Help Center <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path class="viewIcon" fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg> </a>`,
    video: "https://www.youtube.com/embed/e-M2b8i6AvQ?autoplay=0",
  },
  {
    title: "Product Analytics Series Part 1",
    subtitle: "How to Find In-demand Products to Sell",
    video: "https://www.youtube.com/embed/Bof1DwURBi4?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 2",
    subtitle: " How to Understand and Use the Data",
    video: "https://www.youtube.com/embed/RPcPnFTH_CI?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 3",
    subtitle: " Tips and Tricks For Using Product Analytics",
    video: "https://www.youtube.com/embed/9lXAE1Va9QQ?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 4",
    subtitle: "False Positives When Using Product Analytics",
    video: "https://www.youtube.com/embed/aXt1AGVX69I?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 5",
    subtitle: "Find Etsy Products in Your Niche",
    video: "https://www.youtube.com/embed/IZnvXgyNXiY?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 6",
    subtitle: "How to Use a Single Etsy Shop’s Data to Find Multiple Products",
    video: "https://www.youtube.com/embed/bacOy17CBzg?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 7",
    subtitle: "How to Use Keyword Volume and Product Analytics to Check Demand",
    video: "https://www.youtube.com/embed/mDgSTHJkaBE?autoplay=0",
    articlelink: "",
  },
  {
    title: "Product Analytics Series Part 8",
    subtitle: "How to Find Products in Sub Niches Using Filters and Analytics",
    video: "https://www.youtube.com/embed/jGJpGpw3OL0?autoplay=0",
    articlelink: "",
  },
];

const everbeeVideoList = [
  {
    title: "Use EverBee Email to maximize sales during slow shopping seasons",
    subtitle: "Join Jay to uncover new strategies that work",
    video: "https://www.youtube.com/embed/ZUzDxeS3B58?autoplay=0",
    articlelink: `<a href="https://help.everbee.io/article/15-how-to-setup-everbee-email" class="faqLink" target="blank">View in Help Center<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg> </a>`,
  },
  {
    title: "Everbee Email 101",
    subtitle: "Boosting Customer Engagement and Sales",
    video: "https://www.youtube.com/embed/AcB-SO-5zqU?autoplay=0",
    articlelink: `<a href="https://help.everbee.io/article/15-how-to-setup-everbee-email" class="faqLink" target="blank">View in Help Center<svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="viewIcon"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg> </a>`,
  },
];
let startTime = 0;
const LearningModule = ({ setOpenLearningModule }) => {
  const router = useRouter();
  const { openLearn, setOpenLearn } = useLoader();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpenLearn(false);
    setOpenLearningModule(false);
  };

  //For amplitude event
  useEffect(() => {
    if (value == 1 && open) {
      startTime = Date.now();
    } else if (startTime != 0) {
      let duration = (Date.now() - startTime) / 1000;
      amplitude.getInstance().logEvent("Learn - FAQ viewed", {
        duration: duration,
      });
      startTime = 0;
    }
  }, [openLearn, value]);
  return (
    <Box style={{ width: "522px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            className={classes.textHeaders}
            sx={{ fontWeight: "800" }}
          >
            Learn
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#002753",
              opacity: "0.4",
              marginLeft: "19px",
            }}
          >
            Unleash your potential. Learn with EverBee
          </Typography>
        </Box>
        <Close
          sx={{
            fontSize: "20px",
            marginTop: "19px",
            fontWeight: "600",
            cursor: "pointer",
            marginRight: "20px",
          }}
          onClick={handleClose}
        />
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "90%",
          marginLeft: "20px",
        }}
      >
        <Tabs
          aria-label="basic tabs example"
          sx={{ height: "20px" }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            className="listing-details"
            label="Videos"
            sx={{
              textTransform: "capitalize",
              fontSize: "14px",
            }}
          />
          <Tab
            className="shop-details"
            label="Frequently Asked Questions"
            sx={{
              textTransform: "capitalize",
              fontSize: "14px",
            }}
          />
        </Tabs>
      </Box>
      {value == 1 && (
        <Box sx={{ marginBottom: "10px", marginLeft: "20px" }}>
          {faqList.map((obj) => (
            <FaqList questionText={obj.question} answerText={obj.answer} />
          ))}
        </Box>
      )}
      {value == 0 && (
        <Box sx={{ marginBottom: "80px", marginLeft: "20px" }}>
          {router.pathname === "/evermail"
            ? everbeeVideoList.map((obj) => {
              return (
                <VideoTab
                  title={obj.title}
                  subtitle={obj.subtitle}
                  articlelink={obj?.articlelink}
                  video={obj.video}
                />
              );
            })
            : videoList.map((obj) => {
              return (
                <VideoTab
                  title={obj.title}
                  subtitle={obj.subtitle}
                  articlelink={obj?.articlelink}
                  video={obj.video}
                />
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default LearningModule;
