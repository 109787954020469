import { createContext, useContext, useEffect, useState } from "react";
const ExtensionContext = createContext();
const ExtensionProvider = ({ children }) => {
  const [extInstalled, setExtInstalled] = useState();

  const isExtensionInstalled = () => {
    try {
      var ExtID = process.env.NEXT_PUBLIC_EXTENTION_ID;

      var s = document.createElement("script");
      s.onload = function (e) {
        Ext_Detect_Installed();
      };
      s.onerror = function () {
        Ext_Detect_NotInstalled();
      };
      s.src = "chrome-extension://" + ExtID + "/everbeeInstalled.js";
      document.body.appendChild(s);
    } catch (err) {
      console.log(err);
    }
  };

  function Ext_Detect_NotInstalled() {
    setExtInstalled(false);
  }

  function Ext_Detect_Installed() {
    setExtInstalled(true);
  }

  useEffect(() => {
    isExtensionInstalled();
  }, []);

  return (
    <ExtensionContext.Provider
      value={{
        extInstalled,
      }}
    >
      {children}
    </ExtensionContext.Provider>
  );
};
const useExtension = () => useContext(ExtensionContext);
export { ExtensionProvider, useExtension };
