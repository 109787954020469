import RadioButton from "../../components/RadioButton/RadioButtom";
import styled from "styled-components";
import { useSalesChannel } from "../../contexts/SalesChannelContext";

const StyledWrapper = styled.div`
  height: 2.25rem;
  width: 92%;
  padding-left: 8px;
  background-color: white;
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const StoreSelectItem = ({ name, isChecked, onClick }) => {
  const {
    salesChannelsState: { userAccountDetails },
  } = useSalesChannel();
  return (
    <>
      <StyledWrapper onClick={onClick}>
        {/* <input type="radio" checked={isChecked} /> */}

        <RadioButton checked={isChecked} onClick={onClick} />
        {!userAccountDetails?.hide_details ? (
          <div>{name}</div>
        ) : (
          <div>********</div>
        )}
      </StyledWrapper>
    </>
  );
};

export default StoreSelectItem;
