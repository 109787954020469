export function getSubdomain() {
  // Get the hostname from the current URL
  const hostname = window.location.hostname;

  // Split the hostname by "." and get the first part
  const parts = hostname.split(".");

  // Check if the hostname has at least two parts (to avoid localhost)
  if (parts.length >= 2) {
    // Return the first part as the subdomain
    return parts[0];
  } else {
    // No subdomain found
    return "";
  }
}
