import Image from "next/image";
import StyledWrapper from "./StyledWrapper";
import arrowRight from "../../assets/svg/arrow-right.svg";
import FramerMotion from "../utils/FramerMotion";
import { useRouter } from "next/router";
import { useAuth } from "../../contexts/AuthContext";

const ProductAnalytics = ({ isExpanded, userAccountDetails }) => {
  const router = useRouter();
  const handleUpgrade = () => {
    router.push("/pricing");
  };
  const {
    authState: { trialData },
  } = useAuth();
  const percent =
    ((trialData?.total_trial_length - trialData?.trial_end_days_left) /
      trialData?.total_trial_length) *
    100;
  return (
    <StyledWrapper isExpanded={isExpanded} percent={percent}>
      {isExpanded && (
        <FramerMotion
          explicitClass="footer-pa-container"
          className="footer-pa-container"
        >
          <div className="analytics-header">
            {/* <div className="analytics-text">Product Analytics</div> */}
            <div className="analytics-status">
              {/* {trialData?.trial_end_days_left}/
              {trialData?.total_trial_length} days */}
              {trialData.trial_end_days_left > 0
                ? `Trial Ends in ${trialData.trial_end_days_left} days`
                : `Your trial has ended`}
            </div>
          </div>
          <div className="analytics-progressbar" role="progressbar">
            <div className="progress"></div>
          </div>
          {/* <div className="upgrade-plan" onClick={handleUpgrade}>
            Upgrade Plan
            <Image src={arrowRight} alt="Right arrow of Upgrade Plan" />
          </div> */}
        </FramerMotion>
      )}
      {!isExpanded && (
        <FramerMotion
          explicitClass="footer-pa-container"
          className="footer-pa-container"
        >
          <div className="analytics-progressbar collapsed" role="progressbar">
            <div className="progress"></div>
          </div>
        </FramerMotion>
      )}
    </StyledWrapper>
  );
};

export default ProductAnalytics;
