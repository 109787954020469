import { Modal, Box, Typography, Button, Fade } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import {
  cancelSubscriptionReminderPopup,
  subscriptionReminder,
} from "../../apis/account";
import { plans, plansPriceId } from "../../helper/priceconstant";
import { currentPlan } from "../../apis/current_plan";
import UpdateSubscriptionModal from "./UpdateSubscriptionModal";
import { updateStripeSubscription } from "../../apis/stripe";
import { useLoader } from "../../contexts/LoaderContext";
import { useAuth } from "../../contexts/AuthContext";
import amplitude from "amplitude-js";
import { toast } from "react-toastify";

const PricingModal = ({
  openModal,
  setOpenPricingModal,
  setOpenCardFailModal,
}) => {
  const [currentPlanName, setCurrentPlanName] = useState();
  const [updateSubscriptionModal, setUpdateSubscriptionModal] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    position: "relative",
    width: "70%",
    borderRadius: "7px",
    backgroundColor: "background.paper",
    padding: "8px",
  };
  const { loadingSet, setLoadingSet, addToLoadingSet, removeFromLoadingSet } =
    useLoader();
  const {
    authState: { everbeeToken },
  } = useAuth();

  useEffect(() => {
    if (everbeeToken) {
      getCurrentPlan();
    }
  }, []);

  const details = {
    title: plans.growthannualy,
    price: "19.99",
    chargePrice: 239.0,
    subDesc: ["$239.00 Billed annually", "4 Months Free!  $120 saved!"],
    section: [
      {
        header: "Product Research Suite",
        description: [
          "Unlimited Product Analytics Searches per month",
          "Unlimited Revenue Estimates",
          "All Tags of each particular listing",
          "Quick Sort Ability",
          "Unlimited Favorites folder uses",
          "Additional Listing Insights: Listing Reviews, Processing Time, Shipping Location + More",
          "Additional Shop Insights: Shop Age, Shop Review Count + More",
        ],
      },
      {
        header: "Keyword Research Suite",
        description: [
          "Search Bar Keyword Volume Insights",
          "Keyword Finder - See Specific Keyword Insights + Related Keyword Ideas",
          "Tag Search Volume - See the monthly search volume of each particular tag on a listing",
          "Tag Analyzer - Insights of all the tags of each particular listing in one click",
        ],
      },
    ],
    buttonText: "Growth Annual",
    buttonVariant: "outlined",
    plan: plans.growthannualy,
    priceId: plansPriceId.growthannualy,
    interval: "year",
    footerTagLine: "$239 Billed annually. ($120 SAVINGS = 4 months value)",
  };
  async function getCurrentPlan() {
    try {
      // addToLoadingSet("getCurrentPlan");
      const response = await currentPlan();
      setCurrentPlanName(response.data.current_plan);
    } catch (err) {
      console.log("errorat", err);
    }
  }

  const handleClose = async () => {
    try {
      setOpenPricingModal(false);
      const res = await cancelSubscriptionReminderPopup();
    } catch {
      toast.error(error.message);
      console.log("err", error);
    }
  };

  const amplitudeCloseEvent = () => {
    amplitude.getInstance().logEvent(`Annual Pop-up Offer`, {
      app: "web app",
      button_clicked: "Closed",
    });
  };

  const ReminderLater = async (setOpenPricingModal) => {
    try {
      amplitude.getInstance().logEvent(`Annual Pop-up Offer`, {
        app: "web app",
        button_clicked: "Remind Me Later",
      });
      setOpenPricingModal(false);
      const res = await subscriptionReminder();
    } catch (error) {
      console.log("error", error);
    }
  };
  const UpgradePlan = (setOpenPricingModal) => {
    amplitude.getInstance().logEvent(`Annual Pop-up Offer`, {
      app: "web app",
      button_clicked: "Upgrade",
    });
    setUpdateSubscriptionModal(true);
  };

  const updateSubscription = async (e, promoCodeId) => {
    e.preventDefault();
    setOpenPricingModal(false);
    setUpdateSubscriptionModal(false);
    try {
      addToLoadingSet("updateSubscription");
      const res = await updateStripeSubscription({
        interval: details.interval,
        plan_name: details.title,
        promoCodeId: promoCodeId,
      });
      if (res.status == 200) {
        if (
          (currentPlanName?.includes("Growth") ||
            currentPlanName?.includes("Business")) &&
          (details.title?.includes("Pro") || details.title?.includes("Growth"))
        ) {
          amplitude.getInstance().logEvent(`Downgrade`, {
            plan_name: details.title,
            price: `$ ${details.price}`,
            previous_plan: currentPlanName,
            status: "success",
          });
        } else {
          // amplitude.getInstance().setUserProperties({ out_of_limit: false });
          amplitude.getInstance().logEvent(`Upgrade`, {
            plan_name: details.title,
            price: `$ ${details.price}`,
            previous_plan: currentPlanName,
            status: "success",
          });
        }

        toast.success("Updated to Growth Annual Plan");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      if (
        (currentPlanName?.includes("Growth") ||
          currentPlanName?.includes("Business")) &&
        (details.title?.includes("Pro") || details.title?.includes("Growth"))
      ) {
        amplitude.getInstance().logEvent(`Downgrade`, {
          plan_name: details.title,
          price: `$ ${details.price}`,
          previous_plan: currentPlanName,
          status: "failed",
        });
      } else {
        amplitude.getInstance().logEvent(`Upgrade`, {
          plan_name: details.title,
          price: `$ ${details.price}`,
          previous_plan: currentPlanName,
          status: "failed",
        });
      }
      setOpenCardFailModal([details]);
      removeFromLoadingSet("updateSubscription");
    } finally {
    }
  };
  return (
    <>
      <Modal
        open={openModal}
        disableAutoFocus
        sx={{ outline: "none", border: "none" }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <ClearIcon
              sx={{ position: "absolute", right: "7px", cursor: "pointer" }}
              onClick={() => {
                handleClose();
                amplitudeCloseEvent();
              }}
            />

            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "22px",
                py: "25px",
                margin: "auto",
                gap: "30px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#1D6BC3",
                  fontWeight: "600",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                <span>Pay Annually and </span>
                <span style={{ color: "#E56B2C" }}>save $120 </span>
                <span> (4 months of value 🚀) </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  color: " #808080",
                }}
              >
                You will be saving{" "}
                <span style={{ color: "#E56B2C", textDecoration: "none" }}>
                  {" "}
                  33%{" "}
                </span>{" "}
                by switching to Annual Payments
              </Typography>
            </Box>
            <Box
              sx={{
                height: "190px",
                width: "310px",
                // background: "#63AFEF",
                background: "linear-gradient(90deg, #63AFEF 0%, #1E6CC4 100%)",
                borderRadius: "10px 10px 10px 8px",
                margin: "0 auto",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "#E56B2C",
                  color: "white",
                  height: "37px",
                  borderRadius: "10px 10px 0 0",
                  fontSize: "20px",
                  textAlign: "center",
                  lineHeight: "1.9",
                }}
              >
                Best Value
              </Typography>
              <p
                style={{
                  fontSize: "20px",
                  color: "white",
                  gap: "20px",
                  marginTop: "35px",
                }}
              >
                Growth Plan Annual{" "}
              </p>
              <p style={{ marginTop: "30px", color: "white" }}>$19.99/mo</p>
            </Box>
            <Box sx={{ margin: "20px auto" }}>
              <Typography
                sx={{
                  color: "#808080",
                  fontSize: "12px",
                  fontWeight: "500",
                  margin: "0px 34%",
                  width: "70%",
                  opacity: "0.8",
                }}
              >
                $239 Billed annually. ($120 SAVINGS = 4 months value)
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  padding: "0px 10px",
                  marginTop: "25px",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => UpgradePlan(setOpenPricingModal)}
                  variant="contained"
                  sx={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    backgroundColor: "#1D6BC3",
                    fontSize: "12px",
                    borderRadius: "5px",
                    padding: "7px 10px",
                    height: "38px",
                    width: "142px",
                    margin: "0px auto",
                    marginLeft: "34%",
                    color: "white",
                  }}
                >
                  Upgrade Now
                </Button>
                <Button
                  onClick={() => ReminderLater(setOpenPricingModal)}
                  variant="outlined"
                  sx={{
                    cursor: "pointer",
                    fontSize: "12px",
                    borderRadius: "5px",
                    height: "38px",
                    width: "142px",
                    marginRight: "34%",
                  }}
                >
                  Remind me Later
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {
        <Modal
          disableAutoFocus
          open={updateSubscriptionModal}
          onClose={() => setUpdateSubscriptionModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              borderRadius: "1rem",
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          >
            <UpdateSubscriptionModal
              priceId={details.priceId}
              details={details}
              updateSubscription={updateSubscription}
              isUpdateLoading={loadingSet.size !== 0}
            />
          </Box>
        </Modal>
      }
    </>
  );
};

export default PricingModal;
